import { IUseAuthValues } from "../../helpers/AuthContext";
import {
  CityCreateRequestModel,
  CityDetailVM,
  CityUpdateRequestModel,
  CityVM
} from "../models/City";

export const getCitiesList = async (context: IUseAuthValues): Promise<CityVM[]> => {
  const { get } = context;

  const cities = await get<CityVM[] | undefined>(`/City`);

  return cities || [];
};

export const getCityDetails = async (
  context: IUseAuthValues,
  id: number
): Promise<CityDetailVM | undefined> => {
  const { get } = context;

  const entity = await get<CityDetailVM | undefined>(`/City/${id}`);

  return entity;
};

export const createCity = async (
  context: IUseAuthValues,
  newEntity: CityCreateRequestModel
): Promise<number | undefined> => {
  const { post } = context;

  const entity = await post<number | undefined>(`/City`, newEntity);

  return entity;
};

export const updateCity = async (
  context: IUseAuthValues,
  updateEntity: CityUpdateRequestModel
): Promise<number | undefined> => {
  const { put } = context;

  const entity = await put<number | undefined>(`/City/${updateEntity.id}`, updateEntity);

  return entity;
};

export const deleteCity = async (context: IUseAuthValues, id: number): Promise<number | undefined> => {
  const { remove } = context;

  const entity = await remove<number | undefined>(`/City/${id}`);

  return entity;
};
