/* eslint-disable react-hooks/exhaustive-deps */
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Space } from "antd";
import React, { useEffect, useState } from "react";
import { translations } from "../../config/translations";
import { BrandDetailVM, BrandFormModel } from "../../core/models/Brand";
import { DrawerState } from "../../core/models/Enum";
import {
  createBrand,
  getBrandDetails,
  updateBrand
} from "../../core/services/BrandService";
import { useAuthContext } from "../../helpers/AuthContext";
import {
  formGenericRules,
  formItemCrudDrawer,
  formItemModalWithoutLabel
} from "../../helpers/FormHelper";

interface Props {
  drawerState: DrawerState;
  selectedEntityId?: number;
  wrappedComponentRef?: React.RefObject<any>;
  onClose: (
    drawerState: DrawerState,
    shouldUpdate: boolean,
    id?: number
  ) => void;
}

const BrandForm: React.FC<Props> = (props: Props) => {
  const { drawerState, selectedEntityId, wrappedComponentRef, onClose } = props;
  const { language, setLoading } = useAuthContext();
  const authContext = useAuthContext();
  const [selectedEntity, setSelectedEntity] = useState<
    BrandDetailVM | undefined
  >();

  useEffect(() => {
    getFormData();
  }, []);

  const getFormData = async () => {
    if (!selectedEntityId) return;

    setLoading(true);

    const result = await getBrandDetails(authContext, selectedEntityId);

    if (result) {
      setSelectedEntity(result);
    }

    setLoading(false);
  };

  const handleSave = async (formValues: BrandFormModel) => {
    setLoading(true, translations[language].saving);

    const result =
      drawerState === DrawerState.Edit && selectedEntityId
        ? await updateBrand(authContext, {
            id: selectedEntityId,
            name: formValues.name,
            questions: JSON.stringify(formValues.questions || []),
          })
        : await createBrand(authContext, {
            name: formValues.name,
            questions: JSON.stringify(formValues.questions || []),
          });

    if (result) {
      onClose(DrawerState.Closed, true, undefined);
    }

    setLoading(false);
  };

  return (
    <>
      {(drawerState === DrawerState.Create ||
        (drawerState === DrawerState.Edit && selectedEntity)) && (
        <Form
          onFinish={handleSave}
          className="form"
          {...formItemCrudDrawer}
          ref={wrappedComponentRef}
        >
          <Form.Item
            {...formGenericRules(language, true)}
            name="name"
            label={translations[language].name}
            initialValue={selectedEntity?.name}
          >
            <Input placeholder={translations[language].nameEnter} />
          </Form.Item>
          <Form.List
            name="questions"
            initialValue={
              selectedEntity?.questions
                ? JSON.parse(selectedEntity.questions)
                : []
            }
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} style={{ display: "flex" }} align="baseline">
                    <Form.Item
                      {...restField}
                      rules={[
                        {
                          required: true,
                          message: translations[language].requiredField,
                        },
                        {
                          pattern: /^[A-Za-z]+$/,
                          message: translations[language].questionValidation,
                        },
                      ]}
                      name={[name, "key"]}
                      label={translations[language].key}
                    >
                      <Input placeholder={translations[language].enterKey} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      {...formGenericRules(language, true)}
                      name={[name, "question"]}
                      label={translations[language].question}
                    >
                      <Input
                        placeholder={translations[language].enterQuestion}
                      />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item {...formItemModalWithoutLabel}>
                  <Button
                    type="primary"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    {translations[language].addQuestion}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      )}
    </>
  );
};

export default BrandForm;
