import { BaseViewModel } from "../models/Base";
import { AgeGroup } from "../models/Enum";

export const getAgeGroupList = (): BaseViewModel[] => {
  const results: BaseViewModel[] = [
    {
      id: AgeGroup.First,
      name: "18-25",
    },
    {
      id: AgeGroup.Second,
      name: "18-30",
    },
    {
      id: AgeGroup.Third,
      name: "25-30",
    },
    {
      id: AgeGroup.Forth,
      name: "30+",
    },
  ];

  return results;
};

export const getAgeGroupDetails = (
  ageGroupId: AgeGroup
): BaseViewModel | undefined => {
  switch (ageGroupId) {
    case AgeGroup.First:
      return {
        id: AgeGroup.First,
        name: "18-25",
      };
    case AgeGroup.Second:
      return {
        id: AgeGroup.Second,
        name: "18-30",
      };
    case AgeGroup.Third:
      return {
        id: AgeGroup.Third,
        name: "25-30",
      };
    default:
      return {
        id: AgeGroup.Forth,
        name: "30+",
      };
  }
};
