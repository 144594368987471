/* eslint-disable react-hooks/exhaustive-deps */
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, PageHeader, Row, Select } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";
import { translations } from "../../config/translations";
import { PersonType } from "../../core/models/Enum";
import { PayoffFilter } from "../../core/models/Payoff";
import { PersonVM } from "../../core/models/Person";
import { getMonthsDropdownList } from "../../core/services/MonthService";
import { getPersonsList } from "../../core/services/PersonService";
import { useAuthContext } from "../../helpers/AuthContext";
import { PageHeaderButtonSettings } from "../../helpers/ButtonHelper";
import { filterSelectOption } from "../../helpers/FormHelper";
import { getPersonTypeFromLocation, isAdmin } from "../../helpers/PersonHelper";
import PayoffsTable from "./PayoffTable";
import "./styles.css";

const Payoff: React.FC = () => {
  const detailsRef = React.useRef<any>(null);
  const { language, userRole, userPersonId } = useAuthContext();
  const authContext = useAuthContext();
  const [months, setMonths] = useState<DefaultOptionType[]>([]);
  const [persons, setPersons] = useState<DefaultOptionType[] | undefined>();
  const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
  const [selectedPersonId, setSelectedPersonId] = useState<number | null>(null);
  const [selectedPersonType, setSelectedPersonType] = useState<
    PersonType | undefined
  >();
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );
  const [filter, setFilter] = useState<PayoffFilter | undefined>();
  const location = useLocation();

  useEffect(() => {
    const newType = getPersonTypeFromLocation(location);
    if (newType !== selectedPersonType) {
      setSelectedPersonType(newType);
      setFilter(
        filter ? { ...filter, personType: newType } : { personType: newType }
      );
      setSelectedPersonId(null);
      detailsRef?.current?.clearData();
    }
  }, [location.pathname]);

  useEffect(() => {
    getMonths();
  }, []);

  useEffect(() => {
    if (!isAdmin(userRole)) {
      setSelectedPersonId(userPersonId || null);
    }
  }, [userPersonId]);

  useEffect(() => {
    if (isAdmin(userRole)) {
      getPersons();
    }
  }, [filter?.personType, userRole]);

  const getMonths = () => {
    const data = getMonthsDropdownList(language);

    setMonths(data);
  };

  const getPersons = async () => {
    const data = await getPersonsList(
      authContext,
      filter?.personType || selectedPersonType
    );
    if (data.length > 0) {
      const mappedPersons = data.map(
        (item: PersonVM): DefaultOptionType => ({
          value: item.id,
          label: `${item.firstName} ${item.lastName}`,
        })
      );
      setPersons([
        {
          value: null,
          label: translations[language].all,
        },
        ...mappedPersons,
      ]);
    }
  };

  const getTableTitle = (): string => {
    switch (selectedPersonType) {
      case PersonType.Bartender:
        return translations[language].payoffsBartenders;
      case PersonType.Hostess:
        return translations[language].payoffsHossteses;
      case PersonType.Technician:
        return translations[language].payoffsTechnicians;
      default:
        return translations[language].payoffsPromoters;
    }
  };

  return (
    <>
      <PageHeader
        title={getTableTitle()}
        className={`payoffs-header ${isMobile ? "mobile-payoff-select" : ""}`}
        extra={
          isMobile || !isAdmin(userRole)
            ? [
                <Select
                  showSearch
                  placeholder={translations[language].monthChoose}
                  options={months}
                  value={selectedMonth}
                  onSelect={(value: number | null): void => {
                    setSelectedMonth(value);
                  }}
                  filterOption={(input, option) =>
                    filterSelectOption(input, option)
                  }
                />,
                <DatePicker
                  defaultValue={moment(new Date())}
                  onChange={(value: Moment | null): void => {
                    if (value) {
                      setSelectedYear(value.year());
                    }
                  }}
                  picker="year"
                />,
                <Button
                  key="orders-button"
                  {...PageHeaderButtonSettings}
                  onClick={(): void => {
                    setFilter({
                      personId: selectedPersonId || undefined,
                      month: selectedMonth || undefined,
                      year: selectedYear,
                      personType: selectedPersonType,
                    });
                    detailsRef?.current?.getTableData({
                      personId: selectedPersonId || undefined,
                      month: selectedMonth || undefined,
                      year: selectedYear,
                      personType: selectedPersonType,
                    });
                  }}
                >
                  <SearchOutlined />
                  {translations[language].search}
                </Button>,
              ]
            : [
                <Col span={24} className="page-header-wrapper">
                  <Row>
                    <Select
                      showSearch
                      placeholder={translations[language].personChoose}
                      options={persons}
                      value={selectedPersonId}
                      onSelect={(value: number | undefined): void => {
                        setSelectedPersonId(value || null);
                      }}
                      filterOption={(input, option) =>
                        filterSelectOption(input, option)
                      }
                    />
                    <Select
                      showSearch
                      placeholder={translations[language].monthChoose}
                      options={months}
                      value={selectedMonth}
                      style={{ marginLeft: 10 }}
                      onSelect={(value: number | null): void => {
                        setSelectedMonth(value);
                      }}
                      filterOption={(input, option) =>
                        filterSelectOption(input, option)
                      }
                    />
                    <DatePicker
                      defaultValue={moment(new Date())}
                      onChange={(value: Moment | null): void => {
                        if (value) {
                          setSelectedYear(value.year());
                        }
                      }}
                      style={{ marginLeft: 10 }}
                      picker="year"
                    />
                    <Button
                      key="orders-button"
                      {...PageHeaderButtonSettings}
                      onClick={(): void => {
                        setFilter({
                          personId: selectedPersonId || undefined,
                          month: selectedMonth || undefined,
                          year: selectedYear,
                          personType: selectedPersonType,
                        });
                        detailsRef?.current?.getTableData({
                          personId: selectedPersonId || undefined,
                          month: selectedMonth || undefined,
                          year: selectedYear,
                          personType: selectedPersonType,
                        });
                      }}
                    >
                      <SearchOutlined />
                      {translations[language].search}
                    </Button>
                  </Row>
                  <Row style={{ marginTop: 10, float: "right" }}>
                    <Button
                      key="orders-grouped-download-button"
                      {...PageHeaderButtonSettings}
                      onClick={() => detailsRef?.current?.downloadGroupedXlsx()}
                    >
                      <DownloadOutlined />
                      {translations[language].downloadGroupedXlsx}
                    </Button>
                    <Button
                      key="orders-download-button"
                      {...PageHeaderButtonSettings}
                      onClick={() => detailsRef?.current?.downloadXlsx()}
                    >
                      <DownloadOutlined />
                      {translations[language].downloadXlsx}
                    </Button>
                  </Row>
                </Col>,
              ]
        }
      />
      <PayoffsTable filter={filter} ref={detailsRef} />
    </>
  );
};

export default Payoff;
