import { IUseAuthValues } from "../../helpers/AuthContext";
import {
    PrincipalCreateRequestModel,
    PrincipalDetailVM,
    PrincipalUpdateRequestModel,
    PrincipalVM
} from "../models/Principal";

export const getPrincipalsList = async (
    context: IUseAuthValues
): Promise<PrincipalVM[]> => {
    const { get } = context;

    const entities = await get<PrincipalVM[] | undefined>(`/Principal`);

    return entities || [];
};

export const getPrincipalDetails = async (
    context: IUseAuthValues,
    id: number
): Promise<PrincipalDetailVM | undefined> => {
    const { get } = context;

    const entity = await get<PrincipalDetailVM | undefined>(`/Principal/${id}`);

    return entity;
};

export const createPrincipal = async (
    context: IUseAuthValues,
    newEntity: PrincipalCreateRequestModel
): Promise<number | undefined> => {
    const { post } = context;

    const entity = await post<number | undefined>(`/Principal`, newEntity);

    return entity;
};

export const updatePrincipal = async (
    context: IUseAuthValues,
    updateEntity: PrincipalUpdateRequestModel
): Promise<number | undefined> => {
    const { put } = context;

    const entity = await put<number | undefined>(`/Principal/${updateEntity.id}`, updateEntity);

    return entity;
};

export const deletePrincipal = async (context: IUseAuthValues, id: number): Promise<number | undefined> => {
    const { remove } = context;

    const entity = await remove<number | undefined>(`/Principal/${id}`);

    return entity;
};
