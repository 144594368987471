import { IUseAuthValues } from "../../helpers/AuthContext";
import {
    SignatureDrinkCreateRequestModel,
    SignatureDrinkDetailVM,
    SignatureDrinkUpdateRequestModel,
    SignatureDrinkVM
} from "../models/SignatureDrink";

export const getSignatureDrinksList = async (
    context: IUseAuthValues
): Promise<SignatureDrinkVM[]> => {
    const { get } = context;

    const entities = await get<SignatureDrinkVM[] | undefined>(`/SignatureDrink`);

    return entities || [];
};

export const getSignatureDrinkDetails = async (
    context: IUseAuthValues,
    id: number
): Promise<SignatureDrinkDetailVM | undefined> => {
    const { get } = context;

    const entity = await get<SignatureDrinkDetailVM | undefined>(`/SignatureDrink/${id}`);

    return entity;
};

export const createSignatureDrink = async (
    context: IUseAuthValues,
    newEntity: SignatureDrinkCreateRequestModel
): Promise<number | undefined> => {
    const { post } = context;

    const entity = await post<number | undefined>(`/SignatureDrink`, newEntity);

    return entity;
};

export const updateSignatureDrink = async (
    context: IUseAuthValues,
    updateEntity: SignatureDrinkUpdateRequestModel
): Promise<number | undefined> => {
    const { put } = context;

    const entity = await put<number | undefined>(`/SignatureDrink/${updateEntity.id}`, updateEntity);

    return entity;
};

export const deleteSignatureDrink = async (context: IUseAuthValues, id: number): Promise<number | undefined> => {
    const { remove } = context;

    const entity = await remove<number | undefined>(`/SignatureDrink/${id}`);

    return entity;
};
