import { Location } from "react-router";
import { ApplicationPaths } from "../components/api-authorization/ApiAuthorizationConstants";
import { hourlyRateBartender, hourlyRateHostess, hourlyRatePromoter, hourlyRateTechnician } from "../config/constants";
import routes from "../config/routes";
import { translations } from "../config/translations";
import {
  ConfigKey,
  DrawerState,
  Language,
  PersonType,
  UserRole,
  UserRoleText
} from "../core/models/Enum";

export const getDeleteModalTitle = (
  language: Language,
  personType?: PersonType
): string => {
  switch (personType) {
    case PersonType.Bartender:
      return translations[language].deleteBartender;
    case PersonType.Promoter:
      return translations[language].deletePromoter;
    case PersonType.Technician:
      return translations[language].deleteTechnician;
    case PersonType.BrandManager:
      return translations[language].deleteBrandManager;
    default:
      return translations[language].deleteHostess;
  }
};

export const getDeleteModalMessage = (
  language: Language,
  personType?: PersonType
): string => {
  switch (personType) {
    case PersonType.Bartender:
      return translations[language].confirmDeleteBartender;
    case PersonType.Promoter:
      return translations[language].confirmDeletePromoter;
    case PersonType.Technician:
      return translations[language].confirmDeleteTechnician;
    case PersonType.BrandManager:
      return translations[language].confirmDeleteBrandManager;
    default:
      return translations[language].confirmDeleteHostess;
  }
};

export const getDrawerTitle = (
  language: Language,
  personType?: PersonType,
  selectedEntity?: number
): string => {
  switch (personType) {
    case PersonType.Bartender:
      return selectedEntity
        ? translations[language].editBartender
        : translations[language].addBartender;
    case PersonType.Promoter:
      return selectedEntity
        ? translations[language].editPromoter
        : translations[language].addPromoter;
    case PersonType.Technician:
      return selectedEntity
        ? translations[language].editTechnician
        : translations[language].addTechnician;
    case PersonType.BrandManager:
      return selectedEntity
        ? translations[language].editBrandManager
        : translations[language].addBrandManager;
    default:
      return selectedEntity
        ? translations[language].editHostess
        : translations[language].addHostess;
  }
};

export const getPageTitle = (
  language: Language,
  personType?: PersonType
): string => {
  switch (personType) {
    case PersonType.Bartender:
      return translations[language].bartenders;
    case PersonType.Promoter:
      return translations[language].promoters;
    case PersonType.Technician:
      return translations[language].technicians;
    case PersonType.BrandManager:
      return translations[language].brandManagers;
    default:
      return translations[language].hostesses;
  }
};

export const getPageHeaderAddLabel = (
  language: Language,
  personType?: PersonType
): string => {
  switch (personType) {
    case PersonType.Bartender:
      return translations[language].addBartender;
    case PersonType.Promoter:
      return translations[language].addPromoter;
    case PersonType.Technician:
      return translations[language].addTechnician;
    case PersonType.BrandManager:
      return translations[language].addManager;
    default:
      return translations[language].addHostess;
  }
};

export const getRedirectUrl = (
  state: DrawerState,
  personType?: PersonType,
  id?: number
): string => {
  switch (state) {
    case DrawerState.Create:
      switch (personType) {
        case PersonType.Bartender:
          return routes.ROUTE_BARTENDERS_CREATE;
        case PersonType.Promoter:
          return routes.ROUTE_PROMOTERS_CREATE;
        case PersonType.Technician:
          return routes.ROUTE_TECHNICIANS_CREATE;
        case PersonType.BrandManager:
          return routes.ROUTE_BRAND_MANAGERS_CREATE;
        default:
          return routes.ROUTE_HOSTESSES_CREATE;
      }
    case DrawerState.Edit:
      switch (personType) {
        case PersonType.Bartender:
          return id
            ? routes.ROUTE_BARTENDERS_EDIT.replace(
              ":bartenderId",
              id.toString()
            )
            : routes.ROUTE_BARTENDERS;
        case PersonType.Promoter:
          return id
            ? routes.ROUTE_PROMOTERS_EDIT.replace(":promoterId", id.toString())
            : routes.ROUTE_PROMOTERS;
        case PersonType.Technician:
          return id
            ? routes.ROUTE_TECHNICIANS_EDIT.replace(
              ":technicianId",
              id.toString()
            )
            : routes.ROUTE_TECHNICIANS;
        case PersonType.BrandManager:
          return id
            ? routes.ROUTE_BRAND_MANAGERS_EDIT.replace(
              ":brandManagerId",
              id.toString()
            )
            : routes.ROUTE_BRAND_MANAGERS;
        default:
          return id
            ? routes.ROUTE_HOSTESSES_EDIT.replace(":hostessId", id.toString())
            : routes.ROUTE_HOSTESSES;
      }
    default:
      switch (personType) {
        case PersonType.Bartender:
          return routes.ROUTE_BARTENDERS;
        case PersonType.Promoter:
          return routes.ROUTE_PROMOTERS;
        case PersonType.Technician:
          return routes.ROUTE_TECHNICIANS;
        case PersonType.BrandManager:
          return routes.ROUTE_BRAND_MANAGERS;
        default:
          return routes.ROUTE_HOSTESSES;
      }
  }
};

export const handleInitialRoute = (
  handleInitialSetup: (
    newDrawerState: DrawerState,
    newShouldUpdate: boolean,
    newPersonType: PersonType,
    id?: number
  ) => void,
  handleError: (newRoute: string) => void,
  location: Location,
  promoterId?: string,
  bartenderId?: string,
  technicianId?: string,
  hostessId?: string,
  brandManagerId?: string
): void => {
  const newLocation = getNewReplacedUrl(
    location,
    promoterId,
    bartenderId,
    technicianId,
    hostessId,
    brandManagerId
  );

  switch (newLocation) {
    case routes.ROUTE_PROMOTERS_CREATE:
      handleInitialSetup(DrawerState.Create, true, PersonType.Promoter);
      return;
    case routes.ROUTE_BARTENDERS_CREATE:
      handleInitialSetup(DrawerState.Create, true, PersonType.Bartender);
      return;
    case routes.ROUTE_TECHNICIANS_CREATE:
      handleInitialSetup(DrawerState.Create, true, PersonType.Technician);
      return;
    case routes.ROUTE_HOSTESSES_CREATE:
      handleInitialSetup(DrawerState.Create, true, PersonType.Hostess);
      return;
    case routes.ROUTE_BRAND_MANAGERS_CREATE:
      handleInitialSetup(DrawerState.Create, true, PersonType.BrandManager);
      return;
    case routes.ROUTE_PROMOTERS_EDIT:
      if (promoterId) {
        handleInitialSetup(
          DrawerState.Edit,
          true,
          PersonType.Promoter,
          parseInt(promoterId)
        );
      }
      return;
    case routes.ROUTE_BARTENDERS_EDIT:
      if (bartenderId) {
        handleInitialSetup(
          DrawerState.Edit,
          true,
          PersonType.Bartender,
          parseInt(bartenderId)
        );
      }
      return;
    case routes.ROUTE_TECHNICIANS_EDIT:
      if (technicianId) {
        handleInitialSetup(
          DrawerState.Edit,
          true,
          PersonType.Technician,
          parseInt(technicianId)
        );
      }
      return;
    case routes.ROUTE_HOSTESSES_EDIT:
      if (hostessId) {
        handleInitialSetup(
          DrawerState.Edit,
          true,
          PersonType.Hostess,
          parseInt(hostessId)
        );
      }
      return;
    case routes.ROUTE_BRAND_MANAGERS_EDIT:
      if (brandManagerId) {
        handleInitialSetup(
          DrawerState.Edit,
          true,
          PersonType.BrandManager,
          parseInt(brandManagerId)
        );
      }
      return;
    case routes.ROUTE_PROMOTERS:
      handleInitialSetup(DrawerState.Closed, true, PersonType.Promoter);
      return;
    case routes.ROUTE_BARTENDERS:
      handleInitialSetup(DrawerState.Closed, true, PersonType.Bartender);
      return;
    case routes.ROUTE_TECHNICIANS:
      handleInitialSetup(DrawerState.Closed, true, PersonType.Technician);
      return;
    case routes.ROUTE_HOSTESSES:
      handleInitialSetup(DrawerState.Closed, true, PersonType.Hostess);
      return;
    case routes.ROUTE_BRAND_MANAGERS:
      handleInitialSetup(DrawerState.Closed, true, PersonType.BrandManager);
      return;
    default:
      handleError(routes.ROUTE_DASHBOARD);
      return;
  }
};

const getNewReplacedUrl = (
  location: Location,
  promoterId?: string,
  bartenderId?: string,
  technicianId?: string,
  hostessId?: string,
  brandManagerId?: string
): string => {
  if (promoterId) {
    return location.pathname.replace(promoterId, ":promoterId");
  } else if (bartenderId) {
    return location.pathname.replace(bartenderId, ":bartenderId");
  } else if (technicianId) {
    return location.pathname.replace(technicianId, ":technicianId");
  } else if (hostessId) {
    return location.pathname.replace(hostessId, ":hostessId");
  } else if (brandManagerId) {
    return location.pathname.replace(brandManagerId, ":brandManagerId");
  } else {
    return location.pathname;
  }
};

export const getPersonTypeFromLocation = (location: Location): PersonType => {
  if (
    location.pathname.includes(routes.ROUTE_PROMOTERS) ||
    location.pathname.includes(routes.ROUTE_PAYOFF_PROMOTERS)
  ) {
    return PersonType.Promoter;
  } else if (
    location.pathname.includes(routes.ROUTE_BARTENDERS) ||
    location.pathname.includes(routes.ROUTE_PAYOFF_BARTENDERS)
  ) {
    return PersonType.Bartender;
  } else if (
    location.pathname.includes(routes.ROUTE_TECHNICIANS) ||
    location.pathname.includes(routes.ROUTE_PAYOFF_TECHNICIANS)
  ) {
    return PersonType.Technician;
  } else if (location.pathname.includes(routes.ROUTE_BRAND_MANAGERS)) {
    return PersonType.BrandManager;
  } else {
    return PersonType.Hostess;
  }
};

export const isAdminOrPromoterOrBrandManager = (userRole?: UserRole): boolean => {
  return userRole === UserRole.Admin || userRole === UserRole.Promoter || userRole === UserRole.BrandManager;
};

export const isAdminOrPromoter = (userRole?: UserRole): boolean => {
  return userRole === UserRole.Admin || userRole === UserRole.Promoter;
};

export const isAdminOrBrandManager = (userRole?: UserRole): boolean => {
  return userRole === UserRole.Admin || userRole === UserRole.BrandManager;
};

export const isAdmin = (userRole?: UserRole): boolean => {
  return userRole === UserRole.Admin;
};

export const isBrandManager = (userRole?: UserRole): boolean => {
  return userRole === UserRole.BrandManager;
};

export const getRoleFromRoleText = (role: UserRoleText): UserRole => {
  switch (role) {
    case UserRoleText.Hostess:
      return UserRole.Hostess;
    case UserRoleText.Promoter:
      return UserRole.Promoter;
    case UserRoleText.Technician:
      return UserRole.Technician;
    case UserRoleText.Bartender:
      return UserRole.Bartender;
    case UserRoleText.BrandManager:
      return UserRole.BrandManager;
    case UserRoleText.Admin:
      return UserRole.Admin;
    case UserRoleText.None:
      return UserRole.None;
    default:
      return UserRole.None;
  }
};

export const checkIfPersonIsValidWithCallbackFunction = (
  callbackFunction: (newValue: boolean) => void,
  userRole: UserRole | undefined,
  userPersonId: number | null | undefined,
  userBrandId: number | null | undefined
): void => {
  if (userRole === UserRole.Admin) {
    callbackFunction(true);
  } else if (
    (userRole === UserRole.Bartender ||
      userRole === UserRole.Hostess ||
      userRole === UserRole.Technician ||
      userRole === UserRole.Promoter) &&
    userPersonId
  ) {
    callbackFunction(true);
  } else if (userRole === UserRole.BrandManager && userBrandId) {
    callbackFunction(true);
  } else {
    callbackFunction(false);
  }
};

export const checkIfPersonIsValid = (
  userRole: UserRole | undefined,
  userPersonId: number | null | undefined,
  userBrandId: number | null | undefined
): boolean => {
  if (userRole === UserRole.Admin) {
    return true;
  } else if (
    (userRole === UserRole.Bartender ||
      userRole === UserRole.Hostess ||
      userRole === UserRole.Technician ||
      userRole === UserRole.Promoter) &&
    userPersonId
  ) {
    return true;
  } else if (userRole === UserRole.BrandManager && userBrandId) {
    return true;
  } else {
    return false;
  }
};

export const checkIsAuthorizedRoute = (
  callbackFunction: (newValue: boolean) => void,
  newRoute: string
): void => {
  if (newRoute?.includes(ApplicationPaths.Login)) {
    callbackFunction(true);
  } else if (newRoute?.includes(ApplicationPaths.LoginFailed)) {
    callbackFunction(true);
  } else if (newRoute?.includes(ApplicationPaths.LoginCallback)) {
    callbackFunction(true);
  } else if (newRoute?.includes(ApplicationPaths.Profile)) {
    callbackFunction(true);
  } else if (newRoute?.includes(ApplicationPaths.Register)) {
    callbackFunction(true);
  } else if (newRoute?.includes(ApplicationPaths.LogOut)) {
    callbackFunction(true);
  } else if (newRoute?.includes(ApplicationPaths.LogOutCallback)) {
    callbackFunction(true);
  } else if (newRoute?.includes(ApplicationPaths.LoggedOut)) {
    callbackFunction(true);
  } else {
    callbackFunction(false);
  }
};

export const getHourlyRateConfigKeyFromPersonType = (personType?: PersonType): ConfigKey => {
  switch (personType) {
    case PersonType.Hostess:
      return ConfigKey.HourlyRateHostess;
    case PersonType.Promoter:
      return ConfigKey.HourlyRatePromoter;
    case PersonType.Technician:
      return ConfigKey.HourlyRateTechnician;
    case PersonType.Bartender:
      return ConfigKey.HourlyRateBartender;
    default:
      return ConfigKey.HourlyRateHostess;
  }
};

export const getDefaultHourlyRateFromPersonType = (personType?: PersonType): number => {
  switch (personType) {
    case PersonType.Hostess:
      return hourlyRateHostess;
    case PersonType.Promoter:
      return hourlyRatePromoter;
    case PersonType.Technician:
      return hourlyRateTechnician;
    case PersonType.Bartender:
      return hourlyRateBartender;
    default:
      return hourlyRateHostess;
  }
};
