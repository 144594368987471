/* eslint-disable react-hooks/exhaustive-deps */
import Table, { ColumnsType } from "antd/lib/table";
import React, { ReactElement, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { translations } from "../../config/translations";
import { BaseViewModel, IdViewModel } from "../../core/models/Base";
import { DrawerState } from "../../core/models/Enum";
import { LocationVM } from "../../core/models/Location";
import { TableAction } from "../../core/models/TableAction";
import { getLocationsList } from "../../core/services/LocationService";
import { useAuthContext } from "../../helpers/AuthContext";
import {
  checkStringOnFilter,
  genericTableProps,
  getActionsColumn,
  getColumnSearchProps,
  onRowClick
} from "../../helpers/TableHelper";

interface Props {
  shouldUpdate: boolean;
  setShouldUpdateState: (newState: boolean) => void;
  handleDeleteModalChange: (
    newModalVisibility: boolean,
    newShouldUpdate: boolean,
    id?: number
  ) => void;
  handleDrawerChange: (
    newDrawerState: DrawerState,
    newShouldUpdate: boolean,
    id?: number
  ) => void;
}

const LocationsTable: React.FC<Props> = (props: Props) => {
  const {
    shouldUpdate,
    setShouldUpdateState,
    handleDeleteModalChange,
    handleDrawerChange,
  } = props;
  const authContext = useAuthContext();
  const { language, setLoading } = useAuthContext();
  const [tableData, setTableData] = useState<LocationVM[]>([]);

  useEffect(() => {
    getTableData();
  }, []);

  useEffect(() => {
    if (shouldUpdate) {
      setShouldUpdateState(false);
      getTableData();
    }
  }, [shouldUpdate]);

  const getTableData = async () => {
    setLoading(true);

    const data = await getLocationsList(authContext);

    if (data) {
      setTableData(data);
    }

    setLoading(false);
  };

  const actionsContent = (value: IdViewModel): TableAction[] => {
    const returnActions: TableAction[] = [
      {
        id: 1,
        entity: value,
        className: "details-action-button",
        label: translations[language].edit,
        onClick: (): void =>
          handleDrawerChange(DrawerState.Edit, false, value.id),
      },
      {
        id: 2,
        entity: value,
        className: "details-action-button",
        label: translations[language].delete,
        onClick: (): void => handleDeleteModalChange(true, false, value.id),
      },
    ];

    return returnActions;
  };

  const columns: ColumnsType<LocationVM> = isMobile
    ? [
        {
          title: translations[language].name,
          dataIndex: "name",
          ...getColumnSearchProps(language),
          onFilter: (
            value: string | number | boolean,
            record: LocationVM
          ): boolean => checkStringOnFilter(value, record.name),
        },
        {
          title: translations[language].city,
          dataIndex: "city",
          ...getColumnSearchProps(language),
          onFilter: (
            value: string | number | boolean,
            record: LocationVM
          ): boolean => checkStringOnFilter(value, record.city.name),
          render: (value: BaseViewModel) => value.name,
        },
        {
          title: translations[language].phone,
          dataIndex: "phone",
          ...getColumnSearchProps(language),
          onFilter: (
            value: string | number | boolean,
            record: LocationVM
          ): boolean => checkStringOnFilter(value, record.phone),
        },
        {
          title: translations[language].actions,
          dataIndex: "",
          key: "",
          width: 55,
          render: (value: LocationVM): ReactElement =>
            getActionsColumn(value, actionsContent),
        },
      ]
    : [
        {
          title: translations[language].name,
          dataIndex: "name",
          ...getColumnSearchProps(language),
          onFilter: (
            value: string | number | boolean,
            record: LocationVM
          ): boolean => checkStringOnFilter(value, record.name),
        },
        {
          title: translations[language].representative,
          dataIndex: "representative",
          ...getColumnSearchProps(language),
          onFilter: (
            value: string | number | boolean,
            record: LocationVM
          ): boolean => checkStringOnFilter(value, record.representative.name),
          render: (value: BaseViewModel) => value.name,
        },
        {
          title: translations[language].region,
          dataIndex: "region",
          ...getColumnSearchProps(language),
          onFilter: (
            value: string | number | boolean,
            record: LocationVM
          ): boolean => checkStringOnFilter(value, record.region.name),
          render: (value: BaseViewModel) => value.name,
        },
        {
          title: translations[language].city,
          dataIndex: "city",
          ...getColumnSearchProps(language),
          onFilter: (
            value: string | number | boolean,
            record: LocationVM
          ): boolean => checkStringOnFilter(value, record.city.name),
          render: (value: BaseViewModel) => value.name,
        },
        {
          title: translations[language].locationType,
          dataIndex: "locationType",
          ...getColumnSearchProps(language),
          onFilter: (
            value: string | number | boolean,
            record: LocationVM
          ): boolean => checkStringOnFilter(value, record.locationType.name),
          render: (value: BaseViewModel) => value.name,
        },
        {
          title: translations[language].street,
          dataIndex: "street",
          ...getColumnSearchProps(language),
          onFilter: (
            value: string | number | boolean,
            record: LocationVM
          ): boolean => checkStringOnFilter(value, record.street),
        },
        {
          title: translations[language].contactPerson,
          dataIndex: "contactPerson",
          ...getColumnSearchProps(language),
          onFilter: (
            value: string | number | boolean,
            record: LocationVM
          ): boolean => checkStringOnFilter(value, record.contactPerson),
        },
        {
          title: translations[language].phone,
          dataIndex: "phone",
          ...getColumnSearchProps(language),
          onFilter: (
            value: string | number | boolean,
            record: LocationVM
          ): boolean => checkStringOnFilter(value, record.phone),
        },
        {
          title: translations[language].mapsLink,
          dataIndex: "googleMap",
          render: (value?: string): ReactElement =>
            value ? (
              <a href={value} target="_blank" rel="noopener noreferrer">
                {value}
              </a>
            ) : (
              <></>
            ),
        },
        {
          title: translations[language].actions,
          dataIndex: "",
          key: "",
          width: 55,
          render: (value: LocationVM): ReactElement =>
            getActionsColumn(value, actionsContent),
        },
      ];

  return (
    <Table
      {...genericTableProps(language)}
      columns={columns}
      className="cursorPointer"
      size="small"
      dataSource={tableData}
      rowKey={(item): number => item.id}
      onRow={(item): { onClick: (column: any) => void } => ({
        onClick: (column: { target: { nodeName: string } }): void =>
          onRowClick(column, (): void =>
            handleDrawerChange(DrawerState.Edit, false, item.id)
          ),
      })}
    />
  );
};

export default LocationsTable;
