import { Modal } from "antd";
import React from "react";
import { translations } from "../../config/translations";
import { useAuthContext } from "../../helpers/AuthContext";

interface Props {
  title: string;
  message: string;
  isVisible: boolean;
  onOkAction: () => void | Promise<void>;
  onCancelAction: () => void | Promise<void>;
}

const DeleteModal: React.FC<Props> = (props: Props) => {
  const { title, message, isVisible, onOkAction, onCancelAction } = props;
  const { language } = useAuthContext();

  return (
    <Modal
      title={title}
      open={isVisible}
      okText={translations[language].yes}
      cancelText={translations[language].no}
      onOk={onOkAction}
      onCancel={onCancelAction}
    >
      {message}
    </Modal>
  );
};

export default DeleteModal;
