import { SaveOutlined } from "@ant-design/icons";
import { Button, Row } from "antd";
import React from "react";
import { translations } from "../../config/translations";
import { useAuthContext } from "../../helpers/AuthContext";

interface Props {
  customSubmitMessage?: string;
  customCancelMessage?: string;
  onSubmitAction?: () => void;
  onCancelAction: () => void;
}

export const DrawerButtons: React.FC<Props> = (props: Props) => {
  const { language } = useAuthContext();
  const {
    customSubmitMessage,
    customCancelMessage,
    onCancelAction,
    onSubmitAction,
  } = props;

  return (
    <Row style={{ display: "flow-root" }}>
      <div style={{ float: "right" }}>
        <Button type="default" style={{ width: 100 }} onClick={onCancelAction}>
          {customCancelMessage || translations[language].cancel}
        </Button>
        {onSubmitAction && (
          <Button
            type="primary"
            style={{
              marginLeft: 10,
              minWidth: 100,
            }}
            htmlType="submit"
            onClick={onSubmitAction}
          >
            <SaveOutlined />
            {customSubmitMessage || translations[language].save}
          </Button>
        )}
      </div>
    </Row>
  );
};
