import React from "react";
import { isMobile } from "react-device-detect";
import { translations } from "../config/translations";
import { useAuthContext } from "../helpers/AuthContext";
import "./styles.css";

export const Home: React.FC = () => {
  const { language } = useAuthContext();

  return (
    <div className="home-screen">
      <div className="home-screen-wrapper">
        <img
          src={"/images/logo-home.png"}
          alt="promobook-logo"
          className={
            isMobile ? "home-screen-image-mobile" : "home-screen-image"
          }
        />
        <div
          className={
            isMobile ? "home-screen-title-mobile" : "home-screen-title"
          }
        >
          promobook
        </div>
        <div
          className={isMobile ? "home-screen-text-mobile" : "home-screen-text"}
        >
          {translations[language].welcomeToPromobook}
          <div className={isMobile ? "company-info-mobile" : "company-info"}>
            <br />
            Premium promotion d.o.o.
            <br />
            Rapajinska 18
            <br />
            10000 Zagreb
            <br />
            OIB: 36638297451
            <br />
            mail:{" "}
            <a href="mailto:info@premiumpromotion.hr">
              info@premiumpromotion.hr
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
