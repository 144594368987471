/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input } from "antd";
import Select, { DefaultOptionType } from "antd/lib/select";
import React, { useEffect, useState } from "react";
import { translations } from "../../config/translations";
import { BaseViewModel } from "../../core/models/Base";
import { DrawerState } from "../../core/models/Enum";
import {
  SignatureDrinkDetailVM,
  SignatureDrinkFormModel
} from "../../core/models/SignatureDrink";
import { getBrandsList } from "../../core/services/BrandService";
import {
  createSignatureDrink,
  getSignatureDrinkDetails,
  updateSignatureDrink
} from "../../core/services/SignatureDrinkService";
import { useAuthContext } from "../../helpers/AuthContext";
import {
  filterSelectOption,
  formGenericRules,
  formItemCrudDrawer
} from "../../helpers/FormHelper";

interface Props {
  drawerState: DrawerState;
  selectedEntityId?: number;
  wrappedComponentRef?: React.RefObject<any>;
  onClose: (
    drawerState: DrawerState,
    shouldUpdate: boolean,
    id?: number
  ) => void;
}

const SignatureDrinkForm: React.FC<Props> = (props: Props) => {
  const authContext = useAuthContext();
  const { drawerState, selectedEntityId, wrappedComponentRef, onClose } = props;
  const { language, setLoading } = useAuthContext();
  const [selectedEntity, setSelectedEntity] = useState<
    SignatureDrinkDetailVM | undefined
  >();
  const [brands, setBrands] = useState<DefaultOptionType[]>([]);

  useEffect(() => {
    getBrands();
  }, []);

  useEffect(() => {
    if (brands.length > 0) {
      getFormData();
    }
  }, [brands]);

  const getBrands = async () => {
    const data = await getBrandsList(authContext);

    if (data.length > 0) {
      setBrands(
        data.map(
          (item: BaseViewModel): DefaultOptionType => ({
            value: item.id,
            label: item.name,
          })
        )
      );
    }
  };

  const getFormData = async () => {
    if (!selectedEntityId) return;

    setLoading(true);

    const result = await getSignatureDrinkDetails(
      authContext,
      selectedEntityId
    );

    if (result) {
      setSelectedEntity(result);
    }

    setLoading(false);
  };

  const handleSave = async (formValues: SignatureDrinkFormModel) => {
    setLoading(true, translations[language].saving);

    const result =
      drawerState === DrawerState.Edit && selectedEntityId
        ? await updateSignatureDrink(authContext, {
            id: selectedEntityId,
            name: formValues.name,
            brandId: formValues.brand,
          })
        : await createSignatureDrink(authContext, {
            name: formValues.name,
            brandId: formValues.brand,
          });

    if (result) {
      onClose(DrawerState.Closed, true, undefined);
    }

    setLoading(false);
  };

  return (
    <>
      {(drawerState === DrawerState.Create ||
        (drawerState === DrawerState.Edit && selectedEntity)) && (
        <Form
          onFinish={handleSave}
          className="form"
          {...formItemCrudDrawer}
          ref={wrappedComponentRef}
        >
          <Form.Item
            {...formGenericRules(language, true)}
            name="name"
            label={translations[language].name}
            initialValue={selectedEntity?.name}
          >
            <Input placeholder={translations[language].nameEnter} />
          </Form.Item>
          <Form.Item
            {...formGenericRules(language, true)}
            name="brand"
            label={translations[language].brand}
            initialValue={selectedEntity?.brand.id}
          >
            <Select
              showSearch
              placeholder={translations[language].brandChoose}
              options={brands}
              filterOption={(input, option) =>
                filterSelectOption(input, option)
              }
            />
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default SignatureDrinkForm;
