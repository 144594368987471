const routes = {
  ROUTE_LOCATIONS: "/locations",
  ROUTE_LOCATIONS_CREATE: "/locations/create",
  ROUTE_LOCATIONS_EDIT: "/locations/:locationId",

  ROUTE_BARTENDERS: "/bartenders",
  ROUTE_BARTENDERS_CREATE: "/bartenders/create",
  ROUTE_BARTENDERS_EDIT: "/bartenders/:bartenderId",

  ROUTE_BRAND_MANAGERS: "/brand-managers",
  ROUTE_BRAND_MANAGERS_CREATE: "/brand-managers/create",
  ROUTE_BRAND_MANAGERS_EDIT: "/brand-managers/:brandManagerId",

  ROUTE_SIGNATURE_DRINKS: "/signature-drinks",
  ROUTE_SIGNATURE_DRINKS_CREATE: "/signature-drinks/create",
  ROUTE_SIGNATURE_DRINKS_EDIT: "/signature-drinks/:signatureDrinkId",

  ROUTE_BRANDS: "/brands",
  ROUTE_BRANDS_CREATE: "/brands/create",
  ROUTE_BRANDS_EDIT: "/brands/:brandId",

  ROUTE_PRINCIPALS: "/principals",
  ROUTE_PRINCIPALS_CREATE: "/principals/create",
  ROUTE_PRINCIPALS_EDIT: "/principals/:principalId",

  ROUTE_REPRESENTATIVES: "/representatives",
  ROUTE_REPRESENTATIVES_CREATE: "/representatives/create",
  ROUTE_REPRESENTATIVES_EDIT: "/representatives/:representativeId",

  ROUTE_MAILS: "/mails",
  ROUTE_MAILS_CREATE: "/mails/create",
  ROUTE_MAILS_EDIT: "/mails/:mailId",

  ROUTE_REGIONS: "/regions",
  ROUTE_REGIONS_CREATE: "/regions/create",
  ROUTE_REGIONS_EDIT: "/regions/:regionId",

  ROUTE_CITIES: "/cities",
  ROUTE_CITIES_CREATE: "/cities/create",
  ROUTE_CITIES_EDIT: "/cities/:cityId",

  ROUTE_DASHBOARD: "/",

  ROUTE_TRAVEL_COST: "/travel-cost",
  ROUTE_HOURLY_RATE: "/hourly-rate",

  ROUTE_HOSTESSES: "/hostesses",
  ROUTE_HOSTESSES_CREATE: "/hostesses/create",
  ROUTE_HOSTESSES_EDIT: "/hostesses/:hostessId",

  ROUTE_AREAS: "/areas",
  ROUTE_AREAS_CREATE: "/areas/create",
  ROUTE_AREAS_EDIT: "/areas/:areaId",

  ROUTE_ORDERS: "/orders",
  ROUTE_ORDERS_CREATE: "/orders/create",
  ROUTE_ORDERS_EDIT: "/orders/:orderId",

  ROUTE_PROMOTERS: "/promoters",
  ROUTE_PROMOTERS_CREATE: "/promoters/create",
  ROUTE_PROMOTERS_EDIT: "/promoters/:promoterId",

  ROUTE_REPORTS: "/reports",
  ROUTE_REPORTS_CREATE: "/reports/create",
  ROUTE_REPORTS_EDIT: "/reports/:reportId",

  ROUTE_TECHNICIANS: "/technicians",
  ROUTE_TECHNICIANS_CREATE: "/technicians/create",
  ROUTE_TECHNICIANS_EDIT: "/technicians/:technicianId",

  ROUTE_PAYOFF_TECHNICIANS: "/payoff-technicians",
  ROUTE_PAYOFF_PROMOTERS: "/payoff-promoters",
  ROUTE_PAYOFF_HOSTESSES: "/payoff-hostesses",
  ROUTE_PAYOFF_BARTENDERS: "/payoff-bartenders",

  ROUTE_LOCATION_TYPES: "/location-types",
  ROUTE_LOCATION_TYPES_CREATE: "/location-types/create",
  ROUTE_LOCATION_TYPES_EDIT: "/location-types/:locationTypeId",

  ROUTE_PROMOTION_TYPES: "/promotion-types",
  ROUTE_PROMOTION_TYPES_CREATE: "/promotion-types/create",
  ROUTE_PROMOTION_TYPES_EDIT: "/promotion-types/:promotionTypeId",

  ROUTE_MUSIC_TYPES: "/music-types",
  ROUTE_MUSIC_TYPES_CREATE: "/music-types/create",
  ROUTE_MUSIC_TYPES_EDIT: "/music-types/:musicTypeId",

  ROUTE_WEATHER_TYPES: "/weather-types",
  ROUTE_WEATHER_TYPES_CREATE: "/weather-types/create",
  ROUTE_WEATHER_TYPES_EDIT: "/weather-types/:weatherTypeId",
};

export const sidebarKey = {
  Areas: "areas",
  Bartenders: "bartenders",
  BrandManagers: "brand-managers",
  Brands: "brands",
  Cities: "cities",
  Dashboard: "dashboard",
  Hostesses: "hostesses",
  HourlyRate: "hourly-rate",
  Locations: "locations",
  LocationTypes: "location-types",
  Mails: "mails",
  MusicTypes: "music-types",
  Orders: "orders",
  PayoffBartenders: "payoff-bartenders",
  PayoffHostesses: "payoff-hostesses",
  PayoffPromoters: "payoff-promoters",
  PayoffTechnicians: "payoff-technicians",
  Principals: "principals",
  Promoters: "promoters",
  PromotionTypes: "promotion-types",
  Regions: "regions",
  Reports: "reports",
  Representatives: "representatives",
  Settings: "settings",
  SignatureDrinks: "signature-drinks",
  Technicians: "technicians",
  TravelCost: "travel-cost",
  WeatherTypes: "weather-types",
};

export default routes;
