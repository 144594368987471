import { BaseViewModel } from "../models/Base";

export const getLocationFullnessList = (): BaseViewModel[] => {
  const results: BaseViewModel[] = [
    {
      id: 0,
      name: "0%",
    },
    {
      id: 1,
      name: "5%",
    },
    {
      id: 2,
      name: "10%",
    },
    {
      id: 3,
      name: "15%",
    },
    {
      id: 4,
      name: "20%",
    },
    {
      id: 5,
      name: "25%",
    },
    {
      id: 6,
      name: "30%",
    },
    {
      id: 7,
      name: "35%",
    },
    {
      id: 8,
      name: "40%",
    },
    {
      id: 9,
      name: "45%",
    },
    {
      id: 10,
      name: "50%",
    },
    {
      id: 11,
      name: "55%",
    },
    {
      id: 12,
      name: "60%",
    },
    {
      id: 13,
      name: "65%",
    },
    {
      id: 14,
      name: "70%",
    },
    {
      id: 15,
      name: "75%",
    },
    {
      id: 16,
      name: "80%",
    },
    {
      id: 17,
      name: "85%",
    },
    {
      id: 18,
      name: "90%",
    },
    {
      id: 19,
      name: "95%",
    },
    {
      id: 20,
      name: "100%",
    },
  ];

  return results;
};

export const getLocationFullnessDetails = (
  locationFullnessId: number
): BaseViewModel | undefined => ({
  id: locationFullnessId,
  name: `${locationFullnessId * 5}%`,
});
