import { IUseAuthValues } from "../../helpers/AuthContext";
import { PersonType } from "../models/Enum";
import {
  PersonCreateRequestModel,
  PersonDetailVM,
  PersonPasswordUpdateRequestModel,
  PersonUpdateRequestModel,
  PersonVM
} from "../models/Person";

export const getPersonsList = async (
  context: IUseAuthValues,
  typeId?: PersonType
): Promise<PersonVM[]> => {
  const { get } = context;

  if (typeId === undefined) return [];

  const entities = await get<PersonVM[] | undefined>(
    `/Person/type/${typeId}`
  );

  return entities || [];
};

export const getPersonDetails = async (
  context: IUseAuthValues,
  id: number
): Promise<PersonDetailVM | undefined> => {
  const { get } = context;

  const entity = await get<PersonDetailVM | undefined>(`/Person/${id}`);

  return entity;
};

export const createPerson = async (
  context: IUseAuthValues,
  newEntity: PersonCreateRequestModel
): Promise<number | undefined> => {
  const { post } = context;

  const entity = await post<number | undefined>(`/Person`, newEntity);

  return entity;
};

export const updatePerson = async (
  context: IUseAuthValues,
  updateEntity: PersonUpdateRequestModel
): Promise<number | undefined> => {
  const { put } = context;

  const entity = await put<number | undefined>(
    `/Person/${updateEntity.id}`,
    updateEntity
  );

  return entity;
};

export const changePersonPassword = async (
  context: IUseAuthValues,
  updateEntity: PersonPasswordUpdateRequestModel
): Promise<number | undefined> => {
  const { put } = context;

  const entity = await put<number | undefined>(
    `/Authorize/password/person/${updateEntity.id}`,
    updateEntity
  );

  return entity;
};

export const deletePerson = async (
  context: IUseAuthValues,
  id: number
): Promise<number | undefined> => {
  const { remove } = context;

  const entity = await remove<number | undefined>(`/Person/${id}`);

  return entity;
};
