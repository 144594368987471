/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { translations } from "../../config/translations";
import { DrawerState } from "../../core/models/Enum";
import { MailDetailVM, MailFormModel } from "../../core/models/Mail";
import {
  createMail,
  getMailDetails,
  updateMail
} from "../../core/services/MailService";
import { useAuthContext } from "../../helpers/AuthContext";
import {
  formEmailRules, formItemCrudDrawer
} from "../../helpers/FormHelper";

interface Props {
  drawerState: DrawerState;
  selectedEntityId?: number;
  wrappedComponentRef?: React.RefObject<any>;
  onClose: (
    drawerState: DrawerState,
    shouldUpdate: boolean,
    id?: number
  ) => void;
}

const MailForm: React.FC<Props> = (props: Props) => {
  const { drawerState, selectedEntityId, wrappedComponentRef, onClose } = props;
  const { language, setLoading } = useAuthContext();
  const authContext = useAuthContext();
  const [selectedEntity, setSelectedEntity] = useState<
    MailDetailVM | undefined
  >();

  useEffect(() => {
    getFormData();
  }, []);

  const getFormData = async () => {
    if (!selectedEntityId) return;

    setLoading(true);

    const result = await getMailDetails(authContext, selectedEntityId);

    if (result) {
      setSelectedEntity(result);
    }

    setLoading(false);
  };

  const handleSave = async (formValues: MailFormModel) => {
    setLoading(true, translations[language].saving);

    const result =
      drawerState === DrawerState.Edit && selectedEntityId
        ? await updateMail(authContext, {
            id: selectedEntityId,
            name: formValues.name,
          })
        : await createMail(authContext, {
            name: formValues.name,
          });

    if (result) {
      onClose(DrawerState.Closed, true, undefined);
    }

    setLoading(false);
  };

  return (
    <>
      {(drawerState === DrawerState.Create ||
        (drawerState === DrawerState.Edit && selectedEntity)) && (
        <Form
          onFinish={handleSave}
          className="form"
          {...formItemCrudDrawer}
          ref={wrappedComponentRef}
        >
          <Form.Item
            {...formEmailRules(language, true)}
            name="name"
            label={translations[language].email}
            initialValue={selectedEntity?.name}
          >
            <Input
              type="email"
              placeholder={translations[language].enterEmail}
            />
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default MailForm;
