import { SearchOutlined } from "@ant-design/icons";
import { Button, DatePicker, Row } from "antd";
import { Moment } from "moment";
import React, { useState } from "react";
import { defaultFormatDate } from "../config/constants";
import { translations } from "../config/translations";
import { useAuthContext } from "./AuthContext";

interface Props {
  selectedKeys: any;
  confirm: Function;
  setSelectedKeys: Function;
  clearFilters: Function;
}

const DateRangeFilter: React.FC<Props> = (props: Props) => {
  const { selectedKeys, confirm, setSelectedKeys, clearFilters } = props;
  const { language } = useAuthContext();
  const [resetFieldsKeyCounter, setResetFieldsKeyCounter] = useState<number>(0);
  const [inputOneValue, setInputOneValue] = useState<Moment | undefined>(
    selectedKeys[0] && selectedKeys[0][0]
  );
  const [inputTwoValue, setInputTwoValue] = useState<Moment | undefined>(
    selectedKeys[0] && selectedKeys[0][1]
  );

  const handleReset = (): void => {
    setSelectedKeys(undefined);
    setResetFieldsKeyCounter(resetFieldsKeyCounter + 1);
    setInputOneValue(undefined);
    setInputTwoValue(undefined);
    clearFilters();
    confirm();
  };

  const setSelectedValueFrom = (value: any): void => {
    setInputOneValue(value);
  };

  const setSelectedValueTo = (value: any): void => {
    setInputTwoValue(value);
  };

  const handleConfirm = (): void => {
    setSelectedKeys([[inputOneValue, inputTwoValue]]);
    confirm();
  };

  return (
    <div className="date-picker-filter">
      <div style={{ padding: 8 }}>
        <Row>
          <div style={{ marginRight: 10 }}>
            <DatePicker
              autoFocus
              placeholder={translations[language].from}
              value={inputOneValue}
              showTime={false}
              format={defaultFormatDate}
              key={`date-input-filter-1-${resetFieldsKeyCounter}`}
              onChange={setSelectedValueFrom}
            />
          </div>
          <DatePicker
            autoFocus
            placeholder={translations[language].to}
            value={inputTwoValue}
            showTime={false}
            format={defaultFormatDate}
            key={`date-input-filter-2-${resetFieldsKeyCounter}`}
            onChange={setSelectedValueTo}
          />
        </Row>
        <Row style={{ marginTop: 10 }}>
          <div style={{ alignSelf: "center" }}>
            <Button
              type="primary"
              onClick={(): void => handleConfirm()}
              icon={<SearchOutlined />}
              size="small"
            >
              {translations[language].search}
            </Button>
            <Button
              onClick={(): void => handleReset()}
              size="small"
              style={{ marginLeft: 10 }}
            >
              {translations[language].clear}
            </Button>
          </div>
        </Row>
      </div>
    </div>
  );
};

export default DateRangeFilter;
