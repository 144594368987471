/* eslint-disable react-hooks/exhaustive-deps */
import { SaveOutlined } from "@ant-design/icons";
import { Button, Form, InputNumber, PageHeader } from "antd";
import React, { useEffect, useState } from "react";
import { translations } from "../../config/translations";
import { ConfigTravelCostFormModel, ConfigVM } from "../../core/models/Config";
import { ConfigKey } from "../../core/models/Enum";
import {
  getTravelCostsList,
  updateTravelCosts
} from "../../core/services/TravelCostService";
import { useAuthContext } from "../../helpers/AuthContext";
import { PageHeaderButtonSettings } from "../../helpers/ButtonHelper";
import {
  formGenericRules,
  formItemCrudDrawer,
  submitForm
} from "../../helpers/FormHelper";

const TravelCost: React.FC = () => {
  const { language, setLoading } = useAuthContext();
  const authContext = useAuthContext();
  const [selectedEntity, setSelectedEntity] = useState<ConfigVM | undefined>();
  const [selectedEntityVan, setSelectedEntityVan] = useState<
    ConfigVM | undefined
  >();
  const ref = React.createRef();

  useEffect(() => {
    getFormData();
  }, []);

  const getFormData = async () => {
    setLoading(true);

    const result = await getTravelCostsList(authContext);

    if (result) {
      setSelectedEntity(
        result.find((r: ConfigVM): boolean => r.key === ConfigKey.TravelCost)
      );

      setSelectedEntityVan(
        result.find((r: ConfigVM): boolean => r.key === ConfigKey.TravelCostVan)
      );
    }

    setLoading(false);
  };

  const handleSave = async (formValues: ConfigTravelCostFormModel) => {
    if (!(selectedEntity !== undefined && selectedEntityVan !== undefined))
      return;
    setLoading(true, translations[language].saving);

    await updateTravelCosts(authContext, {
      configValues: [
        {
          id: selectedEntity?.id,
          key: ConfigKey.TravelCost,
          value: formValues.value,
        },
        {
          id: selectedEntityVan?.id,
          key: ConfigKey.TravelCostVan,
          value: formValues.valueVan,
        },
      ],
    });

    setLoading(false);
  };

  return (
    <>
      <PageHeader
        title={translations[language].travelCost}
        extra={[
          <Button
            key="travel-cost-button"
            {...PageHeaderButtonSettings}
            onClick={() => submitForm(ref)}
          >
            <SaveOutlined />
            {translations[language].save}
          </Button>,
        ]}
      />
      {selectedEntity && (
        <Form
          onFinish={handleSave}
          className="travel-cost-form"
          {...formItemCrudDrawer}
          ref={ref as any}
        >
          <Form.Item
            {...formGenericRules(language, true)}
            name="value"
            label={translations[language].travelCost}
            initialValue={selectedEntity?.value}
          >
            <InputNumber
              placeholder={translations[language].travelCostEnter}
              addonAfter="€/km"
            />
          </Form.Item>
          <Form.Item
            {...formGenericRules(language, true)}
            name="valueVan"
            label={translations[language].travelCostVan}
            initialValue={selectedEntityVan?.value}
          >
            <InputNumber
              placeholder={translations[language].travelCostVanEnter}
              addonAfter="€/km"
            />
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default TravelCost;
