/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Descriptions, Image, Row } from "antd";
import React, { ReactElement, useEffect, useState } from "react";
import { defaultFormatDate, defaultFormatTime } from "../../config/constants";
import { translations } from "../../config/translations";
import { BaseViewModel } from "../../core/models/Base";
import { CustomFields } from "../../core/models/Brand";
import { ImageEntityType } from "../../core/models/Enum";
import { PersonFeeVM } from "../../core/models/Order";
import {
  QuestionsReportFormModel,
  ReportDetailVM
} from "../../core/models/Report";
import { getAgeGroupDetails } from "../../core/services/AgeGroupService";
import { getEntityImages } from "../../core/services/ImageService";
import { getLocationFullnessDetails } from "../../core/services/LocationFullnessService";
import { getMenWomenRatiosDetails } from "../../core/services/MenWomenRatioService";
import { getReportDetails } from "../../core/services/ReportService";
import { useAuthContext } from "../../helpers/AuthContext";
import { convertUTCToLocalFormattedTime } from "../../helpers/TableHelper";

interface Props {
  selectedEntityId?: number;
}

const ReportReadForm: React.FC<Props> = (props: Props) => {
  const { selectedEntityId } = props;
  const { language, setLoading } = useAuthContext();
  const authContext = useAuthContext();
  const [selectedEntity, setSelectedEntity] = useState<
    ReportDetailVM | undefined
  >();
  const [hostesses, setHostesses] = useState<ReactElement[]>([]);
  const [bartenders, setBartenders] = useState<ReactElement[]>([]);
  const [technicians, setTechnicians] = useState<ReactElement[]>([]);
  const [additionalQuestions, setAdditionalQuestions] = useState<
    ReactElement[]
  >([]);
  const [convertedFiles, setConvertedFiles] = useState<string[]>([]);

  useEffect(() => {
    getFormData();
  }, []);

  useEffect(() => {
    mapHostessesData();
    mapTechniciansData();
    mapBartendersData();
    mapCustomFieldsData();
  }, [selectedEntity]);

  const getFormData = async () => {
    if (!selectedEntityId) return;

    setLoading(true);

    const result = getReportDetails(authContext, selectedEntityId);
    const results = getEntityImages(
      authContext,
      ImageEntityType.Report,
      selectedEntityId
    );

    setConvertedFiles((await results) || []);
    setSelectedEntity(await result);

    setLoading(false);
  };

  const mapCustomFieldsData = async (): Promise<void> => {
    if (selectedEntity?.brand?.questions) {
      const questions: CustomFields[] = JSON.parse(
        selectedEntity?.brand.questions
      );
      const mappedQuestions: ReactElement[] = [];

      questions.forEach((question: CustomFields): void => {
        const defValues: QuestionsReportFormModel[] = selectedEntity?.questions
          ? JSON.parse(selectedEntity.questions)
          : [];
        const selectedDefValue = defValues.find(
          (defValue: QuestionsReportFormModel): boolean =>
            defValue.key === question.key
        );

        mappedQuestions.push(
          <Descriptions.Item label={question.question}>
            {selectedDefValue?.answer === true
              ? translations[language].yes
              : translations[language].no}
          </Descriptions.Item>
        );
      });

      setAdditionalQuestions(mappedQuestions);
    } else {
      setAdditionalQuestions([]);
    }
  };

  const mapHostessesData = async (): Promise<void> => {
    if (selectedEntity?.hostesses) {
      const mappedData: ReactElement[] = [];
      selectedEntity.hostesses.forEach((person: PersonFeeVM): void => {
        mappedData.push(
          <Descriptions.Item label={translations[language].hostess}>
            {`${person.person?.firstName} ${person.person?.lastName}`}
          </Descriptions.Item>
        );
      });

      setHostesses(mappedData);
    } else {
      setHostesses([]);
    }
  };

  const mapBartendersData = async (): Promise<void> => {
    if (selectedEntity?.bartenders) {
      const mappedData: ReactElement[] = [];
      selectedEntity.bartenders.forEach((person: PersonFeeVM): void => {
        mappedData.push(
          <Descriptions.Item label={translations[language].bartender}>
            {`${person.person?.firstName} ${person.person?.lastName}`}
          </Descriptions.Item>
        );
      });

      setBartenders(mappedData);
    } else {
      setBartenders([]);
    }
  };

  const mapTechniciansData = async (): Promise<void> => {
    if (selectedEntity?.technicians) {
      const mappedData: ReactElement[] = [];
      selectedEntity.technicians.forEach((person: PersonFeeVM): void => {
        mappedData.push(
          <Descriptions.Item label={translations[language].technician}>
            {`${person.person?.firstName} ${person.person?.lastName}`}
          </Descriptions.Item>
        );
      });

      setTechnicians(mappedData);
    } else {
      setTechnicians([]);
    }
  };

  return (
    <Row className="smaller-gap-row">
      {selectedEntity && (
        <Descriptions column={1}>
          <Descriptions.Item label={translations[language].promotionDate}>
            {convertUTCToLocalFormattedTime(
              selectedEntity.timeOfPromotion,
              defaultFormatDate
            )}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].promotionTime}>
            {convertUTCToLocalFormattedTime(
              selectedEntity.timeOfPromotion,
              defaultFormatTime
            )}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].representative}>
            {selectedEntity.representative.name || "-"}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].principal}>
            {selectedEntity.principal?.name || "-"}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].region}>
            {selectedEntity.region.name || "-"}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].city}>
            {selectedEntity.city.name || "-"}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].location}>
            {selectedEntity.location?.name || "-"}
          </Descriptions.Item>

          <Descriptions.Item label={translations[language].brand}>
            {selectedEntity.brand.name || "-"}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].promotionType}>
            {selectedEntity.promotionType.name || "-"}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].promoter}>
            {selectedEntity.promoter
              ? `${selectedEntity.promoter?.person?.firstName} ${selectedEntity.promoter?.person?.lastName}`
              : ""}
          </Descriptions.Item>
          {hostesses}
          {technicians}
          {bartenders}
          <Descriptions.Item label={translations[language].weather}>
            {selectedEntity.weather?.name || "-"}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].musicType}>
            {selectedEntity.musicType?.name || "-"}
          </Descriptions.Item>

          <Descriptions.Item label={translations[language].sold07Bottles}>
            {selectedEntity.smallBottlesCount || "0"}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].sold1Bottles}>
            {selectedEntity.largeBottlesCount || "0"}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].cumulative07Sold}>
            {(
              Number(
                selectedEntity?.largeBottlesCount
                  ? selectedEntity?.largeBottlesCount / 0.7
                  : 0
              ) + Number(selectedEntity?.smallBottlesCount || 0)
            )?.toFixed(2)}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].signatureDrinks}>
            {(
              selectedEntity?.signatureDrinks?.map(
                (signatureDrink: BaseViewModel): string => signatureDrink.name
              ) || []
            ).join(", ")}
          </Descriptions.Item>
          {additionalQuestions}
          <Descriptions.Item label={translations[language].price}>
            {selectedEntity.price || "-"}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].promotionalPrice}>
            {selectedEntity.promoPrice || "-"}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].competitionBrand}>
            {selectedEntity.competitionDrink || "-"}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].ageGroup}>
            {selectedEntity.ageGroup !== undefined &&
            selectedEntity.ageGroup !== null
              ? getAgeGroupDetails(selectedEntity.ageGroup)?.name
              : "-"}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].attendeesNumber}>
            {selectedEntity.attendeesNumber || "-"}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].locationCapacity}>
            {selectedEntity.locationCapacity || "-"}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].locationFullness}>
            {selectedEntity.locationFulness !== undefined &&
            selectedEntity.locationFulness !== null
              ? getLocationFullnessDetails(selectedEntity.locationFulness)?.name
              : "-"}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].menWomenRatio}>
            {selectedEntity.menWomenRatio !== undefined &&
            selectedEntity.menWomenRatio !== null
              ? getMenWomenRatiosDetails(language, selectedEntity.menWomenRatio)
                  ?.name
              : "-"}
          </Descriptions.Item>
          <Descriptions.Item
            label={translations[language].promotionRatingOwner}
          >
            {selectedEntity.promotionRatingOwner || "-"}
          </Descriptions.Item>
          <Descriptions.Item
            label={translations[language].promotionRatingPromoter}
          >
            {selectedEntity.promotionRatingPromoter || "-"}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].observations}>
            {selectedEntity.observations || "-"}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].supervisor}>
            {selectedEntity.supervisor || "-"}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].promoterContact}>
            {selectedEntity.promoter?.person?.email ? (
              <a href={`mailto:${selectedEntity.promoter.person.email}`}>
                {selectedEntity.promoter.person.email}
              </a>
            ) : (
              "-"
            )}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].images}>
            <div className="preview-image-wrapper">
              <Col span={24}>
                {convertedFiles.map(
                  (convertedFile: string, index: number): ReactElement => {
                    return (
                      <Row className="image-row">
                        <Col span={24}>
                          <Image
                            src={convertedFile}
                            key={`image-file-${index}`}
                            className="preview-image"
                          />
                        </Col>
                      </Row>
                    );
                  }
                )}
              </Col>
            </div>
          </Descriptions.Item>
        </Descriptions>
      )}
    </Row>
  );
};

export default ReportReadForm;
