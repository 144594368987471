/* eslint-disable react-hooks/exhaustive-deps */
import {
  CameraOutlined,
  DeleteOutlined,
  InboxOutlined
} from "@ant-design/icons";
import { Button, Col, Form, Image, Modal, Row, UploadProps } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import React, { ReactElement, useEffect, useState } from "react";
import { maxPersonImages, maxReportImages } from "../../config/constants";
import { translations } from "../../config/translations";
import { ImageEntityType, SimpleDrawerState } from "../../core/models/Enum";
import { UploadImageModel } from "../../core/models/File";
import { getEntityImages, uploadImage } from "../../core/services/ImageService";
import { useAuthContext } from "../../helpers/AuthContext";
import { formItemCrudDrawer } from "../../helpers/FormHelper";
import { resizeFile } from "../../helpers/ImageHelper";
import "./styles.css";

interface Props {
  drawerState: SimpleDrawerState;
  entityType: ImageEntityType;
  selectedEntityId?: number;
  wrappedComponentRef?: React.RefObject<any>;
  onClose: (drawerState: SimpleDrawerState, id?: number) => void;
}

const ImagesForm: React.FC<Props> = (props: Props) => {
  const { selectedEntityId, wrappedComponentRef, entityType, onClose } = props;
  const { language, setLoading } = useAuthContext();
  const authContext = useAuthContext();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [convertedFiles, setConvertedFiles] = useState<string[]>([]);

  useEffect(() => {
    getFormData();
  }, []);

  const getFormData = async () => {
    if (!selectedEntityId) return;

    setLoading(true);

    const results = await getEntityImages(
      authContext,
      entityType,
      selectedEntityId
    );

    if (results) {
      setConvertedFiles(results);
    }

    setLoading(false);
  };

  const handleSave = async (): Promise<void> => {
    if (!selectedEntityId) return;

    if (convertedFiles?.length > 0) {
      setLoading(true, translations[language].saving);

      const model: UploadImageModel = {
        reportId:
          entityType === ImageEntityType.Report ? selectedEntityId : undefined,
        personId:
          entityType === ImageEntityType.Person ? selectedEntityId : undefined,
        files: convertedFiles,
      };

      const result = await uploadImage(authContext, model);

      if (result) {
        onClose(SimpleDrawerState.Closed, result);
      }
      setLoading(false);
    } else {
      Modal.error({
        title: translations[language].error,
        content: translations[language].atLeastOneFile,
      });
    }
  };

  const handleAddingNewFiles = async (): Promise<void> => {
    const convertedUploadedFiles = await convertBinaryFilesToBase64(
      uploadedFiles
    );
    const newConvertedFiles = [...convertedFiles, ...convertedUploadedFiles];

    setConvertedFiles(newConvertedFiles);
    setUploadedFiles([]);
    setIsModalOpen(false);
  };

  const removeImage = (index: number): void => {
    const newArray = [...convertedFiles];
    newArray.splice(index, 1);
    setConvertedFiles(newArray);
  };

  const draggerProps: UploadProps = {
    name: "file",
    multiple: true,
    maxCount:
      (entityType === ImageEntityType?.Person
        ? maxPersonImages
        : maxReportImages) - convertedFiles.length,
    accept: "image/png, image/jpg, image/jpeg",
    action: `${process.env.REACT_APP_API_HOST}/Image/Confirm`,
    onChange(info: any) {
      const { status } = info.file;

      if (status === "done" || status === "removed") {
        const mappedFiles = info.fileList?.map(
          (file: any): File => file.originFileObj
        );
        setUploadedFiles(mappedFiles);
      }
    },
  };

  const convertBinaryFilesToBase64 = async (
    filesToConvert: File[]
  ): Promise<string[]> => {
    const newConvertedFiles: string[] = [];

    for (const file of filesToConvert) {
      newConvertedFiles.push((await resizeFile(file)) as unknown as string);
    }

    return newConvertedFiles;
  };

  return (
    <Form
      onFinish={handleSave}
      className="form"
      {...formItemCrudDrawer}
      ref={wrappedComponentRef}
    >
      <Button
        type="primary"
        onClick={(): void => {
          if (
            (entityType === ImageEntityType?.Person
              ? maxPersonImages
              : maxReportImages) -
              convertedFiles.length >
            0
          ) {
            setIsModalOpen(true);
          } else {
            Modal.error({
              title: translations[language].error,
              content: translations[language].maxFileNumberReached,
            });
          }
        }}
      >
        <CameraOutlined />
        {translations[language].addNewPhotos}
      </Button>
      <div className="preview-image-wrapper">
        <Col span={24}>
          {convertedFiles.map(
            (convertedFile: string, index: number): ReactElement => (
              <Row className="image-row">
                <Col span={1}>
                  <Button
                    className="image-delete-icon"
                    type="link"
                    onClick={(): void => removeImage(index)}
                  >
                    <DeleteOutlined style={{ color: "red" }} />
                  </Button>
                </Col>
                <Col span={23}>
                  <Image
                    src={convertedFile}
                    key={`image-file-${index}`}
                    className="preview-image"
                  />
                </Col>
              </Row>
            )
          )}
        </Col>
      </div>
      <Modal
        title={translations[language].uploadImages}
        open={isModalOpen}
        onOk={(): Promise<void> => handleAddingNewFiles()}
        onCancel={(): void => setIsModalOpen(false)}
        okText={translations[language].save}
        cancelText={translations[language].cancel}
        destroyOnClose
      >
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            {`${translations[language].uploadPersonImageDescription} (max ${
              (entityType === ImageEntityType?.Person
                ? maxPersonImages
                : maxReportImages) - convertedFiles.length
            })`}
          </p>
        </Dragger>
      </Modal>
    </Form>
  );
};

export default ImagesForm;
