/* eslint-disable react-hooks/exhaustive-deps */
import Table, { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { translations } from "../../config/translations";
import { AreaVM } from "../../core/models/Area";
import { BaseViewModel, IdViewModel } from "../../core/models/Base";
import { DrawerState } from "../../core/models/Enum";
import { TableAction } from "../../core/models/TableAction";
import { getAreasList } from "../../core/services/AreaService";
import { useAuthContext } from "../../helpers/AuthContext";
import {
  checkStringOnFilter,
  genericTableProps,
  getActionsColumn,
  getColumnSearchProps,
  onRowClick
} from "../../helpers/TableHelper";

interface Props {
  shouldUpdate: boolean;
  setShouldUpdateState: (newState: boolean) => void;
  handleDeleteModalChange: (
    newModalVisibility: boolean,
    newShouldUpdate: boolean,
    id?: number
  ) => void;
  handleDrawerChange: (
    newDrawerState: DrawerState,
    newShouldUpdate: boolean,
    id?: number
  ) => void;
}

const AreasTable: React.FC<Props> = (props: Props) => {
  const {
    shouldUpdate,
    setShouldUpdateState,
    handleDeleteModalChange,
    handleDrawerChange,
  } = props;
  const { language, setLoading } = useAuthContext();
  const authContext = useAuthContext();
  const [tableData, setTableData] = useState<AreaVM[]>([]);

  useEffect(() => {
    getTableData();
  }, []);

  useEffect(() => {
    if (shouldUpdate) {
      setShouldUpdateState(false);
      getTableData();
    }
  }, [shouldUpdate]);

  const getTableData = async () => {
    setLoading(true);

    const data = await getAreasList(authContext);

    if (data) {
      setTableData(data);
    }

    setLoading(false);
  };

  const actionsContent = (value: IdViewModel): TableAction[] => {
    const returnActions: TableAction[] = [
      {
        id: 1,
        entity: value,
        className: "details-action-button",
        label: translations[language].edit,
        onClick: (): void =>
          handleDrawerChange(DrawerState.Edit, false, value.id),
      },
      {
        id: 2,
        entity: value,
        className: "details-action-button",
        label: translations[language].delete,
        onClick: (): void => handleDeleteModalChange(true, false, value.id),
      },
    ];

    return returnActions;
  };

  const columns: ColumnsType<AreaVM> = [
    {
      title: translations[language].name,
      dataIndex: "name",
      ...getColumnSearchProps(language),
      onFilter: (value: string | number | boolean, record: AreaVM): boolean =>
        checkStringOnFilter(value, record.name),
    },
    {
      title: translations[language].city,
      dataIndex: "city",
      ...getColumnSearchProps(language),
      onFilter: (value: string | number | boolean, record: AreaVM): boolean =>
        checkStringOnFilter(value, record.city.name),
      render: (value: BaseViewModel) => value.name,
    },
    {
      title: translations[language].actions,
      dataIndex: "",
      key: "",
      width: 55,
      render: (value: AreaVM): React.ReactElement =>
        getActionsColumn(value, actionsContent),
    },
  ];

  return (
    <Table
      {...genericTableProps(language)}
      columns={columns}
      className="cursorPointer"
      dataSource={tableData}
      size="small"
      rowKey={(item): number => item.id}
      onRow={(item): { onClick: (column: any) => void } => ({
        onClick: (column: { target: { nodeName: string } }): void =>
          onRowClick(column, (): void =>
            handleDrawerChange(DrawerState.Edit, false, item.id)
          ),
      })}
    />
  );
};

export default AreasTable;
