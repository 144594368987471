import { Col, Descriptions, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { ReactElement } from "react";
import { isMobile } from "react-device-detect";
import { defaultFormatDate, defaultFormatTime } from "../../config/constants";
import { translations } from "../../config/translations";
import { OrderDetailVM, PersonFeeVM } from "../../core/models/Order";
import { PersonBaseVM } from "../../core/models/Person";
import { useAuthContext } from "../../helpers/AuthContext";
import { isAdmin, isAdminOrPromoter } from "../../helpers/PersonHelper";
import {
  convertUTCToLocalFormattedTime,
  genericTableProps
} from "../../helpers/TableHelper";
import "./styles.css";

interface Props {
  selectedEntity: OrderDetailVM;
}

const OrderDetailsView: React.FC<Props> = (props: Props) => {
  const { selectedEntity } = props;
  const { language, userRole } = useAuthContext();

  const columns: ColumnsType<PersonFeeVM> = !isMobile
    ? [
        {
          title: translations[language].fullName,
          dataIndex: "person",
          render: (value?: PersonBaseVM) =>
            `${value?.firstName} ${value?.lastName}`,
        },
        {
          title: translations[language].mobilePhone,
          dataIndex: "person",
          render: (value?: PersonBaseVM) => value?.phone,
        },
        {
          title: translations[language].street,
          dataIndex: "person",
          render: (value?: PersonBaseVM) => value?.street,
        },
        {
          title: translations[language].area,
          dataIndex: "person",
          render: (value?: PersonBaseVM) => value?.area?.name || "",
        },
        {
          title: translations[language].city,
          dataIndex: "person",
          render: (value?: PersonBaseVM) => value?.city?.name,
        },
      ]
    : [
        {
          title: translations[language].fullName,
          dataIndex: "person",
          render: (value?: PersonBaseVM) =>
            `${value?.firstName} ${value?.lastName}`,
        },
        {
          title: translations[language].mobilePhone,
          dataIndex: "person",
          render: (value?: PersonBaseVM) => value?.phone,
        },
        {
          title: translations[language].street,
          dataIndex: "person",
          render: (value?: PersonBaseVM) => value?.street,
        },
      ];

  if (isAdmin(userRole)) {
    columns.push({
      title: translations[language].fee,
      dataIndex: "fee",
      render: (value?: number) =>
        value !== undefined ? `${value.toFixed(2)} €` : "",
    });
  }

  return (
    <Row className="smaller-gap-row">
      <Col span={isMobile ? 24 : 12}>
        <Descriptions column={1}>
          <Descriptions.Item label={translations[language].promotionDate}>
            {convertUTCToLocalFormattedTime(
              selectedEntity.timeOfPromotion,
              defaultFormatDate
            )}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].promotionTime}>
            {convertUTCToLocalFormattedTime(
              selectedEntity.timeOfPromotion,
              defaultFormatTime
            )}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].representative}>
            {selectedEntity.representative.name || "-"}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].principal}>
            {selectedEntity.principal?.name || "-"}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].region}>
            {selectedEntity.region.name || "-"}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].city}>
            {selectedEntity.city.name || "-"}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].location}>
            {selectedEntity.location?.name || "-"}
          </Descriptions.Item>
        </Descriptions>
      </Col>
      <Col span={isMobile ? 24 : 12}>
        <Descriptions column={1}>
          <Descriptions.Item label={translations[language].locationAddress}>
            {selectedEntity.location?.googleMap ? (
              <a
                href={selectedEntity.location.googleMap}
                target="_blank"
                rel="noreferrer"
              >
                {selectedEntity.location?.street || "-"}
              </a>
            ) : (
              <>{selectedEntity.location?.street || "-"}</>
            )}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].contactPerson}>
            {selectedEntity.location?.phone ? (
              <>
                <a href={`tel:${selectedEntity.location.phone}`}>
                  {selectedEntity.location.phone}
                </a>
                {` (${selectedEntity.location?.contactPerson || "-"})`}
              </>
            ) : (
              <>{selectedEntity.location?.contactPerson || "-"}</>
            )}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].brand}>
            {selectedEntity.brand.name || "-"}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].promotionType}>
            {selectedEntity.promotionType.name || "-"}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].note}>
            {selectedEntity.note}
          </Descriptions.Item>
          <Descriptions.Item label={translations[language].promoter}>
            {selectedEntity.promoter
              ? `${selectedEntity.promoter?.person?.firstName} ${selectedEntity.promoter?.person?.lastName}`
              : ""}
          </Descriptions.Item>
          {isAdminOrPromoter(userRole) && (
            <Descriptions.Item label={translations[language].fee}>
              {selectedEntity.promoter ? `${selectedEntity.promoter.fee}€` : ""}
            </Descriptions.Item>
          )}
        </Descriptions>
      </Col>
      {selectedEntity.hostesses &&
        selectedEntity.hostesses?.length > 0 &&
        isAdminOrPromoter(userRole) && (
          <Table
            {...genericTableProps(language)}
            columns={columns}
            className="cursorPointer"
            style={{ marginTop: 15 }}
            size="small"
            dataSource={selectedEntity.hostesses}
            pagination={false}
            title={(): ReactElement => (
              <b>{translations[language].hostesses}</b>
            )}
            rowKey={(item): number => item.participantId}
          />
        )}
      {selectedEntity.technicians &&
        selectedEntity.technicians?.length > 0 &&
        isAdminOrPromoter(userRole) && (
          <Table
            {...genericTableProps(language)}
            columns={columns}
            className="cursorPointer"
            style={{ marginTop: 15 }}
            size="small"
            dataSource={selectedEntity.technicians}
            pagination={false}
            title={(): ReactElement => (
              <b>{translations[language].technicians}</b>
            )}
            rowKey={(item): number => item.participantId}
          />
        )}
      {selectedEntity.bartenders &&
        selectedEntity.bartenders?.length > 0 &&
        isAdminOrPromoter(userRole) && (
          <Table
            {...genericTableProps(language)}
            columns={columns}
            className="cursorPointer"
            style={{ marginTop: 15 }}
            size="small"
            dataSource={selectedEntity.bartenders}
            pagination={false}
            title={(): ReactElement => (
              <b>{translations[language].bartenders}</b>
            )}
            rowKey={(item): number => item.participantId}
          />
        )}
    </Row>
  );
};

export default OrderDetailsView;
