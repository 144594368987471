import { IUseAuthValues } from "../../helpers/AuthContext";
import { BaseCreateRequestModel, BaseUpdateRequestModel, BaseViewModel } from "../models/Base";

export const getMusicTypesList = async (
  context: IUseAuthValues
): Promise<BaseViewModel[]> => {
  const { get } = context;

  const entities = await get<BaseViewModel[] | undefined>(`/MusicType`);

  return entities || [];
};

export const getMusicTypeDetails = async (
  context: IUseAuthValues,
  id: number
): Promise<BaseViewModel | undefined> => {
  const { get } = context;

  const entity = await get<BaseViewModel | undefined>(`/MusicType/${id}`);

  return entity;
};

export const createMusicType = async (
  context: IUseAuthValues,
  newEntity: BaseCreateRequestModel
): Promise<number | undefined> => {
  const { post } = context;

  const entity = await post<number | undefined>(`/MusicType`, newEntity);

  return entity;
};

export const updateMusicType = async (
  context: IUseAuthValues,
  updateEntity: BaseUpdateRequestModel
): Promise<number | undefined> => {
  const { put } = context;

  const entity = await put<number | undefined>(`/MusicType/${updateEntity.id}`, updateEntity);

  return entity;
};

export const deleteMusicType = async (context: IUseAuthValues, id: number): Promise<number | undefined> => {
  const { remove } = context;

  const entity = await remove<number | undefined>(`/MusicType/${id}`);

  return entity;
};
