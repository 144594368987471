import { IUseAuthValues } from "../../helpers/AuthContext";
import {
  AreaCreateRequestModel,
  AreaDetailVM,
  AreaUpdateRequestModel,
  AreaVM
} from "../models/Area";

export const getAreasList = async (
  context: IUseAuthValues
): Promise<AreaVM[]> => {
  const { get } = context;

  const entities = await get<AreaVM[] | undefined>(`/Area`);

  return entities || [];
};

export const getAreaDetails = async (
  context: IUseAuthValues,
  id: number
): Promise<AreaDetailVM | undefined> => {
  const { get } = context;

  const entity = await get<AreaDetailVM | undefined>(`/Area/${id}`);

  return entity;
};

export const createArea = async (
  context: IUseAuthValues,
  newEntity: AreaCreateRequestModel
): Promise<number | undefined> => {
  const { post } = context;

  const entity = await post<number | undefined>(`/Area`, newEntity);

  return entity;
};

export const updateArea = async (
  context: IUseAuthValues,
  updateEntity: AreaUpdateRequestModel
): Promise<number | undefined> => {
  const { put } = context;

  const entity = await put<number | undefined>(`/Area/${updateEntity.id}`, updateEntity);

  return entity;
};

export const deleteArea = async (context: IUseAuthValues, id: number): Promise<number | undefined> => {
  const { remove } = context;

  const entity = await remove<number | undefined>(`/Area/${id}`);

  return entity;
};
