import { IUseAuthValues } from "../../helpers/AuthContext";
import { PersonType } from "../models/Enum";
import {
  HostessCreateRequestModel,
  HostessDetailVM,
  HostessUpdateRequestModel,
  HostessVM
} from "../models/Hostess";

export const getHostessesList = async (
  context: IUseAuthValues,
  typeId: PersonType
): Promise<HostessVM[]> => {
  const { get } = context;

  const entities = await get<HostessVM[] | undefined>(`/Person/type/${typeId}`);

  return entities || [];
};

export const getHostessDetails = async (
  context: IUseAuthValues,
  id: number
): Promise<HostessDetailVM | undefined> => {
  const { get } = context;

  const entity = await get<HostessDetailVM | undefined>(`/Person/${id}`);

  return entity;
};

export const createHostess = async (
  context: IUseAuthValues,
  newEntity: HostessCreateRequestModel
): Promise<number | undefined> => {
  const { post } = context;

  const entity = await post<number | undefined>(`/Person`, newEntity);

  return entity;
};

export const updateHostess = async (
  context: IUseAuthValues,
  updateEntity: HostessUpdateRequestModel
): Promise<number | undefined> => {
  const { put } = context;

  const entity = await put<number | undefined>(`/Person/${updateEntity.id}`, updateEntity);

  return entity;
};

export const deleteHostess = async (context: IUseAuthValues, id: number): Promise<number | undefined> => {
  const { remove } = context;

  const entity = await remove<number | undefined>(`/Person/${id}`);

  return entity;
};
