import { Menu } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import React from "react";
import { TableAction } from "../../core/models/TableAction";
import "./styles.css";

interface Props {
  tableActions: TableAction[];
  className?: string;
}

export const TableActionDropdown: React.FC<Props> = (props: Props) => {
  const { tableActions, className } = props;

  return (
    <Menu
      className={className}
      items={
        tableActions?.length > 0
          ? tableActions.map(
              (action: TableAction): ItemType => ({
                key: `actions-${action.id}`,
                onClick: (): Promise<void> | void =>
                  action.onClick(action.entity),
                className: `action-button ${action.className} ${
                  action.disabled && "action-button-disabled"
                }`,
                label: action.label,
              })
            )
          : []
      }
    />
  );
};
