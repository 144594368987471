/* eslint-disable react-hooks/exhaustive-deps */
import { Form } from "antd";
import Select, { DefaultOptionType } from "antd/lib/select";
import React, { useEffect, useState } from "react";
import { translations } from "../../config/translations";
import { BaseViewModel } from "../../core/models/Base";
import { SimpleDrawerState } from "../../core/models/Enum";
import {
  ReportMailFormModel,
  ReportSendMailRequestModel
} from "../../core/models/Report";
import { getMailsList } from "../../core/services/MailService";
import { sendReportByMail } from "../../core/services/ReportService";
import { useAuthContext } from "../../helpers/AuthContext";
import {
  filterSelectOption,
  formItemCrudDrawer
} from "../../helpers/FormHelper";
import "./styles.css";

interface Props {
  selectedEntityId?: number;
  wrappedComponentRef?: React.RefObject<any>;
  onClose: (drawerState: SimpleDrawerState, id?: number) => void;
}

const SendReportByMailForm: React.FC<Props> = (props: Props) => {
  const { selectedEntityId, wrappedComponentRef, onClose } = props;
  const { language, setLoading } = useAuthContext();
  const authContext = useAuthContext();
  const [mails, setMails] = useState<DefaultOptionType[]>([]);

  useEffect(() => {
    getMails();
  }, []);

  const getMails = async () => {
    const data = await getMailsList(authContext);

    if (data.length > 0) {
      setMails(
        data.map(
          (item: BaseViewModel): DefaultOptionType => ({
            value: item.id,
            label: item.name,
          })
        )
      );
    }
  };

  const sendReport = async (formValues: ReportMailFormModel): Promise<void> => {
    if (!selectedEntityId) return;

    setLoading(true, translations[language].saving);

    const model: ReportSendMailRequestModel = {
      reportId: selectedEntityId,
      mails: formValues.mails,
    };

    const result = await sendReportByMail(authContext, model);

    if (result) {
      onClose(SimpleDrawerState.Closed);
    }

    setLoading(false);
  };

  return (
    <Form
      onFinish={sendReport}
      className="form"
      ref={wrappedComponentRef}
      {...formItemCrudDrawer}
    >
      <Form.Item name="mails" label={translations[language].mails}>
        <Select
          showSearch
          mode="multiple"
          placeholder={translations[language].mailsChoose}
          options={mails}
          filterOption={(input, option) => filterSelectOption(input, option)}
        />
      </Form.Item>
    </Form>
  );
};

export default SendReportByMailForm;
