/* eslint-disable react-hooks/exhaustive-deps */
import { DownloadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Modal,
  PageHeader,
  Row,
  Select,
  Tooltip
} from "antd";
import { DefaultOptionType } from "antd/lib/select";
import saveAs from "file-saver";
import JSZip from "jszip";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../components/delete-modal/DeleteModal";
import { DrawerButtons } from "../../components/drawer-buttons/DrawerButtons";
import {
  DRAWER_LARGE_WIDTH,
  DRAWER_MEDIUM_WIDTH,
  DRAWER_SMALL_WIDTH
} from "../../config/constants";
import routes from "../../config/routes";
import { translations } from "../../config/translations";
import { BrandVM } from "../../core/models/Brand";
import {
  DrawerState,
  ImageEntityType,
  SimpleDrawerState
} from "../../core/models/Enum";
import { DownloadImagesModel } from "../../core/models/File";
import { DownloadImageModel } from "../../core/models/Image";
import { getBrandsList } from "../../core/services/BrandService";
import { getAllEntityImages } from "../../core/services/ImageService";
import { getMonthsDropdownList } from "../../core/services/MonthService";
import { deleteReport } from "../../core/services/ReportService";
import { useAuthContext } from "../../helpers/AuthContext";
import { PageHeaderButtonSettings } from "../../helpers/ButtonHelper";
import { filterSelectOption, submitForm } from "../../helpers/FormHelper";
import {
  isAdminOrBrandManager,
  isBrandManager
} from "../../helpers/PersonHelper";
import ImagesForm from "../Image/ImagesForm";
import ReportForm from "./ReportForm";
import ReportReadForm from "./ReportReadForm";
import ReportSoldBottlesForm from "./ReportSoldBottlesForm";
import ReportsTable from "./ReportTable";
import SendReportByMailForm from "./SendReportByEmail";
import "./styles.css";

const Report: React.FC = () => {
  const detailsRef = React.useRef<any>(null);
  const ref = React.createRef();
  const bottlesRef = React.createRef();
  const imagesRef = React.createRef();
  const sendMailRef = React.createRef();
  const { language, userRole, userPersonId, userBrandId, setLoading } =
    useAuthContext();
  const authContext = useAuthContext();
  const [drawerState, setDrawerState] = useState<DrawerState>(
    DrawerState.Closed
  );
  const [imagesDrawerState, setImagesDrawerState] = useState<SimpleDrawerState>(
    SimpleDrawerState.Closed
  );
  const [bottlesFormDrawerState, setBottlesFormDrawerState] =
    useState<SimpleDrawerState>(SimpleDrawerState.Closed);
  const [months, setMonths] = useState<DefaultOptionType[]>([]);
  const [brands, setBrands] = useState<DefaultOptionType[] | undefined>();
  const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
  const [selectedBrandId, setSelectedBrandId] = useState<number | null>(null);
  const [selectedPersonId, setSelectedPersonId] = useState<number | null>(null);
  const [shouldTableUpdate, setShouldTableUpdate] = useState<boolean>(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [selectedEntityId, setSelectedEntityId] = useState<
    number | undefined
  >();
  const [sendReportModalState, setSendReportModalState] =
    useState<SimpleDrawerState>(SimpleDrawerState.Closed);
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );
  const { reportId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    handleInitialRoute();
    getMonths();
    getBrands();
  }, []);

  useEffect(() => {
    if (isBrandManager(userRole)) {
      setSelectedBrandId(userBrandId || null);
    }
  }, [userBrandId]);

  useEffect(() => {
    if (!isAdminOrBrandManager(userRole)) {
      setSelectedPersonId(userPersonId || null);
    }
  }, [userPersonId]);

  const handleInitialRoute = (): void => {
    const newLocation = reportId
      ? location.pathname.replace(reportId, ":reportId")
      : location.pathname;

    switch (newLocation) {
      case routes.ROUTE_REPORTS_CREATE:
        handleDrawerChange(DrawerState.Create, false);
        return;
      case routes.ROUTE_REPORTS_EDIT:
        if (reportId) {
          handleDrawerChange(DrawerState.Edit, false, parseInt(reportId));
        }
        return;
      default:
        return;
    }
  };

  const getMonths = () => {
    const data = getMonthsDropdownList(language);

    setMonths(data);
  };

  const getBrands = async () => {
    const data = await getBrandsList(authContext);
    if (data.length > 0) {
      const mappedBrands = data.map(
        (item: BrandVM): DefaultOptionType => ({
          value: item.id,
          label: item.name,
        })
      );
      setBrands([
        {
          value: null,
          label: translations[language].all,
        },
        ...mappedBrands,
      ]);
    }
  };

  const handleDrawerChange = (
    newDrawerState: DrawerState,
    newShouldUpdate: boolean,
    id?: number
  ) => {
    setSelectedEntityId(id);
    setShouldTableUpdate(newShouldUpdate);
    setDrawerState(newDrawerState);
    navigate(getUrl(newDrawerState, id));
  };

  const getUrl = (state: DrawerState, id?: number): string => {
    switch (state) {
      case DrawerState.Create:
        return routes.ROUTE_REPORTS_CREATE;
      case DrawerState.Edit:
        return id
          ? routes.ROUTE_REPORTS_EDIT.replace(":reportId", id.toString())
          : routes.ROUTE_REPORTS;
      default:
        return routes.ROUTE_REPORTS;
    }
  };

  const handleImagesModalChange = (
    newDrawerState: SimpleDrawerState,
    id?: number
  ) => {
    setSelectedEntityId(id);
    setImagesDrawerState(newDrawerState);
  };

  const handleSoldBottlesFormState = (
    newDrawerState: SimpleDrawerState,
    newShouldUpdate: boolean,
    id?: number
  ) => {
    setSelectedEntityId(id);
    setShouldTableUpdate(newShouldUpdate);
    setBottlesFormDrawerState(newDrawerState);
  };

  const handleDeleteModalChange = (
    newModalVisibility: boolean,
    newShouldUpdate: boolean,
    id?: number
  ) => {
    setSelectedEntityId(id);
    setShouldTableUpdate(newShouldUpdate);
    setDeleteModalVisible(newModalVisibility);
  };

  const handleSendReportModalState = (
    newModalState: SimpleDrawerState,
    reportId?: number
  ): void => {
    setSendReportModalState(newModalState);
    setSelectedEntityId(reportId);
  };

  const deleteEntity = async (): Promise<void> => {
    if (!selectedEntityId) return;

    setLoading(true, translations[language].deleting);

    const result = await deleteReport(authContext, selectedEntityId);

    if (result) {
      handleDeleteModalChange(false, true);
    }

    setLoading(false);
  };

  const downloadGroupedImages = async (): Promise<void> => {
    if (!(selectedBrandId && selectedMonth && selectedYear)) return;

    setLoading(true);
    const images = await getAllEntityImages(
      authContext,
      ImageEntityType.Report,
      {
        year: selectedYear,
        month: selectedMonth,
        brand: selectedBrandId,
      }
    );

    const zip = new JSZip();

    const mappedImages: DownloadImagesModel[] = images.map(
      (image: DownloadImageModel): DownloadImagesModel => ({
        fileName: image.name,
        base64: image.image,
      })
    );

    mappedImages.forEach((mappedImage: DownloadImagesModel): void => {
      zip.file(mappedImage.fileName, mappedImage.base64, { base64: true });
    });

    const selectedBrand = brands?.find(
      (brand: DefaultOptionType): boolean => brand.value === selectedBrandId
    );

    const brandName = selectedBrand?.label?.toString();
    const fileName = `Report-${
      brandName ? `${brandName}-` : ""
    }${selectedMonth}-${selectedYear}.zip`;

    zip.generateAsync({ type: "blob" }).then(function (content: any) {
      saveAs(content, fileName);
    });

    setLoading(false);
  };

  return (
    <>
      <PageHeader
        key="report-page-header"
        className={`report-page-header ${
          isMobile ? "mobile-report-select" : ""
        }`}
        title={translations[language].reports}
        extra={
          !isMobile
            ? [
                <Col span={24} className="page-header-wrapper">
                  <Row>
                    {!isBrandManager(userRole) && (
                      <Select
                        showSearch
                        placeholder={translations[language].brandChoose}
                        options={brands}
                        value={selectedBrandId}
                        onSelect={(value: number | undefined): void => {
                          setSelectedBrandId(value || null);
                        }}
                        filterOption={(input, option) =>
                          filterSelectOption(input, option)
                        }
                      />
                    )}
                    <Select
                      showSearch
                      placeholder={translations[language].monthChoose}
                      options={months}
                      value={selectedMonth}
                      style={{ marginLeft: 10 }}
                      onSelect={(value: number | null): void => {
                        setSelectedMonth(value);
                      }}
                      filterOption={(input, option) =>
                        filterSelectOption(input, option)
                      }
                    />
                    <DatePicker
                      defaultValue={moment(new Date())}
                      onChange={(value: Moment | null): void => {
                        if (value) {
                          setSelectedYear(value.year());
                        }
                      }}
                      style={{ marginLeft: 10 }}
                      picker="year"
                      key="date-picker"
                    />
                  </Row>
                  <Row style={{ marginTop: 10, float: "right" }}>
                    {isAdminOrBrandManager(userRole) && (
                      <>
                        {selectedMonth && selectedBrandId ? (
                          <Button
                            key="report-images-download-button"
                            {...PageHeaderButtonSettings}
                            disabled={!(selectedMonth && selectedBrandId)}
                            onClick={(): Promise<void> =>
                              downloadGroupedImages()
                            }
                          >
                            <DownloadOutlined />
                            {translations[language].downloadGroupedImages}
                          </Button>
                        ) : (
                          <Tooltip
                            title={
                              isBrandManager(userRole)
                                ? translations[language].chooseMonthToDownload
                                : translations[language]
                                    .chooseBrandAndMonthToDownload
                            }
                          >
                            <Button
                              key="report-images-download-button"
                              {...PageHeaderButtonSettings}
                              disabled={!(selectedMonth && selectedBrandId)}
                              onClick={(): Promise<void> =>
                                downloadGroupedImages()
                              }
                            >
                              <DownloadOutlined />
                              {translations[language].downloadGroupedImages}
                            </Button>
                          </Tooltip>
                        )}
                      </>
                    )}
                    <Button
                      key="report-button"
                      {...PageHeaderButtonSettings}
                      onClick={() => detailsRef?.current?.downloadXlsx()}
                    >
                      <DownloadOutlined />
                      {translations[language].downloadXlsx}
                    </Button>
                  </Row>
                </Col>,
              ]
            : [
                <>
                  {!isBrandManager(userRole) && (
                    <Select
                      showSearch
                      placeholder={translations[language].brandChoose}
                      options={brands}
                      value={selectedBrandId}
                      onSelect={(value: number | undefined): void => {
                        setSelectedBrandId(value || null);
                      }}
                      filterOption={(input, option) =>
                        filterSelectOption(input, option)
                      }
                    />
                  )}
                </>,
                <Select
                  showSearch
                  placeholder={translations[language].monthChoose}
                  options={months}
                  value={selectedMonth}
                  style={{ marginLeft: 10 }}
                  onSelect={(value: number | null): void => {
                    setSelectedMonth(value);
                  }}
                  filterOption={(input, option) =>
                    filterSelectOption(input, option)
                  }
                />,
                <DatePicker
                  defaultValue={moment(new Date())}
                  onChange={(value: Moment | null): void => {
                    if (value) {
                      setSelectedYear(value.year());
                    }
                  }}
                  picker="year"
                  key="date-picker"
                />,
              ]
        }
      />
      <ReportsTable
        year={selectedYear}
        month={selectedMonth}
        person={selectedPersonId}
        brand={selectedBrandId}
        shouldUpdate={shouldTableUpdate}
        setShouldUpdateState={setShouldTableUpdate}
        handleDrawerChange={handleDrawerChange}
        handleDeleteModalChange={handleDeleteModalChange}
        handleImagesDrawerChange={handleImagesModalChange}
        handleSoldBottlesFormState={handleSoldBottlesFormState}
        handleSendReportModalState={handleSendReportModalState}
        ref={detailsRef}
      />
      {isBrandManager(userRole) ? (
        <Drawer
          key="drawer-form"
          title={translations[language].details}
          open={!!drawerState}
          width={isMobile ? window.screen.width : DRAWER_SMALL_WIDTH}
          destroyOnClose
          onClose={() => handleDrawerChange(DrawerState.Closed, false)}
          footer={
            <DrawerButtons
              customCancelMessage={translations[language].close}
              onCancelAction={() =>
                handleDrawerChange(DrawerState.Closed, false)
              }
            />
          }
        >
          <ReportReadForm selectedEntityId={selectedEntityId} />
        </Drawer>
      ) : (
        <Drawer
          key="drawer-form"
          title={
            selectedEntityId
              ? translations[language].editReport
              : translations[language].addReport
          }
          open={!!drawerState}
          width={isMobile ? window.screen.width : DRAWER_LARGE_WIDTH}
          destroyOnClose
          onClose={() => handleDrawerChange(DrawerState.Closed, false)}
          footer={
            <DrawerButtons
              onCancelAction={() =>
                handleDrawerChange(DrawerState.Closed, false)
              }
              onSubmitAction={() => submitForm(ref)}
            />
          }
        >
          <ReportForm
            drawerState={drawerState}
            selectedEntityId={selectedEntityId}
            wrappedComponentRef={ref}
            onClose={handleDrawerChange}
          />
        </Drawer>
      )}
      <Drawer
        key="drawer-images"
        title={translations[language].editImages}
        open={!!imagesDrawerState}
        width={isMobile ? window.screen.width : DRAWER_MEDIUM_WIDTH}
        destroyOnClose
        onClose={() => handleImagesModalChange(SimpleDrawerState.Closed)}
        footer={
          <DrawerButtons
            onCancelAction={() =>
              handleImagesModalChange(SimpleDrawerState.Closed)
            }
            onSubmitAction={() => submitForm(imagesRef)}
          />
        }
      >
        <ImagesForm
          drawerState={imagesDrawerState}
          entityType={ImageEntityType.Report}
          selectedEntityId={selectedEntityId}
          wrappedComponentRef={imagesRef}
          onClose={handleImagesModalChange}
        />
      </Drawer>
      <DeleteModal
        title={translations[language].deleteReport}
        message={translations[language].confirmDeleteReport}
        isVisible={deleteModalVisible}
        onOkAction={deleteEntity}
        onCancelAction={(): void => handleDeleteModalChange(false, false)}
      />
      <Modal
        title={translations[language].sendingReport}
        open={!!sendReportModalState}
        okText={translations[language].send}
        cancelText={translations[language].cancel}
        onOk={(): void => submitForm(sendMailRef)}
        onCancel={(): void =>
          handleSendReportModalState(SimpleDrawerState.Closed)
        }
        destroyOnClose
      >
        <SendReportByMailForm
          selectedEntityId={selectedEntityId}
          wrappedComponentRef={sendMailRef}
          onClose={handleSendReportModalState}
        />
      </Modal>
      {selectedEntityId && (
        <Drawer
          key="drawer-bottles-form"
          title={translations[language].editSoldBottles}
          open={!!bottlesFormDrawerState}
          width={isMobile ? window.screen.width : DRAWER_MEDIUM_WIDTH}
          destroyOnClose
          onClose={() =>
            handleSoldBottlesFormState(SimpleDrawerState.Closed, false)
          }
          footer={
            <DrawerButtons
              onCancelAction={() =>
                handleSoldBottlesFormState(SimpleDrawerState.Closed, false)
              }
              onSubmitAction={() => submitForm(bottlesRef)}
            />
          }
        >
          <ReportSoldBottlesForm
            drawerState={bottlesFormDrawerState}
            selectedEntityId={selectedEntityId}
            wrappedComponentRef={bottlesRef}
            onClose={handleSoldBottlesFormState}
          />
        </Drawer>
      )}
    </>
  );
};

export default Report;
