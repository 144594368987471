export enum Language {
  Croatian = "hr",
  English = "en",
}

export enum DrawerState {
  Closed = 0,
  Edit = 1,
  Create = 2,
}

export enum SimpleDrawerState {
  Closed = 0,
  Opened = 1,
}

export enum PersonType {
  Hostess = 0,
  Technician = 1,
  Bartender = 2,
  Promoter = 3,
  BrandManager = 4,
}

export enum AgeGroup {
  First = 0,
  Second = 1,
  Third = 2,
  Forth = 3,
}

export enum UserRole {
  Hostess = 0,
  Promoter = 1,
  Technician = 2,
  Bartender = 3,
  BrandManager = 4,
  Admin = 10,
  None = 99,
}

export enum UserRoleText {
  Hostess = "Hostess",
  Promoter = "Promoter",
  Technician = "Technician",
  Bartender = "Bartender",
  BrandManager = "BrandManager",
  Admin = "Admin",
  None = "None",
}

export enum ConfigKey {
  TravelCost = "travel-cost",
  TravelCostVan = "travel-cost-van",
  HourlyRatePromoter = "hourly-rate-promoter",
  HourlyRateHostess = "hourly-rate-hostess",
  HourlyRateBartender = "hourly-rate-bartender",
  HourlyRateTechnician = "hourly-rate-technician",
}

export enum ImageEntityType {
  Person = 0,
  Report = 1,
}
