/* eslint-disable react-hooks/exhaustive-deps */
import { Excel } from "antd-table-saveas-excel";
import Table, { ColumnsType } from "antd/lib/table";
import saveAs from "file-saver";
import JSZip from "jszip";
import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import { isMobile } from "react-device-detect";
import {
  defaultFormatDate,
  defaultFormatDateTimeWithoutSeconds,
  defaultFormatTime
} from "../../config/constants";
import { translations } from "../../config/translations";
import { BaseViewModel, IdViewModel } from "../../core/models/Base";
import { CustomFields } from "../../core/models/Brand";
import {
  DrawerState,
  ImageEntityType,
  SimpleDrawerState,
  UserRole
} from "../../core/models/Enum";
import { DownloadImagesModel } from "../../core/models/File";
import { LocationVM } from "../../core/models/Location";
import { PersonFeeVM } from "../../core/models/Order";
import {
  QuestionsReportFormModel,
  ReportExportVM,
  ReportVM
} from "../../core/models/Report";
import { TableAction } from "../../core/models/TableAction";
import { getAgeGroupDetails } from "../../core/services/AgeGroupService";
import { getEntityImages } from "../../core/services/ImageService";
import { getLocationFullnessDetails } from "../../core/services/LocationFullnessService";
import { getMenWomenRatiosDetails } from "../../core/services/MenWomenRatioService";
import { getReportsList } from "../../core/services/ReportService";
import { useAuthContext } from "../../helpers/AuthContext";
import {
  checkIfPersonIsValid,
  isAdmin,
  isAdminOrBrandManager,
  isAdminOrPromoter,
  isAdminOrPromoterOrBrandManager,
  isBrandManager
} from "../../helpers/PersonHelper";
import {
  checkStringOnFilter,
  convertUTCToLocalFormattedTime,
  genericTableProps,
  getActionsColumn,
  getColumnSearchProps,
  getDateRangeSearchProps,
  onRowClick
} from "../../helpers/TableHelper";

interface Props {
  year: number;
  month: number | null;
  person: number | null;
  brand: number | null;
  shouldUpdate: boolean;
  setShouldUpdateState: (newState: boolean) => void;
  handleDeleteModalChange: (
    newModalVisibility: boolean,
    newShouldUpdate: boolean,
    id?: number
  ) => void;
  handleDrawerChange: (
    newDrawerState: DrawerState,
    newShouldUpdate: boolean,
    id?: number
  ) => void;
  handleSoldBottlesFormState: (
    newDrawerState: SimpleDrawerState,
    newShouldUpdate: boolean,
    id?: number
  ) => void;
  handleImagesDrawerChange: (
    newDrawerState: SimpleDrawerState,
    id?: number
  ) => void;
  handleSendReportModalState: (
    newDrawerState: SimpleDrawerState,
    id?: number
  ) => void;
}

const ReportsTable: React.FC<Props & { ref: ForwardedRef<any> }> = forwardRef(
  (props: Props, ref: ForwardedRef<any>) => {
    const {
      year,
      brand,
      month,
      person,
      shouldUpdate,
      setShouldUpdateState,
      handleDeleteModalChange,
      handleDrawerChange,
      handleSoldBottlesFormState,
      handleImagesDrawerChange,
      handleSendReportModalState,
    } = props;
    const { language, userRole, setLoading } = useAuthContext();
    const authContext = useAuthContext();
    const [tableData, setTableData] = useState<ReportVM[]>([]);

    useEffect(() => {
      getTableData();
    }, [year, brand, person, month]);

    useEffect(() => {
      if (shouldUpdate) {
        setShouldUpdateState(false);
        getTableData();
      }
    }, [shouldUpdate]);

    useImperativeHandle(ref, () => ({
      downloadXlsx: () => {
        downloadXlsx();
      },
    }));

    const getTableData = async () => {
      const isValid = checkIfPersonIsValid(userRole, person, brand);

      if (!isValid) return;

      setLoading(true);

      const data = await getReportsList(authContext, {
        year: year,
        brandId: brand,
        month: month,
        personId: !isAdminOrBrandManager(userRole) ? person : null,
      });

      if (data) {
        setTableData(data);
      }

      setLoading(false);
    };

    const downloadImages = async (id: number): Promise<void> => {
      const selectedReport = tableData.find(
        (td: ReportVM): boolean => td.id === id
      );
      if (!selectedReport) return;

      const promotionLocation = selectedReport?.location.name;
      const promotionTime = convertUTCToLocalFormattedTime(
        selectedReport.timeOfPromotion,
        defaultFormatDate
      );
      const fileName = promotionLocation
        ? `-${promotionLocation}-${promotionTime}`
        : promotionTime;

      setLoading(true);
      const images = await getEntityImages(
        authContext,
        ImageEntityType.Report,
        id
      );

      const zip = new JSZip();

      const mappedImages: DownloadImagesModel[] = images.map(
        (image: string, index: number): DownloadImagesModel => ({
          fileName: `Report${fileName}-${index + 1}.${
            image.split(";")[0].split("/")[1]
          }`,
          base64: image.split(",")[1],
        })
      );

      mappedImages.forEach((mappedImage: DownloadImagesModel): void => {
        zip.file(mappedImage.fileName, mappedImage.base64, { base64: true });
      });

      zip.generateAsync({ type: "blob" }).then(function (content: any) {
        saveAs(content, `Report${fileName}.zip`);
      });

      setLoading(false);
    };

    const downloadXlsx = async (): Promise<void> => {
      const excel = new Excel();
      excel
        .addSheet(translations[language].report)
        .addColumns(getExcelColumns());

      if (brand) {
        const dataToExport: ReportExportVM[] = [];

        tableData.forEach((row: ReportVM): void => {
          const newModel: ReportExportVM = {
            ...row,
          };

          if (row.questions) {
            const defValues: QuestionsReportFormModel[] = JSON.parse(
              row.questions
            );

            defValues.forEach((defValue: QuestionsReportFormModel): void => {
              newModel[defValue.key] = defValue.answer;
            });
          }

          dataToExport.push(newModel);
        });

        excel.addDataSource(dataToExport, {
          str2Percent: true,
        });
      } else {
        excel.addDataSource(tableData, {
          str2Percent: true,
        });
      }

      excel.saveAs("Reports.xlsx");
    };

    const actionsContent = (value: IdViewModel): TableAction[] => {
      const returnActions: TableAction[] = [
        {
          id: 1,
          entity: value,
          className: "details-action-button",
          label: isBrandManager(userRole)
            ? translations[language].details
            : translations[language].edit,
          onClick: (): void =>
            handleDrawerChange(DrawerState.Edit, false, value.id),
        },
      ];

      if (isAdminOrPromoter(userRole)) {
        returnActions.splice(1, 0, {
          id: 3,
          entity: value,
          className: "details-action-button",
          label: translations[language].editImages,
          onClick: (): void =>
            handleImagesDrawerChange(SimpleDrawerState.Opened, value.id),
        });

        returnActions.push({
          id: 5,
          entity: value,
          className: "details-action-button",
          label: translations[language].sendReport,
          onClick: (): void =>
            handleSendReportModalState(SimpleDrawerState.Opened, value.id),
        });
      }

      if (isAdminOrPromoterOrBrandManager(userRole)) {
        returnActions.splice(2, 0, {
          id: 4,
          entity: value,
          className: "details-action-button",
          label: translations[language].downloadImages,
          onClick: (): Promise<void> => downloadImages(value.id),
        });
      }

      if (isAdmin(userRole)) {
        returnActions.splice(1, 0, {
          id: 2,
          entity: value,
          className: "details-action-button",
          label: translations[language].delete,
          onClick: (): void => handleDeleteModalChange(true, false, value.id),
        });
      }

      if (isBrandManager(userRole)) {
        returnActions.push({
          id: 6,
          entity: value,
          className: "details-action-button",
          label: translations[language].editSoldBottles,
          onClick: (): void =>
            handleSoldBottlesFormState(
              SimpleDrawerState.Opened,
              false,
              value.id
            ),
        });
      }

      return returnActions;
    };

    const getExcelColumns = () => {
      const exportColumns = [
        {
          title: translations[language].brand,
          dataIndex: "brand",
          render: (value?: BaseViewModel) => value?.name,
        },
        {
          title: translations[language].promotionType,
          dataIndex: "promotionType",
          render: (value?: BaseViewModel) => value?.name,
        },
        {
          title: translations[language].promotionDate,
          dataIndex: "timeOfPromotion",
          render: (value?: Date) =>
            convertUTCToLocalFormattedTime(value, defaultFormatDate),
        },
        {
          title: translations[language].promotionTime,
          dataIndex: "timeOfPromotion",
          render: (value?: Date) =>
            convertUTCToLocalFormattedTime(value, defaultFormatTime),
        },
        {
          title: translations[language].principal,
          dataIndex: "principal",
          render: (value?: BaseViewModel) => value?.name,
        },
        {
          title: translations[language].representative,
          dataIndex: "representative",
          render: (value?: BaseViewModel) => value?.name,
        },
        {
          title: translations[language].city,
          dataIndex: "city",
          render: (value?: BaseViewModel) => value?.name,
        },
        {
          title: translations[language].location,
          dataIndex: "location",
          render: (value?: LocationVM) => value?.name,
        },
        {
          title: translations[language].locationType,
          dataIndex: "location",
          render: (value?: LocationVM) => value?.locationType?.name,
        },

        {
          title: translations[language].promoter,
          dataIndex: "promoter",
          render: (value?: PersonFeeVM) =>
            value !== null && value !== undefined
              ? `${value.person?.firstName} ${value.person?.lastName}`
              : "",
        },
        {
          title: translations[language].hostesses,
          dataIndex: "hostesses",
          render: (value?: PersonFeeVM[]) => {
            const strings: string[] =
              value?.map(
                (v: PersonFeeVM): string =>
                  `${v.person?.firstName} ${v.person?.lastName}`
              ) || [];
            return strings.join(", ");
          },
        },
        {
          title: translations[language].hostessesNo,
          dataIndex: "hostesses",
          render: (value?: PersonFeeVM[]) => {
            return value?.length || 0;
          },
        },
        {
          title: translations[language].bartenders,
          dataIndex: "bartenders",
          render: (value?: PersonFeeVM[]) => {
            const strings: string[] =
              value?.map(
                (v: PersonFeeVM): string =>
                  `${v.person?.firstName} ${v.person?.lastName}`
              ) || [];
            return strings.join(", ");
          },
        },
        {
          title: translations[language].bartendersNo,
          dataIndex: "bartenders",
          render: (value?: PersonFeeVM[]) => {
            return value?.length || 0;
          },
        },
        {
          title: translations[language].technicians,
          dataIndex: "technicians",
          render: (value?: PersonFeeVM[]) => {
            const strings: string[] =
              value?.map(
                (v: PersonFeeVM): string =>
                  `${v.person?.firstName} ${v.person?.lastName}`
              ) || [];
            return strings.join(", ");
          },
        },
        {
          title: translations[language].techniciansNo,
          dataIndex: "technicians",
          render: (value?: PersonFeeVM[]) => {
            return value?.length || 0;
          },
        },
        {
          title: translations[language].supervisor,
          dataIndex: "supervisor",
          render: (value?: string) =>
            value && value !== ""
              ? translations[language].yes
              : translations[language].no,
        },
        {
          title: translations[language].supervisor,
          dataIndex: "supervisor",
        },
        {
          title: translations[language].weather,
          dataIndex: "weather",
          render: (value?: BaseViewModel) => value?.name,
        },
        {
          title: translations[language].musicType,
          dataIndex: "musicType",
          render: (value?: BaseViewModel) => value?.name,
        },
        {
          title: translations[language].sold1Bottles,
          dataIndex: "largeBottlesCount",
          render: (value: number | null | undefined) =>
            value !== null && value !== undefined
              ? Number(value.toFixed(2))
              : "",
        },
        {
          title: translations[language].sold07Bottles,
          dataIndex: "smallBottlesCount",
          render: (value: number | null | undefined) =>
            value !== null && value !== undefined
              ? Number(value.toFixed(2))
              : "",
        },
        {
          title: translations[language].cumulative07Sold,
          dataIndex: "largeBottlesCount",
          render: (value: number | null | undefined, row: ReportVM) =>
            value !== null &&
            value !== undefined &&
            row.smallBottlesCount !== null &&
            row.smallBottlesCount !== undefined
              ? Number((value / 0.7 + row.smallBottlesCount).toFixed(2))
              : value || row?.smallBottlesCount || "",
        },
        {
          title: translations[language].price,
          dataIndex: "price",
          render: (value: number | null | undefined) =>
            value !== null && value !== undefined
              ? Number(value.toFixed(2))
              : "",
        },
        {
          title: translations[language].promotionalPrice,
          dataIndex: "promoPrice",
          render: (value: number | null | undefined) =>
            value !== null && value !== undefined
              ? Number(value.toFixed(2))
              : "",
        },
        {
          title: translations[language].competitionBrand,
          dataIndex: "competitionDrink",
        },
        {
          title: translations[language].ageGroup,
          dataIndex: "ageGroup",
          render: (value?: number) =>
            value !== null && value !== undefined
              ? getAgeGroupDetails(value)?.name
              : "",
        },
        {
          title: translations[language].attendeesNumber,
          dataIndex: "attendeesNumber",
        },
        {
          title: translations[language].locationCapacity,
          dataIndex: "locationCapacity",
        },
        {
          title: translations[language].locationFullness,
          dataIndex: "locationFulness",
          render: (value?: number) =>
            value !== null && value !== undefined
              ? getLocationFullnessDetails(value)?.name
              : "",
        },
        {
          title: translations[language].menWomenRatio,
          dataIndex: "menWomenRatio",
          render: (value?: number) =>
            value !== null && value !== undefined
              ? getMenWomenRatiosDetails(language, value)?.name
              : "",
        },
        {
          title: translations[language].promotionRatingOwner,
          dataIndex: "promotionRatingOwner",
        },
        {
          title: translations[language].promotionRatingPromoter,
          dataIndex: "promotionRatingPromoter",
        },
        {
          title: translations[language].observations,
          dataIndex: "observations",
        },
        {
          title: translations[language].signatureDrinks,
          dataIndex: "signatureDrinks",
          render: (value?: BaseViewModel[]) => {
            const strings: string[] =
              value?.map((v: BaseViewModel): string => v.name) || [];
            return strings.join(", ");
          },
        },
      ];

      if (brand) {
        if (tableData.length > 0 && tableData[0].brand?.questions) {
          const questions: CustomFields[] = JSON.parse(
            tableData[0].brand.questions
          );

          const mappedNewColumns = questions.map((question: CustomFields) => ({
            title: question.question,
            dataIndex: question.key,
            render: (value?: any) => {
              if (value === true) {
                return translations[language].yes;
              } else if (value === false) {
                return translations[language].no;
              }
              return "";
            },
          }));

          return [
            ...exportColumns.slice(0, 23),
            ...mappedNewColumns,
            ...exportColumns.slice(23),
          ];
        }
      } else {
        exportColumns.splice(23, 0, {
          title: translations[language].questions,
          dataIndex: "questions",
          render: (value: any, row: ReportVM): any => {
            if (!value) return "";

            const questions: CustomFields[] = JSON.parse(row.brand.questions);
            const mappedQuestions: string[] = [];

            questions.forEach((question: CustomFields): void => {
              const defValues: QuestionsReportFormModel[] = value
                ? JSON.parse(value)
                : [];
              const selectedDefValue = defValues.find(
                (defValue: QuestionsReportFormModel): boolean =>
                  defValue.key === question.key
              );

              mappedQuestions.push(
                `${question.question}: ${
                  selectedDefValue?.answer === true
                    ? translations[language].yes
                    : translations[language].no
                }`
              );
            });

            return mappedQuestions.join(",\n");
          },
        });
      }

      return exportColumns;
    };

    const getColumns = (): ColumnsType<ReportVM> => {
      if (isMobile) {
        return [
          {
            title: translations[language].promotionDate,
            dataIndex: "timeOfPromotion",
            ...getDateRangeSearchProps("timeOfPromotion"),
            render: (value?: Date) =>
              convertUTCToLocalFormattedTime(value, defaultFormatDate),
          },
          {
            title: translations[language].location,
            dataIndex: "location",
            render: (value?: BaseViewModel) => value?.name,
            ...getColumnSearchProps(language),
            onFilter: (
              value: string | number | boolean | undefined,
              record: ReportVM
            ): boolean => checkStringOnFilter(value, record.location.name),
          },
          {
            title: translations[language].actions,
            dataIndex: "",
            key: "",
            width: 55,
            render: (value: ReportVM): ReactElement =>
              getActionsColumn(value, actionsContent),
          },
        ];
      }

      switch (userRole) {
        case UserRole.BrandManager:
          return [
            {
              title: translations[language].promotionTimeDate,
              dataIndex: "timeOfPromotion",
              ...getDateRangeSearchProps("timeOfPromotion"),
              render: (value?: Date) =>
                convertUTCToLocalFormattedTime(
                  value,
                  defaultFormatDateTimeWithoutSeconds
                ),
            },
            {
              title: translations[language].city,
              dataIndex: "city",
              render: (value?: BaseViewModel) => value?.name,
              ...getColumnSearchProps(language),
              onFilter: (
                value: string | number | boolean | undefined,
                record: ReportVM
              ): boolean => checkStringOnFilter(value, record.city.name),
            },
            {
              title: translations[language].location,
              dataIndex: "location",
              render: (value?: BaseViewModel) => value?.name,
              ...getColumnSearchProps(language),
              onFilter: (
                value: string | number | boolean | undefined,
                record: ReportVM
              ): boolean => checkStringOnFilter(value, record.location.name),
            },
            {
              title: translations[language].promotionType,
              dataIndex: "promotionType",
              render: (value: BaseViewModel) => value.name,
              ...getColumnSearchProps(language),
              onFilter: (
                value: string | number | boolean | undefined,
                record: ReportVM
              ): boolean =>
                checkStringOnFilter(value, record.promotionType.name),
            },
            {
              title: translations[language].cumulative07Sold,
              dataIndex: "largeBottlesCount",
              render: (value: number | null | undefined, row: ReportVM) =>
                value !== null &&
                value !== undefined &&
                row.smallBottlesCount !== null &&
                row.smallBottlesCount !== undefined
                  ? (value / 0.7 + row.smallBottlesCount).toFixed(2)
                  : value?.toFixed(2) ||
                    row?.smallBottlesCount?.toFixed(2) ||
                    "",
            },
            {
              title: translations[language].actions,
              dataIndex: "",
              key: "",
              width: 55,
              render: (value: ReportVM): ReactElement =>
                getActionsColumn(value, actionsContent),
            },
          ];
        default:
          return [
            {
              title: translations[language].promotionTimeDate,
              dataIndex: "timeOfPromotion",
              ...getDateRangeSearchProps("timeOfPromotion"),
              render: (value?: Date) =>
                convertUTCToLocalFormattedTime(
                  value,
                  defaultFormatDateTimeWithoutSeconds
                ),
            },
            {
              title: translations[language].city,
              dataIndex: "city",
              render: (value?: BaseViewModel) => value?.name,
              ...getColumnSearchProps(language),
              onFilter: (
                value: string | number | boolean | undefined,
                record: ReportVM
              ): boolean => checkStringOnFilter(value, record.city.name),
            },
            {
              title: translations[language].location,
              dataIndex: "location",
              render: (value?: BaseViewModel) => value?.name,
              ...getColumnSearchProps(language),
              onFilter: (
                value: string | number | boolean | undefined,
                record: ReportVM
              ): boolean => checkStringOnFilter(value, record.location.name),
            },
            {
              title: translations[language].brand,
              dataIndex: "brand",
              render: (value?: BaseViewModel) => value?.name,
              ...getColumnSearchProps(language),
              onFilter: (
                value: string | number | boolean | undefined,
                record: ReportVM
              ): boolean => checkStringOnFilter(value, record.brand.name),
            },
            {
              title: translations[language].promoter,
              dataIndex: "promoter",
              render: (value?: PersonFeeVM) =>
                value?.person
                  ? `${value?.person?.firstName} ${value?.person?.lastName}`
                  : "",
              ...getColumnSearchProps(language),
              onFilter: (
                value: string | number | boolean | undefined,
                record: ReportVM
              ): boolean =>
                checkStringOnFilter(
                  value,
                  `${record.promoter?.person?.firstName} ${record.promoter?.person?.lastName}`
                ),
            },
            {
              title: translations[language].actions,
              dataIndex: "",
              key: "",
              width: 55,
              render: (value: ReportVM): ReactElement =>
                getActionsColumn(value, actionsContent),
            },
          ];
      }
    };

    return (
      <Table
        {...genericTableProps(language)}
        columns={getColumns()}
        className="cursorPointer"
        size="small"
        dataSource={tableData}
        rowKey={(item): number => item.id}
        onRow={(item): { onClick: (column: any) => void } => ({
          onClick: (column: { target: { nodeName: string } }): void =>
            onRowClick(column, (): void =>
              handleDrawerChange(DrawerState.Edit, false, item.id)
            ),
        })}
      />
    );
  }
);

export default ReportsTable;
