import Resizer from "react-image-file-resizer";

export const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export const resizeFile = (file: File) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            1440,
            1440,
            file.type === "image/png" ? "PNG" : "JPEG",
            88,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });