import moment, { Moment } from "moment";
import { translations } from "../config/translations";
import { Language } from "../core/models/Enum";

export const convertDateTimeToUtc = (dateToChange?: Date): Moment => {
  return moment.utc(dateToChange || new Date()).local();
};

export const convertUTCToLocalWithoutDefaultValue = (
  dateTime?: Date | null
): Moment | undefined => {
  if (!dateTime) return;

  return moment.utc(dateTime).local();
};

export const getShortDisplayValueForDayOfTheWeek = (
  language: Language,
  dayOfTheWeek: number
): string => {
  switch (dayOfTheWeek) {
    case 1:
      return translations[language].daysOfTheWeekShort.monday;
    case 2:
      return translations[language].daysOfTheWeekShort.tuesday;
    case 3:
      return translations[language].daysOfTheWeekShort.wednesday;
    case 4:
      return translations[language].daysOfTheWeekShort.thursday;
    case 5:
      return translations[language].daysOfTheWeekShort.friday;
    case 6:
      return translations[language].daysOfTheWeekShort.saturday;
    default:
      return translations[language].daysOfTheWeekShort.sunday;
  }
};
