/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { translations } from "../../config/translations";
import { DrawerState } from "../../core/models/Enum";
import {
  RepresentativeDetailVM,
  RepresentativeFormModel
} from "../../core/models/Representative";
import {
  createRepresentative,
  getRepresentativeDetails,
  updateRepresentative
} from "../../core/services/RepresentativeService";
import { useAuthContext } from "../../helpers/AuthContext";
import { formGenericRules, formItemCrudDrawer } from "../../helpers/FormHelper";

interface Props {
  drawerState: DrawerState;
  selectedEntityId?: number;
  wrappedComponentRef?: React.RefObject<any>;
  onClose: (
    drawerState: DrawerState,
    shouldUpdate: boolean,
    id?: number
  ) => void;
}

const RepresentativeForm: React.FC<Props> = (props: Props) => {
  const { drawerState, selectedEntityId, wrappedComponentRef, onClose } = props;
  const { language, setLoading } = useAuthContext();
  const authContext = useAuthContext();
  const [selectedEntity, setSelectedEntity] = useState<
    RepresentativeDetailVM | undefined
  >();

  useEffect(() => {
    getFormData();
  }, []);

  const getFormData = async () => {
    if (!selectedEntityId) return;

    setLoading(true);

    const result = await getRepresentativeDetails(
      authContext,
      selectedEntityId
    );

    if (result) {
      setSelectedEntity(result);
    }

    setLoading(false);
  };

  const handleSave = async (formValues: RepresentativeFormModel) => {
    setLoading(true, translations[language].saving);

    const result =
      drawerState === DrawerState.Edit && selectedEntityId
        ? await updateRepresentative(authContext, {
            id: selectedEntityId,
            name: formValues.name,
          })
        : await createRepresentative(authContext, {
            name: formValues.name,
          });

    if (result) {
      onClose(DrawerState.Closed, true, undefined);
    }

    setLoading(false);
  };

  return (
    <>
      {(drawerState === DrawerState.Create ||
        (drawerState === DrawerState.Edit && selectedEntity)) && (
        <Form
          onFinish={handleSave}
          className="form"
          {...formItemCrudDrawer}
          ref={wrappedComponentRef}
        >
          <Form.Item
            {...formGenericRules(language, true)}
            name="name"
            label={translations[language].name}
            initialValue={selectedEntity?.name}
          >
            <Input placeholder={translations[language].nameEnter} />
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default RepresentativeForm;
