import "antd/dist/antd.css";
import React from "react";
import { useRoutes } from "react-router-dom";
import {
  ApplicationPaths,
  LoginActions,
  LogoutActions
} from "./components/api-authorization/ApiAuthorizationConstants";
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";
import { Login } from "./components/api-authorization/Login";
import { Logout } from "./components/api-authorization/Logout";
import { Home } from "./components/Home";
import { Layout } from "./components/Layout";
import routes from "./config/routes";
import { UserRole } from "./core/models/Enum";
import "./custom.css";
import { useAuthContext } from "./helpers/AuthContext";
import Area from "./modules/Area/Area";
import Brand from "./modules/Brand/Brand";
import City from "./modules/City/City";
import HourlyRate from "./modules/HourlyRate/HourlyRate";
import Location from "./modules/Location/Location";
import LocationType from "./modules/LocationType/LocationType";
import Mail from "./modules/Mails/Mail";
import MusicType from "./modules/MusicType/MusicType";
import Order from "./modules/Order/Order";
import Payoff from "./modules/Payoff/Payoff";
import Person from "./modules/Person/Person";
import Principal from "./modules/Principal/Principal";
import PromotionType from "./modules/PromotionType/PromotionType";
import Region from "./modules/Region/Region";
import Report from "./modules/Report/Report";
import Representative from "./modules/Representative/Representative";
import SignatureDrink from "./modules/SignatureDrink/SignatureDrink";
import TravelCost from "./modules/TravelCost/TravelCost";
import WeatherType from "./modules/WeatherType/WeatherType";

const App: React.FC = () => {
  const { userRole } = useAuthContext();
  const login = {
    path: ApplicationPaths.Login,
    exact: false,
    element: <Login action={LoginActions.Login} />,
  };

  const loginFailed = {
    path: ApplicationPaths.LoginFailed,
    exact: false,
    element: <Login action={LoginActions.LoginFailed} />,
  };

  const loginCallback = {
    path: ApplicationPaths.LoginCallback,
    exact: false,
    element: <Login action={LoginActions.LoginCallback} />,
  };

  const profile = {
    path: ApplicationPaths.Profile,
    exact: false,
    element: <Login action={LoginActions.Profile} />,
  };

  const register = {
    path: ApplicationPaths.Register,
    exact: false,
    element: <Login action={LoginActions.Register} />,
  };

  const logout = {
    path: ApplicationPaths.LogOut,
    exact: false,
    element: <Logout action={LogoutActions.Logout} />,
  };

  const logoutCallback = {
    path: ApplicationPaths.LogOutCallback,
    exact: false,
    element: <Logout action={LogoutActions.LogoutCallback} />,
  };

  const loggedOut = {
    path: ApplicationPaths.LoggedOut,
    exact: false,
    element: <Logout action={LogoutActions.LoggedOut} />,
  };

  const dashboardRoutes = {
    path: routes.ROUTE_DASHBOARD,
    element: <Home />,
  };

  const citiesRoute = {
    path: routes.ROUTE_CITIES,
    element: <AuthorizeRoute component={City} />,
  };

  const citiesCreateRoute = {
    path: routes.ROUTE_CITIES_CREATE,
    element: <AuthorizeRoute component={City} />,
  };

  const citiesEditRoute = {
    path: routes.ROUTE_CITIES_EDIT,
    element: <AuthorizeRoute component={City} />,
  };

  const signatureDrinksRoute = {
    path: routes.ROUTE_SIGNATURE_DRINKS,
    element: <AuthorizeRoute component={SignatureDrink} />,
  };

  const signatureDrinksCreateRoute = {
    path: routes.ROUTE_SIGNATURE_DRINKS_CREATE,
    element: <AuthorizeRoute component={SignatureDrink} />,
  };

  const signatureDrinksEditRoute = {
    path: routes.ROUTE_SIGNATURE_DRINKS_EDIT,
    element: <AuthorizeRoute component={SignatureDrink} />,
  };

  const principalsRoute = {
    path: routes.ROUTE_PRINCIPALS,
    element: <AuthorizeRoute component={Principal} />,
  };

  const principalsCreateRoute = {
    path: routes.ROUTE_PRINCIPALS_CREATE,
    element: <AuthorizeRoute component={Principal} />,
  };

  const principalsEditRoute = {
    path: routes.ROUTE_PRINCIPALS_EDIT,
    element: <AuthorizeRoute component={Principal} />,
  };

  const brandsRoute = {
    path: routes.ROUTE_BRANDS,
    element: <AuthorizeRoute component={Brand} />,
  };

  const brandsCreateRoute = {
    path: routes.ROUTE_BRANDS_CREATE,
    element: <AuthorizeRoute component={Brand} />,
  };

  const brandsEditRoute = {
    path: routes.ROUTE_BRANDS_EDIT,
    element: <AuthorizeRoute component={Brand} />,
  };

  const representativesRoute = {
    path: routes.ROUTE_REPRESENTATIVES,
    element: <AuthorizeRoute component={Representative} />,
  };

  const representativesCreateRoute = {
    path: routes.ROUTE_REPRESENTATIVES_CREATE,
    element: <AuthorizeRoute component={Representative} />,
  };

  const representativesEditRoute = {
    path: routes.ROUTE_REPRESENTATIVES_EDIT,
    element: <AuthorizeRoute component={Representative} />,
  };

  const mailsRoute = {
    path: routes.ROUTE_MAILS,
    element: <AuthorizeRoute component={Mail} />,
  };

  const mailsCreateRoute = {
    path: routes.ROUTE_MAILS_CREATE,
    element: <AuthorizeRoute component={Mail} />,
  };

  const mailsEditRoute = {
    path: routes.ROUTE_MAILS_EDIT,
    element: <AuthorizeRoute component={Mail} />,
  };

  const regionsRoute = {
    path: routes.ROUTE_REGIONS,
    element: <AuthorizeRoute component={Region} />,
  };

  const regionsCreateRoute = {
    path: routes.ROUTE_REGIONS_CREATE,
    element: <AuthorizeRoute component={Region} />,
  };

  const regionsEditRoute = {
    path: routes.ROUTE_REGIONS_EDIT,
    element: <AuthorizeRoute component={Region} />,
  };

  const areasRoute = {
    path: routes.ROUTE_AREAS,
    element: <AuthorizeRoute component={Area} />,
  };

  const areasCreateRoute = {
    path: routes.ROUTE_AREAS_CREATE,
    element: <AuthorizeRoute component={Area} />,
  };

  const areasEditRoute = {
    path: routes.ROUTE_AREAS_EDIT,
    element: <AuthorizeRoute component={Area} />,
  };

  const locationsRoute = {
    path: routes.ROUTE_LOCATIONS,
    element: <AuthorizeRoute component={Location} />,
  };

  const locationsCreateRoute = {
    path: routes.ROUTE_LOCATIONS_CREATE,
    element: <AuthorizeRoute component={Location} />,
  };

  const locationsEditRoute = {
    path: routes.ROUTE_LOCATIONS_EDIT,
    element: <AuthorizeRoute component={Location} />,
  };

  const ordersRoute = {
    path: routes.ROUTE_ORDERS,
    element: <AuthorizeRoute component={Order} />,
  };

  const ordersCreateRoute = {
    path: routes.ROUTE_ORDERS_CREATE,
    element: <AuthorizeRoute component={Order} />,
  };

  const ordersEditRoute = {
    path: routes.ROUTE_ORDERS_EDIT,
    element: <AuthorizeRoute component={Order} />,
  };

  const promotersRoute = {
    path: routes.ROUTE_PROMOTERS,
    element: <AuthorizeRoute component={Person} />,
  };

  const promotersCreateRoute = {
    path: routes.ROUTE_PROMOTERS_CREATE,
    element: <AuthorizeRoute component={Person} />,
  };

  const promotersEditRoute = {
    path: routes.ROUTE_PROMOTERS_EDIT,
    element: <AuthorizeRoute component={Person} />,
  };

  const hostessesRoute = {
    path: routes.ROUTE_HOSTESSES,
    element: <AuthorizeRoute component={Person} />,
  };

  const hostessesCreateRoute = {
    path: routes.ROUTE_HOSTESSES_CREATE,
    element: <AuthorizeRoute component={Person} />,
  };

  const hostessesEditRoute = {
    path: routes.ROUTE_HOSTESSES_EDIT,
    element: <AuthorizeRoute component={Person} />,
  };

  const bartendersRoute = {
    path: routes.ROUTE_BARTENDERS,
    element: <AuthorizeRoute component={Person} />,
  };

  const bartendersCreateRoute = {
    path: routes.ROUTE_BARTENDERS_CREATE,
    element: <AuthorizeRoute component={Person} />,
  };

  const bartendersEditRoute = {
    path: routes.ROUTE_BARTENDERS_EDIT,
    element: <AuthorizeRoute component={Person} />,
  };

  const techniciansRoute = {
    path: routes.ROUTE_TECHNICIANS,
    element: <AuthorizeRoute component={Person} />,
  };

  const techniciansCreateRoute = {
    path: routes.ROUTE_TECHNICIANS_CREATE,
    element: <AuthorizeRoute component={Person} />,
  };

  const techniciansEditRoute = {
    path: routes.ROUTE_TECHNICIANS_EDIT,
    element: <AuthorizeRoute component={Person} />,
  };

  const brandManagersRoute = {
    path: routes.ROUTE_BRAND_MANAGERS,
    element: <AuthorizeRoute component={Person} />,
  };

  const brandManagersCreateRoute = {
    path: routes.ROUTE_BRAND_MANAGERS_CREATE,
    element: <AuthorizeRoute component={Person} />,
  };

  const brandManagersEditRoute = {
    path: routes.ROUTE_BRAND_MANAGERS_EDIT,
    element: <AuthorizeRoute component={Person} />,
  };

  const reportsRoute = {
    path: routes.ROUTE_REPORTS,
    element: <AuthorizeRoute component={Report} />,
  };

  const reportsCreateRoute = {
    path: routes.ROUTE_REPORTS_CREATE,
    element: <AuthorizeRoute component={Report} />,
  };

  const reportsEditRoute = {
    path: routes.ROUTE_REPORTS_EDIT,
    element: <AuthorizeRoute component={Report} />,
  };

  const payoffHostessesRoute = {
    path: routes.ROUTE_PAYOFF_HOSTESSES,
    element: <AuthorizeRoute component={Payoff} />,
  };

  const payoffBartendersRoute = {
    path: routes.ROUTE_PAYOFF_BARTENDERS,
    element: <AuthorizeRoute component={Payoff} />,
  };

  const payoffPromotersRoute = {
    path: routes.ROUTE_PAYOFF_PROMOTERS,
    element: <AuthorizeRoute component={Payoff} />,
  };

  const payoffTechniciansRoute = {
    path: routes.ROUTE_PAYOFF_TECHNICIANS,
    element: <AuthorizeRoute component={Payoff} />,
  };

  const locationTypesRoute = {
    path: routes.ROUTE_LOCATION_TYPES,
    element: <AuthorizeRoute component={LocationType} />,
  };

  const locationTypesCreateRoute = {
    path: routes.ROUTE_LOCATION_TYPES_CREATE,
    element: <AuthorizeRoute component={LocationType} />,
  };

  const locationTypesEditRoute = {
    path: routes.ROUTE_LOCATION_TYPES_EDIT,
    element: <AuthorizeRoute component={LocationType} />,
  };

  const promotionTypesRoute = {
    path: routes.ROUTE_PROMOTION_TYPES,
    element: <AuthorizeRoute component={PromotionType} />,
  };

  const promotionTypesCreateRoute = {
    path: routes.ROUTE_PROMOTION_TYPES_CREATE,
    element: <AuthorizeRoute component={PromotionType} />,
  };

  const promotionTypesEditRoute = {
    path: routes.ROUTE_PROMOTION_TYPES_EDIT,
    element: <AuthorizeRoute component={PromotionType} />,
  };

  const musicTypesRoute = {
    path: routes.ROUTE_MUSIC_TYPES,
    element: <AuthorizeRoute component={MusicType} />,
  };

  const musicTypesCreateRoute = {
    path: routes.ROUTE_MUSIC_TYPES_CREATE,
    element: <AuthorizeRoute component={MusicType} />,
  };

  const musicTypesEditRoute = {
    path: routes.ROUTE_MUSIC_TYPES_EDIT,
    element: <AuthorizeRoute component={MusicType} />,
  };

  const weatherTypesRoute = {
    path: routes.ROUTE_WEATHER_TYPES,
    element: <AuthorizeRoute component={WeatherType} />,
  };

  const weatherTypesCreateRoute = {
    path: routes.ROUTE_WEATHER_TYPES_CREATE,
    element: <AuthorizeRoute component={WeatherType} />,
  };

  const weatherTypesEditRoute = {
    path: routes.ROUTE_WEATHER_TYPES_EDIT,
    element: <AuthorizeRoute component={WeatherType} />,
  };

  const travelCostRoute = {
    path: routes.ROUTE_TRAVEL_COST,
    element: <AuthorizeRoute component={TravelCost} />,
  };

  const hourlyRateRoute = {
    path: routes.ROUTE_HOURLY_RATE,
    element: <AuthorizeRoute component={HourlyRate} />,
  };

  const getCorrectRoutes = () => {
    switch (userRole) {
      case UserRole.Admin:
        return [
          dashboardRoutes,
          citiesRoute,
          citiesCreateRoute,
          citiesEditRoute,
          signatureDrinksRoute,
          signatureDrinksCreateRoute,
          signatureDrinksEditRoute,
          brandsRoute,
          brandsCreateRoute,
          brandsEditRoute,
          representativesRoute,
          representativesCreateRoute,
          representativesEditRoute,
          mailsRoute,
          mailsCreateRoute,
          mailsEditRoute,
          regionsRoute,
          regionsCreateRoute,
          regionsEditRoute,
          areasRoute,
          areasCreateRoute,
          areasEditRoute,
          locationsRoute,
          locationsCreateRoute,
          locationsEditRoute,
          promotersRoute,
          promotersCreateRoute,
          promotersEditRoute,
          bartendersRoute,
          bartendersCreateRoute,
          bartendersEditRoute,
          techniciansRoute,
          techniciansCreateRoute,
          techniciansEditRoute,
          brandManagersRoute,
          brandManagersCreateRoute,
          brandManagersEditRoute,
          hostessesRoute,
          hostessesCreateRoute,
          hostessesEditRoute,
          ordersRoute,
          ordersCreateRoute,
          ordersEditRoute,
          reportsRoute,
          reportsCreateRoute,
          reportsEditRoute,
          locationTypesRoute,
          locationTypesCreateRoute,
          locationTypesEditRoute,
          weatherTypesRoute,
          weatherTypesCreateRoute,
          weatherTypesEditRoute,
          payoffHostessesRoute,
          payoffBartendersRoute,
          payoffPromotersRoute,
          payoffTechniciansRoute,
          principalsRoute,
          principalsCreateRoute,
          principalsEditRoute,
          promotionTypesRoute,
          promotionTypesCreateRoute,
          promotionTypesEditRoute,
          musicTypesRoute,
          musicTypesCreateRoute,
          musicTypesEditRoute,
          login,
          loginCallback,
          loginFailed,
          profile,
          register,
          logout,
          logoutCallback,
          loggedOut,
          travelCostRoute,
          hourlyRateRoute,
        ];
      case UserRole.Promoter:
        return [
          dashboardRoutes,
          reportsRoute,
          reportsCreateRoute,
          reportsEditRoute,
          payoffPromotersRoute,
          login,
          loginCallback,
          loginFailed,
          profile,
          logout,
          logoutCallback,
          loggedOut,
        ];
      case UserRole.Bartender:
        return [
          dashboardRoutes,
          reportsRoute,
          reportsCreateRoute,
          reportsEditRoute,
          payoffBartendersRoute,
          login,
          loginCallback,
          loginFailed,
          profile,
          logout,
          logoutCallback,
          loggedOut,
        ];
      case UserRole.Technician:
        return [
          dashboardRoutes,
          reportsRoute,
          reportsCreateRoute,
          reportsEditRoute,
          payoffTechniciansRoute,
          login,
          loginCallback,
          loginFailed,
          profile,
          logout,
          logoutCallback,
          loggedOut,
        ];
      default:
        return [
          dashboardRoutes,
          reportsRoute,
          reportsEditRoute,
          login,
          loginCallback,
          loginFailed,
          profile,
          logout,
          logoutCallback,
          loggedOut,
        ];
    }
  };

  return <Layout children={useRoutes(getCorrectRoutes())} />;
};

export default App;
