import { translations } from "../../config/translations";
import { BaseViewModel } from "../models/Base";
import { Language } from "../models/Enum";

export const getMenWomenRatiosList = (
  language: Language
): BaseViewModel[] => {
  const results: BaseViewModel[] = [
    {
      id: 0,
      name: `${translations[language].menShort}(0%) - ${translations[language].womenShort}(100%)`,
    },
    {
      id: 1,
      name: `${translations[language].menShort}(10%) - ${translations[language].womenShort}(90%)`,
    },
    {
      id: 2,
      name: `${translations[language].menShort}(20%) - ${translations[language].womenShort}(80%)`,
    },
    {
      id: 3,
      name: `${translations[language].menShort}(30%) - ${translations[language].womenShort}(70%)`,
    },
    {
      id: 4,
      name: `${translations[language].menShort}(40%) - ${translations[language].womenShort}(60%)`,
    },
    {
      id: 5,
      name: `${translations[language].menShort}(50%) - ${translations[language].womenShort}(50%)`,
    },
    {
      id: 6,
      name: `${translations[language].menShort}(60%) - ${translations[language].womenShort}(40%)`,
    },
    {
      id: 7,
      name: `${translations[language].menShort}(70%) - ${translations[language].womenShort}(30%)`,
    },
    {
      id: 8,
      name: `${translations[language].menShort}(80%) - ${translations[language].womenShort}(20%)`,
    },
    {
      id: 9,
      name: `${translations[language].menShort}(90%) - ${translations[language].womenShort}(10%)`,
    },
    {
      id: 10,
      name: `${translations[language].menShort}(100%) - ${translations[language].womenShort}(0%)`,
    },
  ];

  return results;
};

export const getMenWomenRatiosDetails = (
  language: Language,
  menWomenRatioId: number
): BaseViewModel | undefined => ({
  id: menWomenRatioId,
  name: `${translations[language].menShort}(${menWomenRatioId * 10}%) - ${translations[language].womenShort}(${100 - (menWomenRatioId * 10)})`,
});
