import { IUseAuthValues } from "../../helpers/AuthContext";
import { ImageEntityType } from "../models/Enum";
import { UploadImageModel } from "../models/File";
import { ImageFilterModel, ImageModel, DownloadImageModel, ImagesVM } from "../models/Image";

export const getEntityImages = async (
  context: IUseAuthValues,
  imageEntityType: ImageEntityType,
  id: number
): Promise<string[]> => {
  const { get } = context;

  const entity = await get<ImagesVM | undefined>(
    `/Image/${
      imageEntityType === ImageEntityType.Person ? "Person" : "Report"
    }/${id}`
  );

  return (
    entity?.images.map(
      (image: ImageModel): string =>
        `data:image/${image.extension};base64,${image.image}`
    ) || []
  );
};

export const uploadImage = async (
  context: IUseAuthValues,
  uploadModel: UploadImageModel
): Promise<number | undefined> => {
  const { post } = context;

  const mappedFiles: ImageModel[] = uploadModel.files.map(
    (file: string): ImageModel => ({
      image: file.split(",")[1],
      extension: file.split(";")[0].split("/")[1],
    })
  );

  if (uploadModel.personId) {
    const entity = await post<number | undefined>(
      `/Image/Person/${uploadModel.personId}`,
      { files: mappedFiles }
    );

    return entity;
  } else if (uploadModel.reportId) {
    const entity = await post<number | undefined>(
      `/Image/Report/${uploadModel.reportId}`,
      { files: mappedFiles }
    );

    return entity;
  }

  return;
};

export const getAllEntityImages = async (
  context: IUseAuthValues,
  imageEntityType: ImageEntityType,
  filter: ImageFilterModel
): Promise<DownloadImageModel[]> => {
  const { get } = context;

  const entity = await get<ImagesVM | undefined>(
    `/Image/${
      imageEntityType === ImageEntityType.Person ? "Person" : "Report"
    }?year=${filter.year}${filter.month ? `&month=${filter.month}` : ""}${
      filter.brand ? `&brand=${filter.brand}` : ""
    }`
  );

  return entity?.images || [];
};
