import { SearchOutlined, SmallDashOutlined } from "@ant-design/icons";
import { Button, Dropdown, Empty, Input, InputRef, Space } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { TableLocale } from "antd/lib/table/interface";
import moment from "moment";
import { ReactElement } from "react";
import { TableActionDropdown } from "../components/table-action-dropdown/TableActionDropdown";
import {
  defaultFormatDateTimeWithoutSeconds,
  dropdownStyle
} from "../config/constants";
import { translations } from "../config/translations";
import { IdViewModel } from "../core/models/Base";
import { Language } from "../core/models/Enum";
import { TableAction } from "../core/models/TableAction";
import DateRangeFilter from "./DateFilterHelper";

export const getTableLocale = (language: Language): TableLocale => ({
  emptyText: <Empty description={translations[language].noData} />,
  filterConfirm: translations[language].search,
  filterReset: translations[language].clear,
});

export const onRowClick = (
  column: { target: { nodeName: string } },
  callback: Function
): void => {
  if (column && column.target && column.target.nodeName === "TD") {
    callback();
  }
};

export const genericTableProps = (language: Language) => ({
  bordered: true,
  size: "small" as SizeType,
  scroll: { x: 120, y: 1000 },
  pagination: genericPaginationProps(language),
  locale: getTableLocale(language),
});

export const genericPaginationProps = (language: Language) => ({
  showSizeChanger: false,
  responsive: true,
  defaultPageSize: 20,
  showTotal: (value: number): string =>
    `${translations[language].totalRowCount}: ${value}`,
});

export const getActionsColumn = (
  value: IdViewModel,
  actionsContent: (value: IdViewModel) => TableAction[]
): ReactElement => (
  <Dropdown
    overlay={<TableActionDropdown tableActions={actionsContent(value)} />}
    overlayStyle={dropdownStyle}
  >
    <Button
      shape="circle"
      type="dashed"
      id="actionsButton"
      className="do-not-use-on-row-function"
    >
      <SmallDashOutlined />
    </Button>
  </Dropdown>
);

export const checkStringOnFilter = (
  value?: string | number | boolean,
  rowProp?: string
): boolean => {
  if (!(value && rowProp !== undefined)) return true;

  return rowProp.toLowerCase().includes((value as string).toLowerCase());
};

export const convertUTCToLocalFormattedTime = (
  dateTime?: Date | null,
  format?: string
): string | undefined => {
  if (!dateTime) return;

  return moment
    .utc(dateTime)
    .local()
    .format(format || defaultFormatDateTimeWithoutSeconds);
};

export const getColumnSearchProps = (language: Language) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }: any) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={(input: InputRef): void => input && input.focus()}
        placeholder={translations[language].search}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => confirm()}
        style={{ marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          {translations[language].search}
        </Button>
        <Button
          onClick={() => {
            clearFilters();
            confirm();
          }}
          size="small"
          style={{ width: 90 }}
        >
          {translations[language].clear}
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered: any): ReactElement => (
    <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  ),
});

export const getDateRangeSearchProps = (dataIndex: string): any => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }: {
    setSelectedKeys: Function;
    selectedKeys: number[] | undefined[];
    confirm: Function;
    clearFilters: Function;
  }): React.ReactElement => (
    <DateRangeFilter
      setSelectedKeys={setSelectedKeys}
      selectedKeys={selectedKeys}
      confirm={confirm}
      clearFilters={clearFilters}
    />
  ),
  onFilter: (value: any, record: any) => {
    const formatedFrom =
      value[0] !== undefined
        ? moment.utc(value[0]).local().set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
        : undefined;
    const formatedTo =
      value[1] !== undefined
        ? moment.utc(value[1]).local().set({
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 59,
          })
        : undefined;

    return record[dataIndex]
      ? (formatedFrom === undefined ||
          moment.utc(record[dataIndex]).local() >= formatedFrom) &&
          (formatedTo === undefined ||
            moment.utc(record[dataIndex]).local() <= formatedTo)
      : false;
  },
});
