import { IUseAuthValues } from "../../helpers/AuthContext";
import { PayoffFilter, PayoffVM } from "../models/Payoff";

export const getPayoffsList = async (
  context: IUseAuthValues,
  filter: PayoffFilter
): Promise<PayoffVM[]> => {
  const { get } = context;

  let query = "";

  if (filter.month !== undefined) {
    query = `${query === "" ? "" : `${query}&`}month=${filter.month}`;
  }

  if (filter.personId !== undefined) {
    query = `${query === "" ? "" : `${query}&`}personId=${filter.personId}`;
  }
  if (filter.personType !== undefined) {
    query = `${query === "" ? "" : `${query}&`}personType=${filter.personType}`;
  }
  if (filter.year !== undefined) {
    query = `${query === "" ? "" : `${query}&`}year=${filter.year}`;
  }

  const entities = await get<PayoffVM[] | undefined>(
    `/Payoff${query === "" ? "" : `?${query}`}`
  );

  return entities || [];
};
