/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input } from "antd";
import Select, { DefaultOptionType } from "antd/lib/select";
import React, { useEffect, useState } from "react";
import { translations } from "../../config/translations";
import { AreaDetailVM, AreaFormModel } from "../../core/models/Area";
import { BaseViewModel } from "../../core/models/Base";
import { DrawerState } from "../../core/models/Enum";
import {
  createArea,
  getAreaDetails,
  updateArea
} from "../../core/services/AreaService";
import { getCitiesList } from "../../core/services/CityService";
import { useAuthContext } from "../../helpers/AuthContext";
import {
  filterSelectOption,
  formGenericRules,
  formItemCrudDrawer
} from "../../helpers/FormHelper";

interface Props {
  drawerState: DrawerState;
  selectedEntityId?: number;
  wrappedComponentRef?: React.RefObject<any>;
  onClose: (
    drawerState: DrawerState,
    shouldUpdate: boolean,
    id?: number
  ) => void;
}

const AreaForm: React.FC<Props> = (props: Props) => {
  const { drawerState, selectedEntityId, wrappedComponentRef, onClose } = props;
  const { language, setLoading } = useAuthContext();
  const authContext = useAuthContext();
  const [selectedEntity, setSelectedEntity] = useState<
    AreaDetailVM | undefined
  >();
  const [cities, setCities] = useState<DefaultOptionType[]>([]);

  useEffect(() => {
    getCities();
  }, []);

  useEffect(() => {
    if (cities.length > 0) {
      getFormData();
    }
  }, [cities]);

  const getCities = async () => {
    const data = await getCitiesList(authContext);

    if (data.length > 0) {
      setCities(
        data.map(
          (item: BaseViewModel): DefaultOptionType => ({
            value: item.id,
            label: item.name,
          })
        )
      );
    }
  };

  const getFormData = async () => {
    if (!selectedEntityId) return;

    setLoading(true);

    const result = await getAreaDetails(authContext, selectedEntityId);

    if (result) {
      setSelectedEntity(result);
    }

    setLoading(false);
  };

  const handleSave = async (formValues: AreaFormModel) => {
    setLoading(true, translations[language].saving);

    const result =
      drawerState === DrawerState.Edit && selectedEntityId
        ? await updateArea(authContext, {
            id: selectedEntityId,
            name: formValues.name,
            cityId: formValues.city,
          })
        : await createArea(authContext, {
            name: formValues.name,
            cityId: formValues.city,
          });

    if (result) {
      onClose(DrawerState.Closed, true, undefined);
    }

    setLoading(false);
  };

  return (
    <>
      {(drawerState === DrawerState.Create ||
        (drawerState === DrawerState.Edit && selectedEntity)) && (
        <Form
          onFinish={handleSave}
          className="form"
          {...formItemCrudDrawer}
          ref={wrappedComponentRef}
        >
          <Form.Item
            {...formGenericRules(language, true)}
            name="name"
            label={translations[language].name}
            initialValue={selectedEntity?.name}
          >
            <Input placeholder={translations[language].nameEnter} />
          </Form.Item>
          <Form.Item
            name="city"
            label={translations[language].city}
            initialValue={selectedEntity?.city.id}
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              placeholder={translations[language].cityChoose}
              options={cities}
              filterOption={(input, option) =>
                filterSelectOption(input, option)
              }
            />
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default AreaForm;
