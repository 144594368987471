/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input } from "antd";
import Select, { DefaultOptionType } from "antd/lib/select";
import React, { useEffect, useState } from "react";
import { translations } from "../../config/translations";
import { BaseViewModel } from "../../core/models/Base";
import { CityVM } from "../../core/models/City";
import { DrawerState } from "../../core/models/Enum";
import {
  LocationDetailVM,
  LocationFormModel
} from "../../core/models/Location";
import { getCitiesList } from "../../core/services/CityService";
import {
  createLocation,
  getLocationDetails,
  updateLocation
} from "../../core/services/LocationService";
import { getLocationTypesList } from "../../core/services/LocationTypeService";
import { getRegionsList } from "../../core/services/RegionService";
import { getRepresentativesList } from "../../core/services/RepresentativeService";
import { useAuthContext } from "../../helpers/AuthContext";
import {
  filterSelectOption,
  formGenericRules,
  formItemCrudDrawer
} from "../../helpers/FormHelper";

interface Props {
  drawerState: DrawerState;
  selectedEntityId?: number;
  wrappedComponentRef?: React.RefObject<any>;
  onClose: (
    drawerState: DrawerState,
    shouldUpdate: boolean,
    id?: number
  ) => void;
}

const LocationForm: React.FC<Props> = (props: Props) => {
  const [form] = Form.useForm();
  const { drawerState, selectedEntityId, wrappedComponentRef, onClose } = props;
  const { language, setLoading } = useAuthContext();
  const authContext = useAuthContext();
  const [selectedEntity, setSelectedEntity] = useState<
    LocationDetailVM | undefined
  >();
  const [representatives, setRepresentatives] = useState<DefaultOptionType[]>(
    []
  );
  const [regions, setRegions] = useState<DefaultOptionType[]>([]);
  const [cities, setCities] = useState<CityVM[]>([]);
  const [filteredCities, setFilteredCities] = useState<DefaultOptionType[]>([]);
  const [locationTypes, setLocationTypes] = useState<DefaultOptionType[]>([]);

  useEffect(() => {
    getCities();
    getRepresentatives();
    getRegions();
    getLocationTypes();
  }, []);

  useEffect(() => {
    if (
      cities.length > 0 &&
      representatives.length > 0 &&
      regions.length > 0 &&
      locationTypes.length > 0
    ) {
      getFormData();
    }
  }, [cities, representatives, regions, locationTypes]);

  const getCities = async () => {
    const data = await getCitiesList(authContext);

    if (data.length > 0) {
      setCities(data);
      setFilteredCities(
        data.map(
          (item: BaseViewModel): DefaultOptionType => ({
            value: item.id,
            label: item.name,
          })
        )
      );
    }
  };

  const filterCityOptions = (id: number): void => {
    const filteredValues = cities.filter(
      (city: CityVM): boolean => city.region.id === id
    );

    setFilteredCities(
      filteredValues.map(
        (item: BaseViewModel): DefaultOptionType => ({
          value: item.id,
          label: item.name,
        })
      )
    );
  };

  const getRepresentatives = async () => {
    const data = await getRepresentativesList(authContext);

    if (data.length > 0) {
      setRepresentatives(
        data.map(
          (item: BaseViewModel): DefaultOptionType => ({
            value: item.id,
            label: item.name,
          })
        )
      );
    }
  };

  const getRegions = async () => {
    const data = await getRegionsList(authContext);

    if (data.length > 0) {
      setRegions(
        data.map(
          (item: BaseViewModel): DefaultOptionType => ({
            value: item.id,
            label: item.name,
          })
        )
      );
    }
  };

  const getLocationTypes = async () => {
    const data = await getLocationTypesList(authContext);

    if (data.length > 0) {
      setLocationTypes(
        data.map(
          (item: BaseViewModel): DefaultOptionType => ({
            value: item.id,
            label: item.name,
          })
        )
      );
    }
  };

  const getFormData = async () => {
    if (!selectedEntityId) return;

    setLoading(true);

    const result = await getLocationDetails(authContext, selectedEntityId);

    if (result) {
      setSelectedEntity(result);
    }

    setLoading(false);
  };

  const handleSave = async (formValues: LocationFormModel) => {
    setLoading(true, translations[language].saving);

    const result =
      drawerState === DrawerState.Edit && selectedEntityId
        ? await updateLocation(authContext, {
            id: selectedEntityId,
            name: formValues.name,
            representativeId: formValues.representative,
            regionId: formValues.region,
            cityId: formValues.city,
            locationTypeId: formValues.locationType,
            street: formValues.street,
            contactPerson: formValues.contactPerson,
            phone: formValues.phone,
            googleMap: formValues.googleMap,
          })
        : await createLocation(authContext, {
            name: formValues.name,
            representativeId: formValues.representative,
            regionId: formValues.region,
            cityId: formValues.city,
            locationTypeId: formValues.locationType,
            street: formValues.street,
            contactPerson: formValues.contactPerson,
            phone: formValues.phone,
            googleMap: formValues.googleMap,
          });

    if (result) {
      onClose(DrawerState.Closed, true, undefined);
    }

    setLoading(false);
  };

  return (
    <>
      {(drawerState === DrawerState.Create ||
        (drawerState === DrawerState.Edit && selectedEntity)) && (
        <Form
          onFinish={handleSave}
          className="form"
          {...formItemCrudDrawer}
          ref={wrappedComponentRef}
          form={form}
        >
          <Form.Item
            {...formGenericRules(language, true)}
            name="name"
            label={translations[language].name}
            initialValue={selectedEntity?.name}
          >
            <Input placeholder={translations[language].nameEnter} />
          </Form.Item>
          <Form.Item
            {...formGenericRules(language, true)}
            name="representative"
            label={translations[language].representative}
            initialValue={selectedEntity?.representative.id}
          >
            <Select
              showSearch
              placeholder={translations[language].representativeChoose}
              options={representatives}
              filterOption={(input, option) =>
                filterSelectOption(input, option)
              }
            />
          </Form.Item>
          <Form.Item
            {...formGenericRules(language, true)}
            name="region"
            label={translations[language].region}
            initialValue={selectedEntity?.region.id}
          >
            <Select
              showSearch
              placeholder={translations[language].regionChoose}
              options={regions}
              onChange={(value: number | null): void => {
                form.setFieldValue("region", value);
                form.setFieldValue("city", undefined);
                if (value) {
                  filterCityOptions(value);
                }
              }}
              filterOption={(input, option) =>
                filterSelectOption(input, option)
              }
            />
          </Form.Item>
          <Form.Item
            {...formGenericRules(language, true)}
            name="city"
            label={translations[language].city}
            initialValue={selectedEntity?.city.id}
          >
            <Select
              showSearch
              placeholder={translations[language].cityChoose}
              options={filteredCities}
              filterOption={(input, option) =>
                filterSelectOption(input, option)
              }
            />
          </Form.Item>
          <Form.Item
            {...formGenericRules(language, true)}
            name="locationType"
            label={translations[language].locationType}
            initialValue={selectedEntity?.locationType.id}
          >
            <Select
              showSearch
              placeholder={translations[language].locationTypeChoose}
              options={locationTypes}
              filterOption={(input, option) =>
                filterSelectOption(input, option)
              }
            />
          </Form.Item>
          <Form.Item
            name="street"
            label={translations[language].street}
            initialValue={selectedEntity?.street}
          >
            <Input placeholder={translations[language].streetEnter} />
          </Form.Item>
          <Form.Item
            name="contactPerson"
            label={translations[language].contactPerson}
            initialValue={selectedEntity?.contactPerson}
          >
            <Input placeholder={translations[language].contactPersonEnter} />
          </Form.Item>
          <Form.Item
            name="phone"
            label={translations[language].phone}
            initialValue={selectedEntity?.phone}
          >
            <Input placeholder={translations[language].phoneEnter} />
          </Form.Item>
          <Form.Item
            name="googleMap"
            label={translations[language].mapsLink}
            initialValue={selectedEntity?.googleMap}
          >
            <Input placeholder={translations[language].mapsLinkEnter} />
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default LocationForm;
