/* eslint-disable react-hooks/exhaustive-deps */
import { Button, DatePicker, Divider, Form, Input, InputNumber } from "antd";
import Select, { DefaultOptionType } from "antd/lib/select";
import React, { useEffect, useState } from "react";
import {
  costPerKm,
  defaultFormatDateTimeWithoutSeconds
} from "../../config/constants";
import { translations } from "../../config/translations";
import { BaseViewModel } from "../../core/models/Base";
import { CityVM } from "../../core/models/City";
import { ConfigVM } from "../../core/models/Config";
import { DrawerState, PersonType } from "../../core/models/Enum";
import { LocationVM } from "../../core/models/Location";
import {
  OrderDetailVM,
  OrderFormModel,
  PersonFeeVM
} from "../../core/models/Order";
import { PersonVM } from "../../core/models/Person";
import { PrincipalVM } from "../../core/models/Principal";
import { getBrandsList } from "../../core/services/BrandService";
import { getCitiesList } from "../../core/services/CityService";
import { getHostessesList } from "../../core/services/HostessService";
import { getLocationsList } from "../../core/services/LocationService";
import {
  createOrder,
  getOrderDetails,
  updateOrder
} from "../../core/services/OrderService";
import { getPersonsList } from "../../core/services/PersonService";
import { getPrincipalsList } from "../../core/services/PrincipalService";
import { getPromotionTypesList } from "../../core/services/PromotionTypeService";
import { getRegionsList } from "../../core/services/RegionService";
import { getRepresentativesList } from "../../core/services/RepresentativeService";
import { getTravelCostsList } from "../../core/services/TravelCostService";
import { useAuthContext } from "../../helpers/AuthContext";
import {
  filterSelectOption,
  formGenericRules,
  formItemCrudDrawer
} from "../../helpers/FormHelper";
import { convertDateTimeToUtc } from "../../helpers/TimeHelper";
import "./styles.css";

interface Props {
  drawerState: DrawerState;
  selectedEntityId?: number;
  wrappedComponentRef?: React.RefObject<any>;
  onClose: (
    drawerState: DrawerState,
    shouldUpdate: boolean,
    id?: number
  ) => void;
}

const OrderForm: React.FC<Props> = (props: Props) => {
  const authContext = useAuthContext();
  const { drawerState, selectedEntityId, wrappedComponentRef, onClose } = props;
  const { language, setLoading } = useAuthContext();
  const [form] = Form.useForm();
  const [selectedEntity, setSelectedEntity] = useState<
    OrderDetailVM | undefined
  >();
  const [representatives, setRepresentatives] = useState<DefaultOptionType[]>(
    []
  );
  const [regions, setRegions] = useState<DefaultOptionType[]>([]);
  const [travelCosts, setTravelCosts] = useState<DefaultOptionType[]>([]);
  const [cities, setCities] = useState<CityVM[]>([]);
  const [filteredCities, setFilteredCities] = useState<DefaultOptionType[]>([]);
  const [locations, setLocations] = useState<LocationVM[]>([]);
  const [filteredLocations, setFilteredLocations] = useState<
    DefaultOptionType[]
  >([]);
  const [brands, setBrands] = useState<DefaultOptionType[]>([]);
  const [allPrincipals, setAllPrincipals] = useState<DefaultOptionType[]>([]);
  const [unmappedAllPrincipals, setUnmappedAllPrincipals] = useState<
    PrincipalVM[]
  >([]);
  const [principals, setPrincipals] = useState<DefaultOptionType[]>([]);
  const [bartenders, setBartenders] = useState<DefaultOptionType[]>([]);
  const [technicians, setTechnicians] = useState<DefaultOptionType[]>([]);
  const [promotionTypes, setPromotionTypes] = useState<DefaultOptionType[]>([]);
  const [promoters, setPromoters] = useState<DefaultOptionType[]>([]);
  const [hostesses, setHostesses] = useState<DefaultOptionType[]>([]);
  const [selectedPromoterId, setSelectedPromoterId] = useState<
    number | undefined
  >();
  const [selectedPromoterFee, setSelectedPromoterFee] = useState<
    number | undefined | null
  >();
  const [selectedPromoterTravelCost, setSelectedPromoterTravelCost] = useState<
    number | undefined
  >();

  useEffect(() => {
    getTravelCosts();
    getCities();
    getRepresentatives();
    getRegions();
    getLocations();
    getBrands();
    getPromotionTypes();
    getPromoters();
    getBartenders();
    getTechnicians();
    getHostesses();
    getPrincipals();
  }, []);

  useEffect(() => {
    if (
      cities.length > 0 &&
      representatives.length > 0 &&
      regions.length > 0 &&
      locations.length > 0 &&
      brands.length > 0 &&
      bartenders.length > 0 &&
      technicians.length > 0 &&
      promotionTypes.length > 0 &&
      promoters.length > 0 &&
      hostesses.length > 0 &&
      allPrincipals.length > 0 &&
      travelCosts.length > 0
    ) {
      getFormData();
    }
  }, [
    cities,
    representatives,
    regions,
    locations,
    brands,
    bartenders,
    technicians,
    promotionTypes,
    promoters,
    hostesses,
    allPrincipals,
  ]);

  const getCities = async () => {
    const data = await getCitiesList(authContext);

    if (data.length > 0) {
      setCities(data);
      setFilteredCities(
        data.map(
          (item: BaseViewModel): DefaultOptionType => ({
            value: item.id,
            label: item.name,
          })
        )
      );
    }
  };

  const filterCityOptions = (id: number): void => {
    const filteredValues = cities.filter(
      (city: CityVM): boolean => city.region.id === id
    );

    setFilteredCities(
      filteredValues.map(
        (item: BaseViewModel): DefaultOptionType => ({
          value: item.id,
          label: item.name,
        })
      )
    );
  };

  const getRepresentatives = async () => {
    const data = await getRepresentativesList(authContext);

    if (data.length > 0) {
      setRepresentatives(
        data.map(
          (item: BaseViewModel): DefaultOptionType => ({
            value: item.id,
            label: item.name,
          })
        )
      );
    }
  };

  const getTravelCosts = async () => {
    const data = await getTravelCostsList(authContext);

    if (data.length > 0) {
      setTravelCosts(
        data.map(
          (item: ConfigVM): DefaultOptionType => ({
            value: item.value,
            label: item.value,
          })
        )
      );
    }
  };

  const getPrincipals = async () => {
    const data = await getPrincipalsList(authContext);

    if (data.length > 0) {
      const mappedData = data.map(
        (item: BaseViewModel): DefaultOptionType => ({
          value: item.id,
          label: item.name,
        })
      );
      setAllPrincipals(mappedData);
      setPrincipals(mappedData);
      setUnmappedAllPrincipals(data);
    }
  };

  const getRegions = async () => {
    const data = await getRegionsList(authContext);

    if (data.length > 0) {
      setRegions(
        data.map(
          (item: BaseViewModel): DefaultOptionType => ({
            value: item.id,
            label: item.name,
          })
        )
      );
    }
  };

  const getPromotionTypes = async () => {
    const data = await getPromotionTypesList(authContext);
    if (data.length > 0) {
      setPromotionTypes(
        data.map(
          (item: BaseViewModel): DefaultOptionType => ({
            value: item.id,
            label: item.name,
          })
        )
      );
    }
  };

  const getLocations = async () => {
    const data = await getLocationsList(authContext);

    if (data.length > 0) {
      setLocations(data);
      setFilteredLocations(
        data.map(
          (item: BaseViewModel): DefaultOptionType => ({
            value: item.id,
            label: item.name,
          })
        )
      );
    }
  };

  const filterLocationOptions = (id: number): void => {
    const filteredValues = locations.filter(
      (location: LocationVM): boolean => location.city.id === id
    );

    setFilteredLocations(
      filteredValues.map(
        (item: BaseViewModel): DefaultOptionType => ({
          value: item.id,
          label: item.name,
        })
      )
    );
  };

  const getBrands = async () => {
    const data = await getBrandsList(authContext);
    if (data.length > 0) {
      setBrands(
        data.map(
          (item: BaseViewModel): DefaultOptionType => ({
            value: item.id,
            label: item.name,
          })
        )
      );
    }
  };

  const getBartenders = async () => {
    const data = await getPersonsList(authContext, PersonType.Bartender);
    if (data.length > 0) {
      setBartenders(
        data.map(
          (item: PersonVM): DefaultOptionType => ({
            value: item.id,
            label: `${item.firstName} ${item.lastName}`,
          })
        )
      );
    }
  };

  const getTechnicians = async () => {
    const data = await getPersonsList(authContext, PersonType.Technician);
    if (data.length > 0) {
      setTechnicians(
        data.map(
          (item: PersonVM): DefaultOptionType => ({
            value: item.id,
            label: `${item.firstName} ${item.lastName}`,
          })
        )
      );
    }
  };

  const getPromoters = async () => {
    const data = await getPersonsList(authContext, PersonType.Promoter);
    if (data.length > 0) {
      setPromoters(
        data.map(
          (item: PersonVM): DefaultOptionType => ({
            value: item.id,
            label: `${item.firstName} ${item.lastName}`,
          })
        )
      );
    }
  };

  const getHostesses = async () => {
    const data = await getHostessesList(authContext, PersonType.Hostess);
    if (data.length > 0) {
      setHostesses(
        data.map(
          (item: PersonVM): DefaultOptionType => ({
            value: item.id,
            label: `${item.firstName} ${item.lastName}`,
          })
        )
      );
    }
  };

  const getFormData = async () => {
    if (!selectedEntityId) return;

    setLoading(true);

    const result = await getOrderDetails(authContext, selectedEntityId);

    if (result) {
      setSelectedEntity(result);
      setSelectedPromoterId(result.promoter?.person.id);
      setSelectedPromoterFee(result.promoter?.fee);
    }

    setLoading(false);
  };

  const filterPrincipals = (representativeId: number): void => {
    const filteredValues = unmappedAllPrincipals.filter(
      (principal: PrincipalVM): boolean =>
        principal.representative.id === representativeId
    );
    const mappedData = filteredValues.map(
      (item: BaseViewModel): DefaultOptionType => ({
        value: item.id,
        label: item.name,
      })
    );
    form.setFieldValue("principal", undefined);
    setPrincipals(mappedData);
  };

  const handleSave = async (formValues: OrderFormModel) => {
    setLoading(true, translations[language].saving);

    const formattedDate = formValues.timeOfPromotion;

    const result =
      drawerState === DrawerState.Edit && selectedEntityId
        ? await updateOrder(authContext, {
            id: selectedEntityId,
            representative: formValues.representative,
            principalId: formValues.principal,
            timeOfPromotion: formattedDate,
            region: formValues.region,
            city: formValues.city,
            location: formValues.location,
            brand: formValues.brand,
            promotionType: formValues.promotionType,
            promoter:
              formValues.promoterId &&
              formValues.promoterFee !== undefined &&
              formValues.promoterTravelCost !== undefined
                ? {
                    participantId: selectedEntity?.promoter?.participantId,
                    personId: formValues.promoterId,
                    pricePerKm: formValues.promoterTravelCost ?? costPerKm,
                    fee: formValues.promoterFee || 0,
                  }
                : undefined,
            hostesses: formValues.hostessesFee || [],
            bartenders: formValues.bartendersFee || [],
            technicians: formValues.techniciansFee || [],
            note: formValues.note,
          })
        : await createOrder(authContext, {
            representative: formValues.representative,
            principalId: formValues.principal,
            timeOfPromotion: formattedDate,
            region: formValues.region,
            city: formValues.city,
            location: formValues.location,
            brand: formValues.brand,
            promotionType: formValues.promotionType,
            promoter:
              formValues.promoterId &&
              formValues.promoterFee !== undefined &&
              formValues.promoterTravelCost !== undefined
                ? {
                    participantId: selectedEntity?.promoter?.participantId,
                    personId: formValues.promoterId,
                    pricePerKm: formValues.promoterTravelCost ?? costPerKm,
                    fee: formValues.promoterFee || 0,
                  }
                : undefined,
            hostesses: formValues.hostessesFee || [],
            bartenders: formValues.bartendersFee || [],
            technicians: formValues.techniciansFee || [],
            note: formValues.note,
          });

    if (result) {
      onClose(DrawerState.Closed, true, undefined);
    }

    setLoading(false);
  };

  return (
    <>
      {(drawerState === DrawerState.Create ||
        (drawerState === DrawerState.Edit && selectedEntity)) && (
        <Form
          onFinish={handleSave}
          className="form order-form"
          {...formItemCrudDrawer}
          ref={wrappedComponentRef}
          form={form}
        >
          <Form.Item
            {...formGenericRules(language, true)}
            name="representative"
            label={translations[language].representative}
            initialValue={selectedEntity?.representative.id}
          >
            <Select
              showSearch
              placeholder={translations[language].representativeChoose}
              options={representatives}
              onSelect={(value: number): void => filterPrincipals(value)}
              filterOption={(input, option) =>
                filterSelectOption(input, option)
              }
            />
          </Form.Item>
          <Form.Item
            name="principal"
            label={translations[language].principal}
            initialValue={selectedEntity?.principal?.id}
          >
            <Select
              showSearch
              placeholder={translations[language].principalChoose}
              options={principals}
              filterOption={(input, option) =>
                filterSelectOption(input, option)
              }
            />
          </Form.Item>
          <Form.Item
            {...formGenericRules(language, true)}
            name="region"
            label={translations[language].region}
            initialValue={selectedEntity?.region.id}
          >
            <Select
              showSearch
              placeholder={translations[language].regionChoose}
              options={regions}
              onChange={(value: number | null): void => {
                form.setFieldValue("region", value);
                form.setFieldValue("city", undefined);
                form.setFieldValue("location", undefined);
                if (value) {
                  filterCityOptions(value);
                }
              }}
              filterOption={(input, option) =>
                filterSelectOption(input, option)
              }
            />
          </Form.Item>
          <Form.Item
            {...formGenericRules(language, true)}
            name="city"
            label={translations[language].city}
            initialValue={selectedEntity?.city.id}
          >
            <Select
              showSearch
              placeholder={translations[language].cityChoose}
              options={filteredCities}
              onChange={(value: number | null): void => {
                form.setFieldValue("city", value);
                form.setFieldValue("location", undefined);
                if (value) {
                  filterLocationOptions(value);
                }
              }}
              filterOption={(input, option) =>
                filterSelectOption(input, option)
              }
            />
          </Form.Item>
          <Form.Item
            {...formGenericRules(language, true)}
            name="location"
            label={translations[language].location}
            initialValue={selectedEntity?.location.id}
          >
            <Select
              showSearch
              placeholder={translations[language].locationChoose}
              options={filteredLocations}
              filterOption={(input, option) =>
                filterSelectOption(input, option)
              }
            />
          </Form.Item>
          <Form.Item
            {...formGenericRules(language, true)}
            name="timeOfPromotion"
            label={translations[language].promotionDateTime}
            initialValue={convertDateTimeToUtc(selectedEntity?.timeOfPromotion)}
          >
            <DatePicker
              placeholder={translations[language].promotionDateTimeChoose}
              showTime
              showHour
              showMinute
              minuteStep={5}
              format={defaultFormatDateTimeWithoutSeconds}
            />
          </Form.Item>
          <Form.Item
            {...formGenericRules(language, true)}
            name="brand"
            label={translations[language].brand}
            initialValue={selectedEntity?.brand.id}
          >
            <Select
              showSearch
              placeholder={translations[language].brandChoose}
              options={brands}
              filterOption={(input, option) =>
                filterSelectOption(input, option)
              }
            />
          </Form.Item>
          <Form.Item
            {...formGenericRules(language, true)}
            name="promotionType"
            label={translations[language].promotionType}
            initialValue={selectedEntity?.promotionType.id}
          >
            <Select
              showSearch
              placeholder={translations[language].promotionTypeChoose}
              options={promotionTypes}
              filterOption={(input, option) =>
                filterSelectOption(input, option)
              }
            />
          </Form.Item>
          <Form.Item
            name="note"
            label={translations[language].note}
            initialValue={selectedEntity?.note}
          >
            <Input placeholder={translations[language].enterNote} />
          </Form.Item>
          <Divider plain>{translations[language].promoter}</Divider>
          <Form.Item
            {...formGenericRules(
              language,
              (selectedPromoterTravelCost === null ||
                selectedPromoterTravelCost === undefined) &&
                (selectedPromoterFee === null ||
                  selectedPromoterFee === undefined)
                ? false
                : true
            )}
            name="promoterId"
            label={translations[language].promoter}
            initialValue={selectedEntity?.promoter?.person.id}
          >
            <Select
              showSearch
              placeholder={translations[language].promoterChoose}
              options={promoters}
              filterOption={(input, option) =>
                filterSelectOption(input, option)
              }
              allowClear
              onChange={(value?: number): void => setSelectedPromoterId(value)}
            />
          </Form.Item>
          <Form.Item
            {...formGenericRules(
              language,
              (selectedPromoterId === null ||
                selectedPromoterId === undefined) &&
                (selectedPromoterTravelCost === null ||
                  selectedPromoterTravelCost === undefined)
                ? false
                : true
            )}
            name="promoterFee"
            label={translations[language].fee}
            initialValue={selectedEntity?.promoter?.fee}
          >
            <InputNumber
              min={0}
              placeholder={translations[language].feeEnter}
              onChange={(value: number | null | undefined): void => {
                form.setFieldValue("promoterFee", value);
                setSelectedPromoterFee(value);
              }}
              addonAfter={<span>€</span>}
            />
          </Form.Item>
          <Form.Item
            {...formGenericRules(
              language,
              (selectedPromoterId === null ||
                selectedPromoterId === undefined) &&
                (selectedPromoterFee === null ||
                  selectedPromoterFee === undefined)
                ? false
                : true
            )}
            name="promoterTravelCost"
            label={translations[language].travelCost}
            initialValue={selectedEntity?.promoter?.cost?.pricePerKm}
          >
            <Select
              placeholder={translations[language].travelCostChoose}
              options={travelCosts}
              allowClear
              onChange={(value?: number): void =>
                setSelectedPromoterTravelCost(value)
              }
            />
          </Form.Item>
          <Divider plain>{translations[language].hostesses}</Divider>
          <Form.List
            name="hostessesFee"
            key="hostesses-fee-list"
            initialValue={
              selectedEntity?.hostesses && selectedEntity.hostesses.length > 0
                ? selectedEntity?.hostesses.map((s: PersonFeeVM) => ({
                    participantId: s.participantId,
                    personId: s.person.id,
                    fee: s.fee,
                  }))
                : []
            }
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <>
                    <Form.Item
                      {...restField}
                      {...formGenericRules(language, true)}
                      name={[name, "personId"]}
                      label={translations[language].hostess}
                    >
                      <Select
                        showSearch
                        placeholder={translations[language].hostessChoose}
                        options={hostesses}
                        filterOption={(input, option) =>
                          filterSelectOption(input, option)
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      {...formGenericRules(language, true)}
                      label={translations[language].fee}
                      name={[name, "fee"]}
                    >
                      <InputNumber
                        min={0}
                        placeholder={translations[language].feeEnter}
                        addonAfter={<span>€</span>}
                      />
                    </Form.Item>
                    <Form.Item className="dummy-label" label=" ">
                      <Button
                        type="primary"
                        danger
                        onClick={() => remove(name)}
                      >
                        {translations[language].removeHostess}
                      </Button>
                    </Form.Item>
                  </>
                ))}
                <Form.Item className="dummy-label" label=" ">
                  <Button type="primary" onClick={() => add()}>
                    {translations[language].addHostess}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Divider plain>{translations[language].bartenders}</Divider>
          <Form.List
            name="bartendersFee"
            key="bartender-fee-list"
            initialValue={
              selectedEntity?.bartenders && selectedEntity.bartenders.length > 0
                ? selectedEntity?.bartenders.map((s: PersonFeeVM) => ({
                    participantId: s.participantId,
                    personId: s.person.id,
                    fee: s.fee,
                    pricePerKm: s.cost?.pricePerKm,
                  }))
                : []
            }
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <>
                    <Form.Item
                      {...restField}
                      {...formGenericRules(language, true)}
                      name={[name, "personId"]}
                      label={translations[language].bartender}
                    >
                      <Select
                        showSearch
                        placeholder={translations[language].bartenderChoose}
                        options={bartenders}
                        filterOption={(input, option) =>
                          filterSelectOption(input, option)
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      {...formGenericRules(language, true)}
                      label={translations[language].fee}
                      name={[name, "fee"]}
                    >
                      <InputNumber
                        min={0}
                        placeholder={translations[language].feeEnter}
                        addonAfter={<span>€</span>}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      {...formGenericRules(language, true)}
                      name={[name, "pricePerKm"]}
                      label={translations[language].travelCost}
                    >
                      <Select
                        placeholder={translations[language].travelCostChoose}
                        options={travelCosts}
                      />
                    </Form.Item>
                    <Form.Item className="dummy-label" label=" ">
                      <Button
                        type="primary"
                        danger
                        onClick={() => remove(name)}
                      >
                        {translations[language].removeBartender}
                      </Button>
                    </Form.Item>
                  </>
                ))}
                <Form.Item className="dummy-label" label=" ">
                  <Button type="primary" onClick={() => add()}>
                    {translations[language].addBartender}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Divider plain>{translations[language].technicians}</Divider>
          <Form.List
            name="techniciansFee"
            key="technician-fee-list"
            initialValue={
              selectedEntity?.technicians &&
              selectedEntity.technicians.length > 0
                ? selectedEntity?.technicians.map((s: PersonFeeVM) => ({
                    participantId: s.participantId,
                    personId: s.person.id,
                    fee: s.fee,
                    pricePerKm: s.cost?.pricePerKm,
                  }))
                : []
            }
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <>
                    <Form.Item
                      {...restField}
                      {...formGenericRules(language, true)}
                      name={[name, "personId"]}
                      label={translations[language].technician}
                    >
                      <Select
                        showSearch
                        placeholder={translations[language].technicianChoose}
                        options={technicians}
                        filterOption={(input, option) =>
                          filterSelectOption(input, option)
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      {...formGenericRules(language, true)}
                      label={translations[language].fee}
                      name={[name, "fee"]}
                    >
                      <InputNumber
                        min={0}
                        placeholder={translations[language].feeEnter}
                        addonAfter={<span>€</span>}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      {...formGenericRules(language, true)}
                      name={[name, "pricePerKm"]}
                      label={translations[language].travelCost}
                    >
                      <Select
                        placeholder={translations[language].travelCostChoose}
                        options={travelCosts}
                      />
                    </Form.Item>
                    <Form.Item className="dummy-label" label=" ">
                      <Button
                        type="primary"
                        danger
                        onClick={() => remove(name)}
                      >
                        {translations[language].removeTechnician}
                      </Button>
                    </Form.Item>
                  </>
                ))}
                <Form.Item className="dummy-label" label=" ">
                  <Button type="primary" onClick={() => add()}>
                    {translations[language].addTechnician}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      )}
    </>
  );
};

export default OrderForm;
