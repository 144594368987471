import { IUseAuthValues } from "../../helpers/AuthContext";
import { BaseCreateRequestModel, BaseUpdateRequestModel, BaseViewModel } from "../models/Base";

export const getRegionsList = async (
  context: IUseAuthValues
): Promise<BaseViewModel[]> => {
  const { get } = context;

  const entities = await get<BaseViewModel[] | undefined>(`/Region`);

  return entities || [];
};

export const getRegionDetails = async (
  context: IUseAuthValues,
  id: number
): Promise<BaseViewModel | undefined> => {
  const { get } = context;

  const entity = await get<BaseViewModel | undefined>(`/Region/${id}`);

  return entity;
};

export const createRegion = async (
  context: IUseAuthValues,
  newEntity: BaseCreateRequestModel
): Promise<number | undefined> => {
  const { post } = context;

  const entity = await post<number | undefined>(`/Region`, newEntity);

  return entity;
};

export const updateRegion = async (
  context: IUseAuthValues,
  updateEntity: BaseUpdateRequestModel
): Promise<number | undefined> => {
  const { put } = context;

  const entity = await put<number | undefined>(`/Region/${updateEntity.id}`, updateEntity);

  return entity;
};

export const deleteRegion = async (context: IUseAuthValues, id: number): Promise<number | undefined> => {
  const { remove } = context;

  const entity = await remove<number | undefined>(`/Region/${id}`);

  return entity;
};
