import { Rule, RuleObject } from "antd/lib/form";
import { DefaultOptionType } from "antd/lib/select";
import { Moment } from "moment";
import { translations } from "../config/translations";
import { DropdownOption } from "../core/models/CommonModels";
import { DrawerState, Language } from "../core/models/Enum";

export const customArrayValidationHelper = (
  callback: any,
  message: string,
  entityToValidate?: DropdownOption[],
  required?: boolean
): void => {
  if (
    (required === undefined || required === true) &&
    (!entityToValidate || entityToValidate.length === 0)
  ) {
    callback(message);
  } else {
    callback();
  }
};

export const customStringValidationHelper = (
  _rule: any,
  callback: any,
  message: string,
  entityToValidate?: string
): void => {
  if (!entityToValidate || entityToValidate.length === 0) {
    callback(message);
  } else {
    callback();
  }
};

export const customDateValidationHelper = (
  _rule: any,
  callback: any,
  message: string,
  entityToValidate?: Date | Moment
): void => {
  if (!entityToValidate) {
    callback(message);
  } else {
    callback();
  }
};

export const customNumberValidationHelper = (
  _rule: any,
  callback: any,
  message: string,
  entityToValidate?: number
): void => {
  if (!entityToValidate) {
    callback(message);
  } else {
    callback();
  }
};

export const isFormVisible = (
  drawerState: DrawerState,
  entityExists: boolean
): boolean => {
  if (drawerState === DrawerState.Create) {
    return true;
  } else if (drawerState === DrawerState.Edit && entityExists) {
    return true;
  }

  return false;
};

export const checkDragAndDropRequired = (routeStations: DropdownOption[]) => {
  const routeStationsWithoutLast = routeStations.slice(0, -1);

  let result = true;

  routeStationsWithoutLast.forEach((x) => {
    if (x.descriptionalMessage === undefined) {
      result = false;
    }
  });

  return result;
};

export const formItemLayoutDrawer = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

export const formItemModal = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
};

export const formItemCrudDrawer = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

export const formItemLayoutDrawer10_14 = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

export const formItemLayoutFullWidthDrawer = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

export const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 20,
      offset: 4,
    },
  },
};

export const headerFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 20,
      offset: 4,
    },
    sm: {
      span: 20,
      offset: 4,
    },
  },
};

export const formItemModalWithoutLabel = {
  labelCol: {
    xs: { span: 0 },
    sm: { span: 0 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

export const formGenericRules = (language: Language, isRequired?: boolean) => ({
  rules: [
    {
      required: isRequired !== undefined ? isRequired : true,
      message: translations[language].requiredField,
    },
  ],
});

export const formInputNumberRules = (
  language: Language
): { rules: Rule[] } => ({
  rules: [
    { type: "number" },
    {
      validator: (_: RuleObject, value: number) =>
        value !== undefined && value !== null
          ? Promise.resolve()
          : Promise.reject(translations[language].requiredField),
    },
  ],
});

export const formEmailRules = (
  language: Language,
  isRequired?: boolean
): { rules: Rule[] } => ({
  rules: [
    { type: "email" },
    {
      required: isRequired !== undefined ? isRequired : true,
      message: translations[language].requiredField,
    },
  ],
});

export const submitForm = (ref: React.RefObject<any>): void => {
  ref.current?.submit();
};

export const filterSelectOption = (
  input: string,
  option?: DefaultOptionType
): boolean =>
  (option?.label as String)?.toLowerCase().includes(input?.toLowerCase());

export const inputNumberGenericFormatter = () => ({
  formatter: (value?: number) =>
    value !== undefined && value !== null ? `${Number(value).toFixed(2)}` : "",
});
