import { DefaultOptionType } from "antd/lib/select";
import { translations } from "../../config/translations";
import { Language } from "../models/Enum";

export const getMonthsDropdownList = (
    language: Language,
): DefaultOptionType[] => {
    const results: DefaultOptionType[] = [
        {
            value: null,
            label: translations[language].all,
        },
        {
            value: 1,
            label: translations[language].january,
        },
        {
            value: 2,
            label: translations[language].february,
        },
        {
            value: 3,
            label: translations[language].march,
        },
        {
            value: 4,
            label: translations[language].april,
        },
        {
            value: 5,
            label: translations[language].may,
        },
        {
            value: 6,
            label: translations[language].june,
        },
        {
            value: 7,
            label: translations[language].july,
        },
        {
            value: 8,
            label: translations[language].august,
        },
        {
            value: 9,
            label: translations[language].september,
        },
        {
            value: 10,
            label: translations[language].october,
        },
        {
            value: 11,
            label: translations[language].november,
        },
        {
            value: 12,
            label: translations[language].december,
        },
    ];

    return results;
};