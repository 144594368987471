import { PayOffBaseVM } from "../core/models/Payoff";

export const sortByTextValue = (
  first: PayOffBaseVM,
  second: PayOffBaseVM
): number => {
  if (!(first.name && second.name)) {
    return 0;
  }
  if (!first.name) {
    return -1;
  }
  if (!second.name) {
    return 1;
  }
  if (first.name < second.name) {
    return -1;
  }
  if (first.name > second.name) {
    return 1;
  }
  return 0;
};
