import { IUseAuthValues } from "../../helpers/AuthContext";
import {
  RepresentativeCreateRequestModel,
  RepresentativeDetailVM,
  RepresentativeUpdateRequestModel,
  RepresentativeVM
} from "../models/Representative";

export const getRepresentativesList = async (
  context: IUseAuthValues
): Promise<RepresentativeVM[]> => {
  const { get } = context;

  const entities = await get<RepresentativeVM[] | undefined>(`/Representative`);

  return entities || [];
};

export const getRepresentativeDetails = async (
  context: IUseAuthValues,
  id: number
): Promise<RepresentativeDetailVM | undefined> => {
  const { get } = context;

  const entity = await get<RepresentativeDetailVM | undefined>(`/Representative/${id}`);

  return entity;
};

export const createRepresentative = async (
  context: IUseAuthValues,
  newEntity: RepresentativeCreateRequestModel
): Promise<number | undefined> => {
  const { post } = context;

  const entity = await post<number | undefined>(`/Representative`, newEntity);

  return entity;
};

export const updateRepresentative = async (
  context: IUseAuthValues,
  updateEntity: RepresentativeUpdateRequestModel
): Promise<number | undefined> => {
  const { put } = context;

  const entity = await put<number | undefined>(`/Representative/${updateEntity.id}`, updateEntity);

  return entity;
};

export const deleteRepresentative = async (context: IUseAuthValues, id: number): Promise<number | undefined> => {
  const { remove } = context;

  const entity = await remove<number | undefined>(`/Representative/${id}`);

  return entity;
};
