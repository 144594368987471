import { IUseAuthValues } from "../../helpers/AuthContext";
import {
    ConfigFetchVM,
    ConfigRequest,
    ConfigUpdateRequestModel,
    ConfigVM
} from "../models/Config";
import { ConfigKey } from "../models/Enum";

export const getHourlyRatesList = async (
    context: IUseAuthValues,
): Promise<ConfigVM[]> => {
    const { post } = context;

    const entities = await post<ConfigFetchVM | undefined>(
        `/Config/keys/fetch-multiple`, { keys: [ConfigKey.HourlyRateHostess, ConfigKey.HourlyRatePromoter, ConfigKey.HourlyRateBartender, ConfigKey.HourlyRateTechnician] }, true
    );

    return entities?.configValues || [];
};

export const getHourlyRateDetails = async (
    context: IUseAuthValues,
    key: ConfigKey,
): Promise<ConfigVM | undefined> => {
    const { get } = context;

    const entity = await get<ConfigVM | undefined>(
        `/Config/key/${key}`
    );

    return entity;
};

export const updateHourlyRates = async (
    context: IUseAuthValues,
    updateEntities: ConfigRequest
): Promise<number | undefined> => {
    const { put } = context;

    const entity = await put<number | undefined>(
        `/Config/keys/update-multiple`,
        updateEntities
    );

    return entity;
};

export const updateHourlyRate = async (
    context: IUseAuthValues,
    updateEntity: ConfigUpdateRequestModel
): Promise<number | undefined> => {
    const { put } = context;

    const entity = await put<number | undefined>(
        `/Config/${updateEntity.id}`,
        updateEntity
    );

    return entity;
};
