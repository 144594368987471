import { IUseAuthValues } from "../../helpers/AuthContext";
import {
  OrderCreateRequestModel,
  OrderDetailVM,
  OrderUpdateRequestModel,
  OrderVM
} from "../models/Order";

export const getOrdersList = async (
  context: IUseAuthValues,
  year: number,
): Promise<OrderVM[]> => {
  const { get } = context;

  const entities = await get<OrderVM[] | undefined>(`/Order?year=${year}`);

  return entities || [];
};

export const getOrderDetails = async (
  context: IUseAuthValues,
  id: number
): Promise<OrderDetailVM | undefined> => {
  const { get } = context;

  const entity = await get<OrderDetailVM | undefined>(`/Order/${id}`);

  return entity;
};

export const createOrder = async (
  context: IUseAuthValues,
  newEntity: OrderCreateRequestModel
): Promise<number | undefined> => {
  const { post } = context;

  const entity = await post<number | undefined>(`/Order`, newEntity);

  return entity;
};

export const updateOrder = async (
  context: IUseAuthValues,
  updateEntity: OrderUpdateRequestModel
): Promise<number | undefined> => {
  const { put } = context;

  const entity = await put<number | undefined>(`/Order/${updateEntity.id}`, updateEntity);

  return entity;
};

export const deleteOrder = async (context: IUseAuthValues, id: number): Promise<number | undefined> => {
  const { remove } = context;

  const entity = await remove<number | undefined>(`/Order/${id}`);

  return entity;
};
