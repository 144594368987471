/* eslint-disable react-hooks/exhaustive-deps */
import Table, { ColumnsType } from "antd/lib/table";
import React, { ReactElement, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { defaultFormatDate } from "../../config/constants";
import { translations } from "../../config/translations";
import { BaseViewModel, IdViewModel } from "../../core/models/Base";
import { DrawerState } from "../../core/models/Enum";
import { OrderVM, PersonFeeVM } from "../../core/models/Order";
import { TableAction } from "../../core/models/TableAction";
import { getOrdersList } from "../../core/services/OrderService";
import { useAuthContext } from "../../helpers/AuthContext";
import {
  checkStringOnFilter,
  convertUTCToLocalFormattedTime,
  genericTableProps,
  getActionsColumn,
  getColumnSearchProps,
  getDateRangeSearchProps,
  onRowClick
} from "../../helpers/TableHelper";
import {
  convertUTCToLocalWithoutDefaultValue,
  getShortDisplayValueForDayOfTheWeek
} from "../../helpers/TimeHelper";

interface Props {
  year: number;
  shouldUpdate: boolean;
  setShouldUpdateState: (newState: boolean) => void;
  handleDeleteModalChange: (
    newModalVisibility: boolean,
    newShouldUpdate: boolean,
    id?: number
  ) => void;
  handleDrawerChange: (
    newDrawerState: DrawerState,
    newShouldUpdate: boolean,
    id?: number
  ) => void;
}

const OrdersTable: React.FC<Props> = (props: Props) => {
  const {
    year,
    shouldUpdate,
    setShouldUpdateState,
    handleDeleteModalChange,
    handleDrawerChange,
  } = props;
  const { language, setLoading } = useAuthContext();
  const authContext = useAuthContext();
  const [tableData, setTableData] = useState<OrderVM[]>([]);

  useEffect(() => {
    getTableData();
  }, [year]);

  useEffect(() => {
    if (shouldUpdate) {
      setShouldUpdateState(false);
      getTableData();
    }
  }, [shouldUpdate]);

  const getTableData = async () => {
    setLoading(true);

    const data = await getOrdersList(authContext, year);

    if (data) {
      setTableData(data);
    }

    setLoading(false);
  };

  const actionsContent = (value: IdViewModel): TableAction[] => {
    const returnActions: TableAction[] = [
      {
        id: 1,
        entity: value,
        className: "details-action-button",
        label: translations[language].edit,
        onClick: (): void =>
          handleDrawerChange(DrawerState.Edit, false, value.id),
      },
      {
        id: 2,
        entity: value,
        className: "details-action-button",
        label: translations[language].delete,
        onClick: (): void => handleDeleteModalChange(true, false, value.id),
      },
    ];

    return returnActions;
  };

  const columns: ColumnsType<OrderVM> = isMobile
    ? [
        {
          title: translations[language].promotionDate,
          dataIndex: "timeOfPromotion",
          ...getDateRangeSearchProps("timeOfPromotion"),
          render: (value: Date) => {
            const formatedDate = convertUTCToLocalWithoutDefaultValue(value);
            if (formatedDate) {
              const dayOfWeek = getShortDisplayValueForDayOfTheWeek(
                language,
                formatedDate.weekday()
              );
              return `${convertUTCToLocalFormattedTime(
                value,
                defaultFormatDate
              )} - ${dayOfWeek}`;
            } else {
              return convertUTCToLocalFormattedTime(value, defaultFormatDate);
            }
          },
        },
        {
          title: translations[language].location,
          dataIndex: "location",
          ...getColumnSearchProps(language),
          onFilter: (
            value: string | number | boolean | undefined,
            record: OrderVM
          ): boolean => checkStringOnFilter(value, record.location.name),
          render: (value: BaseViewModel) => value.name,
        },
        {
          title: translations[language].promoter,
          dataIndex: "promoter",
          ...getColumnSearchProps(language),
          onFilter: (
            value: string | number | boolean | undefined,
            record: OrderVM
          ): boolean =>
            checkStringOnFilter(
              value,
              `${record.promoter?.person?.firstName} ${record.promoter?.person?.lastName} - ${record.promoter?.fee}€`
            ),
          render: (value?: PersonFeeVM) =>
            value?.person
              ? `${value?.person?.firstName} ${value?.person?.lastName}`
              : "",
        },
        {
          title: translations[language].actions,
          dataIndex: "",
          key: "",
          width: 55,
          render: (value: OrderVM): ReactElement =>
            getActionsColumn(value, actionsContent),
        },
      ]
    : [
        // {
        //   title: translations[language].orderNumberShort,
        //   dataIndex: "orderNumber",
        //   render: (value: string) => {
        //     const orderNumber = value?.split("-");
        //     return orderNumber?.length > 0 ? orderNumber[0] : value;
        //   },
        //   width: 75,
        // },
        // {
        //   title: translations[language].representative,
        //   dataIndex: "representative",
        //   ...getColumnSearchProps(language),
        //   onFilter: (value: string | number | boolean | undefined, record: OrderVM): boolean =>
        //     checkStringOnFilter(value, record.representative.name),
        //   render: (value: BaseViewModel) => value.name,
        // },
        // {
        //   title: translations[language].principal,
        //   dataIndex: "principal",
        //   ...getColumnSearchProps(language),
        //   onFilter: (value: string | number | boolean | undefined, record: OrderVM): boolean =>
        //     checkStringOnFilter(value, record.principal.name),
        //   render: (value: BaseViewModel) => value.name,
        // },
        // {
        //   title: translations[language].region,
        //   dataIndex: "region",
        //   ...getColumnSearchProps(language),
        //   onFilter: (value: string | number | boolean | undefined, record: OrderVM): boolean =>
        //     checkStringOnFilter(value, record.region.name),
        //   render: (value: BaseViewModel) => value.name,
        // },
        {
          title: translations[language].promotionDate,
          dataIndex: "timeOfPromotion",
          ...getDateRangeSearchProps("timeOfPromotion"),
          render: (value: Date) => {
            const formatedDate = convertUTCToLocalWithoutDefaultValue(value);
            if (formatedDate) {
              const dayOfWeek = getShortDisplayValueForDayOfTheWeek(
                language,
                formatedDate.weekday()
              );
              return `${convertUTCToLocalFormattedTime(
                value,
                defaultFormatDate
              )} - ${dayOfWeek}`;
            } else {
              return convertUTCToLocalFormattedTime(value, defaultFormatDate);
            }
          },
        },
        {
          title: translations[language].city,
          dataIndex: "city",
          ...getColumnSearchProps(language),
          onFilter: (
            value: string | number | boolean | undefined,
            record: OrderVM
          ): boolean => checkStringOnFilter(value, record.city.name),
          render: (value: BaseViewModel) => value.name,
        },
        {
          title: translations[language].location,
          dataIndex: "location",
          ...getColumnSearchProps(language),
          onFilter: (
            value: string | number | boolean | undefined,
            record: OrderVM
          ): boolean => checkStringOnFilter(value, record.location.name),
          render: (value: BaseViewModel) => value.name,
        },
        {
          title: translations[language].brand,
          dataIndex: "brand",
          ...getColumnSearchProps(language),
          onFilter: (
            value: string | number | boolean | undefined,
            record: OrderVM
          ): boolean => checkStringOnFilter(value, record.brand.name),
          render: (value: BaseViewModel) => value.name,
        },
        // {
        //   title: translations[language].promotionType,
        //   dataIndex: "promotionType",
        //   ...getColumnSearchProps(language),
        //   onFilter: (value: string | number | boolean | undefined, record: OrderVM): boolean =>
        //     checkStringOnFilter(value, record.promotionType.name),
        //   render: (value: BaseViewModel) => value.name,
        // },
        {
          title: translations[language].promoter,
          dataIndex: "promoter",
          ...getColumnSearchProps(language),
          onFilter: (
            value: string | number | boolean | undefined,
            record: OrderVM
          ): boolean =>
            checkStringOnFilter(
              value,
              `${record.promoter?.person?.firstName} ${record.promoter?.person?.lastName} - ${record.promoter?.fee}€`
            ),
          render: (value?: PersonFeeVM) =>
            value?.person
              ? `${value?.person?.firstName} ${value?.person?.lastName}`
              : "",
        },
        // {
        //   title: translations[language].hostesses,
        //   dataIndex: "hostesses",
        //   ...getColumnSearchProps(language),
        //   onFilter: (
        //     value: string | number | boolean | undefined,
        //     record: OrderVM
        //   ): boolean => {
        //     const strings: string[] = record.hostesses
        //       ? record.hostesses?.map(
        //           (v: PersonFeeVM): string =>
        //             `${v.person.firstName} ${v.person.lastName} - ${v.fee}€`
        //         )
        //       : [];
        //     return checkStringOnFilter(value, strings.join(", "));
        //   },
        //   render: (value: PersonFeeVM[]) => {
        //     const strings: string[] = value.map(
        //       (v: PersonFeeVM): string =>
        //         `${v.person.firstName} ${v.person.lastName} - ${v.fee}€`
        //     );
        //     return strings.join(", ");
        //   },
        // },
        // {
        //   title: translations[language].bartenders,
        //   dataIndex: "bartenders",
        //   ...getColumnSearchProps(language),
        //   onFilter: (
        //     value: string | number | boolean | undefined,
        //     record: OrderVM
        //   ): boolean => {
        //     const strings: string[] = record.bartenders
        //       ? record.bartenders?.map(
        //           (v: PersonFeeVM): string =>
        //             `${v.person.firstName} ${v.person.lastName} - ${v.fee}€`
        //         )
        //       : [];
        //     return checkStringOnFilter(value, strings.join(", "));
        //   },
        //   render: (value: PersonFeeVM[]) => {
        //     const strings: string[] = value.map(
        //       (v: PersonFeeVM): string =>
        //         `${v.person.firstName} ${v.person.lastName} - ${v.fee}€`
        //     );
        //     return strings.join(", ");
        //   },
        // },
        // {
        //   title: translations[language].technicians,
        //   dataIndex: "technicians",
        //   ...getColumnSearchProps(language),
        //   onFilter: (
        //     value: string | number | boolean | undefined,
        //     record: OrderVM
        //   ): boolean => {
        //     const strings: string[] = record.technicians
        //       ? record.technicians?.map(
        //           (v: PersonFeeVM): string =>
        //             `${v.person.firstName} ${v.person.lastName} - ${v.fee}€`
        //         )
        //       : [];
        //     return checkStringOnFilter(value, strings.join(", "));
        //   },
        //   render: (value: PersonFeeVM[]) => {
        //     const strings: string[] = value.map(
        //       (v: PersonFeeVM): string =>
        //         `${v.person.firstName} ${v.person.lastName} - ${v.fee}€`
        //     );
        //     return strings.join(", ");
        //   },
        // },
        // {
        //   title: translations[language].note,
        //   dataIndex: "note",
        //   ...getColumnSearchProps(language),
        //   onFilter: (value: string | number | boolean | undefined, record: OrderVM): boolean =>
        //     checkStringOnFilter(value, record.note),
        // },
        {
          title: translations[language].actions,
          dataIndex: "",
          key: "",
          width: 55,
          render: (value: OrderVM): ReactElement =>
            getActionsColumn(value, actionsContent),
        },
      ];

  return (
    <Table
      {...genericTableProps(language)}
      columns={columns}
      className="cursorPointer"
      dataSource={tableData}
      size="small"
      rowKey={(item): number => item.id}
      onRow={(item): { onClick: (column: any) => void } => ({
        onClick: (column: { target: { nodeName: string } }): void =>
          onRowClick(column, (): void =>
            handleDrawerChange(DrawerState.Edit, false, item.id)
          ),
      })}
    />
  );
};

export default OrdersTable;
