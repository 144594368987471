import { IUseAuthValues } from "../../helpers/AuthContext";
import { PersonFeeRequestModel } from "../models/Order";
import { BottlesReportRequestModel, BottlesReportVM, ReportDetailVM, ReportFilterModel, ReportRequestAdminModel, ReportRequestPromoterModel, ReportSendMailRequestModel, ReportVM } from "../models/Report";

export const getReportsList = async (
  context: IUseAuthValues,
  filter: ReportFilterModel,
): Promise<ReportVM[]> => {
  const { get } = context;

  const entities = await get<ReportVM[] | undefined>(
    `/Report?year=${filter.year}${filter.personId ? `&person=${filter.personId}` : ""}${filter.month ? `&month=${filter.month}` : ""}${filter.brandId ? `&brand=${filter.brandId}` : ""}`
  );

  return entities || [];
};

export const getReportDetails = async (
  context: IUseAuthValues,
  id: number
): Promise<ReportDetailVM | undefined> => {
  const { get } = context;

  const entity = await get<ReportDetailVM | undefined>(`/Report/${id}`);

  return entity;
};

export const getBottlesReportDetails = async (
  context: IUseAuthValues,
  id: number
): Promise<BottlesReportVM | undefined> => {
  const { get } = context;

  const entity = await get<BottlesReportVM | undefined>(`/Report/bottles/${id}`);

  return entity;
};

export const updateReportAdmin = async (
  context: IUseAuthValues,
  updateEntity: ReportRequestAdminModel
): Promise<number | undefined> => {
  const { put } = context;

  const entity = await put<number | undefined>(
    `/Report/${updateEntity.orderId}`,
    updateEntity
  );

  return entity;
};

export const updateBottlesReport = async (
  context: IUseAuthValues,
  updateEntity: BottlesReportRequestModel
): Promise<number | undefined> => {
  const { put } = context;

  const entity = await put<number | undefined>(
    `/Report/bottles/${updateEntity.orderId}`,
    updateEntity
  );

  return entity;
};


export const updateReportPromoter = async (
  context: IUseAuthValues,
  updateEntity: ReportRequestPromoterModel
): Promise<number | undefined> => {
  const { put } = context;

  const entity = await put<number | undefined>(
    `/Report/Promoter/${updateEntity.orderId}`,
    updateEntity
  );

  return entity;
};

export const updateReportParticipant = async (
  context: IUseAuthValues,
  reportId: number,
  updateEntity: PersonFeeRequestModel
): Promise<number | undefined> => {
  const { put } = context;

  const entity = await put<number | undefined>(
    `/Report/cost/${reportId}`,
    updateEntity
  );

  return entity;
};

export const deleteReport = async (
  context: IUseAuthValues,
  id: number
): Promise<number | undefined> => {
  const { remove } = context;

  const entity = await remove<number | undefined>(`/Report/${id}`);

  return entity;
};

export const sendReportByMail = async (
  context: IUseAuthValues,
  model: ReportSendMailRequestModel
): Promise<number | undefined> => {
  const { post } = context;

  const entity = await post<number | undefined>(
    `/Report/send/${model.reportId}`,
    model
  );

  return entity;
};
