/* eslint-disable react-hooks/exhaustive-deps */
import { SaveOutlined } from "@ant-design/icons";
import { Button, Form, InputNumber, PageHeader } from "antd";
import React, { useEffect, useState } from "react";
import { translations } from "../../config/translations";
import { ConfigHourlyRateFormModel, ConfigVM } from "../../core/models/Config";
import { ConfigKey } from "../../core/models/Enum";
import {
    getHourlyRatesList,
    updateHourlyRates
} from "../../core/services/HourlyRateService";
import { useAuthContext } from "../../helpers/AuthContext";
import { PageHeaderButtonSettings } from "../../helpers/ButtonHelper";
import {
    formGenericRules,
    formItemCrudDrawer,
    submitForm
} from "../../helpers/FormHelper";

const HourlyRate: React.FC = () => {
  const { language, setLoading } = useAuthContext();
  const authContext = useAuthContext();
  const [selectedEntityPromoter, setSelectedEntityPromoter] = useState<
    ConfigVM | undefined
  >();
  const [selectedEntityHostess, setSelectedEntityHostess] = useState<
    ConfigVM | undefined
  >();
  const [selectedEntityBartender, setSelectedEntityBartender] = useState<
    ConfigVM | undefined
  >();
  const [selectedEntityTechnician, setSelectedEntityTechnician] = useState<
    ConfigVM | undefined
  >();
  const ref = React.createRef();

  useEffect(() => {
    getFormData();
  }, []);

  const getFormData = async () => {
    setLoading(true);

    const result = await getHourlyRatesList(authContext);

    if (result) {
      setSelectedEntityPromoter(
        result.find(
          (r: ConfigVM): boolean => r.key === ConfigKey.HourlyRatePromoter
        )
      );

      setSelectedEntityHostess(
        result.find(
          (r: ConfigVM): boolean => r.key === ConfigKey.HourlyRateHostess
        )
      );

      setSelectedEntityBartender(
        result.find(
          (r: ConfigVM): boolean => r.key === ConfigKey.HourlyRateBartender
        )
      );

      setSelectedEntityTechnician(
        result.find(
          (r: ConfigVM): boolean => r.key === ConfigKey.HourlyRateTechnician
        )
      );
    }

    setLoading(false);
  };

  const handleSave = async (formValues: ConfigHourlyRateFormModel) => {
    if (
      !(
        selectedEntityPromoter !== undefined &&
        selectedEntityHostess !== undefined &&
        selectedEntityBartender !== undefined &&
        selectedEntityTechnician !== undefined
      )
    )
      return;
    setLoading(true, translations[language].saving);

    await updateHourlyRates(authContext, {
      configValues: [
        {
          id: selectedEntityPromoter?.id,
          key: ConfigKey.HourlyRatePromoter,
          value: formValues.valuePromoter,
        },
        {
          id: selectedEntityHostess?.id,
          key: ConfigKey.HourlyRateHostess,
          value: formValues.valueHostess,
        },
        {
          id: selectedEntityBartender?.id,
          key: ConfigKey.HourlyRateBartender,
          value: formValues.valueBartender,
        },
        {
          id: selectedEntityTechnician?.id,
          key: ConfigKey.HourlyRateTechnician,
          value: formValues.valueTechnician,
        },
      ],
    });

    setLoading(false);
  };

  return (
    <>
      <PageHeader
        title={translations[language].hourlyRates}
        extra={[
          <Button
            key="hourly-rate-button"
            {...PageHeaderButtonSettings}
            onClick={() => submitForm(ref)}
          >
            <SaveOutlined />
            {translations[language].save}
          </Button>,
        ]}
      />
      {selectedEntityPromoter !== undefined &&
        selectedEntityHostess !== undefined &&
        selectedEntityBartender !== undefined &&
        selectedEntityTechnician !== undefined && (
          <Form
            onFinish={handleSave}
            className="hourly-rate-form"
            {...formItemCrudDrawer}
            ref={ref as any}
          >
            <Form.Item
              {...formGenericRules(language, true)}
              name="valuePromoter"
              label={translations[language].hourlyRatePromoter}
              initialValue={selectedEntityPromoter?.value}
            >
              <InputNumber
                placeholder={translations[language].enterHourlyRatePromoter}
                addonAfter="€/h"
              />
            </Form.Item>
            <Form.Item
              {...formGenericRules(language, true)}
              name="valueHostess"
              label={translations[language].hourlyRateHostess}
              initialValue={selectedEntityHostess?.value}
            >
              <InputNumber
                placeholder={translations[language].enterHourlyRateHostess}
                addonAfter="€/h"
              />
            </Form.Item>
            <Form.Item
              {...formGenericRules(language, true)}
              name="valueBartender"
              label={translations[language].hourlyRateBartender}
              initialValue={selectedEntityBartender?.value}
            >
              <InputNumber
                placeholder={translations[language].enterHourlyRateBartender}
                addonAfter="€/h"
              />
            </Form.Item>
            <Form.Item
              {...formGenericRules(language, true)}
              name="valueTechnician"
              label={translations[language].hourlyRateTechnician}
              initialValue={selectedEntityTechnician?.value}
            >
              <InputNumber
                placeholder={translations[language].enterHourlyRateTechnician}
                addonAfter="€/h"
              />
            </Form.Item>
          </Form>
        )}
    </>
  );
};

export default HourlyRate;
