import { IUseAuthValues } from "../../helpers/AuthContext";
import {
  MailCreateRequestModel,
  MailDetailVM,
  MailUpdateRequestModel,
  MailVM
} from "../models/Mail";

export const getMailsList = async (
  context: IUseAuthValues
): Promise<MailVM[]> => {
  const { get } = context;

  const entities = await get<MailVM[] | undefined>(`/Mail`);

  return entities || [];
};

export const getMailDetails = async (
  context: IUseAuthValues,
  id: number
): Promise<MailDetailVM | undefined> => {
  const { get } = context;

  const entity = await get<MailDetailVM | undefined>(`/Mail/${id}`);

  return entity;
};

export const createMail = async (
  context: IUseAuthValues,
  newEntity: MailCreateRequestModel
): Promise<number | undefined> => {
  const { post } = context;

  const entity = await post<number | undefined>(`/Mail`, newEntity);

  return entity;
};

export const updateMail = async (
  context: IUseAuthValues,
  updateEntity: MailUpdateRequestModel
): Promise<number | undefined> => {
  const { put } = context;

  const entity = await put<number | undefined>(`/Mail/${updateEntity.id}`, updateEntity);

  return entity;
};

export const deleteMail = async (context: IUseAuthValues, id: number): Promise<number | undefined> => {
  const { remove } = context;

  const entity = await remove<number | undefined>(`/Mail/${id}`);

  return entity;
};
