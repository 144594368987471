import { IUseAuthValues } from "../../helpers/AuthContext";
import {
  BaseCreateRequestModel,
  BaseUpdateRequestModel,
  BaseViewModel
} from "../models/Base";

export const getWeatherTypesList = async (
  context: IUseAuthValues
): Promise<BaseViewModel[]> => {
  const { get } = context;

  const entities = await get<BaseViewModel[] | undefined>(`/WeatherType`);

  return entities || [];
};

export const getWeatherTypeDetails = async (
  context: IUseAuthValues,
  id: number
): Promise<BaseViewModel | undefined> => {
  const { get } = context;

  const entity = await get<BaseViewModel | undefined>(`/WeatherType/${id}`);

  return entity;
};

export const createWeatherType = async (
  context: IUseAuthValues,
  newEntity: BaseCreateRequestModel
): Promise<number | undefined> => {
  const { post } = context;

  const entity = await post<number | undefined>(`/WeatherType`, newEntity);

  return entity;
};

export const updateWeatherType = async (
  context: IUseAuthValues,
  updateEntity: BaseUpdateRequestModel
): Promise<number | undefined> => {
  const { put } = context;

  const entity = await put<number | undefined>(
    `/WeatherType/${updateEntity.id}`,
    updateEntity
  );

  return entity;
};

export const deleteWeatherType = async (
  context: IUseAuthValues,
  id: number
): Promise<number | undefined> => {
  const { remove } = context;

  const entity = await remove<number | undefined>(`/WeatherType/${id}`);

  return entity;
};
