/* eslint-disable react-hooks/exhaustive-deps */
import { ConfigProvider, Layout as AntLayout } from "antd";
import React, { ReactElement, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Link, useLocation } from "react-router-dom";
import croatianFlag from "../assets/croatian-flag.png";
import englishFlag from "../assets/english-flag.png";
import routes from "../config/routes";
import { translations } from "../config/translations";
import { Language } from "../core/models/Enum";
import { useAuthContext } from "../helpers/AuthContext";
import {
  checkIfPersonIsValidWithCallbackFunction,
  checkIsAuthorizedRoute
} from "../helpers/PersonHelper";
import { LoginMenu } from "./api-authorization/LoginMenu";
import SideMenu from "./SideMenu";

const { Content, Sider } = AntLayout;

interface Props {
  children: ReactElement | null;
}

export const Layout: React.FC<Props> = (props: Props) => {
  const { children } = props;
  const {
    locale,
    language,
    isAuthenticated,
    userRole,
    userPersonId,
    userBrandId,
    setLanguage,
  } = useAuthContext();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [isPersonValid, setIsPersonValid] = useState<boolean>(false);
  const [isAuthorizationRoutes, setIsAuthorizationRoutes] =
    useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    checkIsAuthorizedRoute(setIsAuthorizedIfDifferent, location.pathname);
  }, [location]);

  useEffect(() => {
    checkIfPersonIsValidWithCallbackFunction(
      setValidationIfDifferent,
      userRole,
      userPersonId,
      userBrandId
    );
  }, [isAuthenticated, userRole, userPersonId, userBrandId]);

  const setValidationIfDifferent = (newValue: boolean) => {
    if (newValue !== isPersonValid) {
      setIsPersonValid(newValue);
    }
  };

  const setIsAuthorizedIfDifferent = (newValue: boolean) => {
    if (newValue !== isAuthorizationRoutes) {
      setIsAuthorizationRoutes(newValue);
    }
  };

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  const collapseSidebar = (): void => {
    setCollapsed(true);
  };

  return (
    <ConfigProvider locale={locale}>
      <AntLayout
        style={{ minHeight: "100vh" }}
        className={isMobile ? "full-screen-sider" : ""}
      >
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={onCollapse}
          className="no-print"
        >
          <div className="logo">
            <Link to={routes.ROUTE_DASHBOARD}>
              <img
                src={
                  collapsed
                    ? "/images/logo-main-collapsed.png"
                    : "/images/logo-main.png"
                }
                className={collapsed ? "logo-collapsed" : "logo-opened"}
                alt="icon-logo"
              />
            </Link>
          </div>
          <div
            className={collapsed ? "language-wrapper-none" : "language-wrapper"}
          >
            <img
              src={croatianFlag}
              alt="croatian-flag"
              onClick={(): void => setLanguage(Language.Croatian)}
            />
            <img
              src={englishFlag}
              alt="english-flag"
              onClick={(): void => setLanguage(Language.English)}
            />
          </div>
          {!collapsed && (
            <LoginMenu
              userTranslation={translations[language].user}
              loginTranslation={translations[language].login}
              logoutTranslation={translations[language].logout}
              registerTranslation={translations[language].register}
            />
          )}
          {isAuthenticated && <SideMenu collapseSidebar={collapseSidebar} />}
        </Sider>
        <AntLayout className={isMobile && !collapsed ? "hide-main-layout" : ""}>
          {(isPersonValid || isAuthorizationRoutes) && (
            <Content>{children}</Content>
          )}
        </AntLayout>
      </AntLayout>
    </ConfigProvider>
  );
};
