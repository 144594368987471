/* eslint-disable react-hooks/exhaustive-deps */
import {
  BuildOutlined,
  CarOutlined,
  CloudOutlined,
  CompressOutlined,
  DingtalkOutlined,
  EuroCircleOutlined,
  EuroOutlined,
  FileOutlined,
  FileTextOutlined,
  HomeOutlined,
  MailOutlined,
  SettingOutlined,
  ShopOutlined,
  SolutionOutlined,
  TagOutlined,
  TagsOutlined,
  UserOutlined
} from "@ant-design/icons";
import { Menu } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { Link, useLocation } from "react-router-dom";
import {
  default as routes,
  default as Routes,
  sidebarKey
} from "../config/routes";
import { translations } from "../config/translations";
import { UserRole } from "../core/models/Enum";
import { useAuthContext } from "../helpers/AuthContext";

interface Props {
  collapseSidebar: () => void;
}

const SideMenu: React.FC<Props> = (props: Props) => {
  const { collapseSidebar } = props;
  const { language, userRole } = useAuthContext();
  const location = useLocation();

  useEffect(() => {
    getInitialSidebarKey();
  }, []);

  const getInitialSidebarKey = (): string[] => {
    const pathname = location.pathname;

    if (pathname.includes(routes.ROUTE_LOCATIONS)) {
      return [sidebarKey.Locations];
    } else if (pathname.includes(routes.ROUTE_BARTENDERS)) {
      return [sidebarKey.Bartenders];
    } else if (pathname.includes(routes.ROUTE_BRAND_MANAGERS)) {
      return [sidebarKey.BrandManagers];
    } else if (pathname.includes(routes.ROUTE_SIGNATURE_DRINKS)) {
      return [sidebarKey.SignatureDrinks];
    } else if (pathname.includes(routes.ROUTE_CITIES)) {
      return [sidebarKey.Cities];
    } else if (pathname.includes(routes.ROUTE_HOSTESSES)) {
      return [sidebarKey.Hostesses];
    } else if (pathname.includes(routes.ROUTE_AREAS)) {
      return [sidebarKey.Areas];
    } else if (pathname.includes(routes.ROUTE_ORDERS)) {
      return [sidebarKey.Orders];
    } else if (pathname.includes(routes.ROUTE_PROMOTERS)) {
      return [sidebarKey.Promoters];
    } else if (pathname.includes(routes.ROUTE_REPORTS)) {
      return [sidebarKey.Reports];
    } else if (pathname.includes(routes.ROUTE_TECHNICIANS)) {
      return [sidebarKey.Technicians];
    } else if (pathname.includes(routes.ROUTE_BRANDS)) {
      return [sidebarKey.Brands];
    } else if (pathname.includes(routes.ROUTE_PAYOFF_BARTENDERS)) {
      return [sidebarKey.PayoffBartenders];
    } else if (pathname.includes(routes.ROUTE_PAYOFF_HOSTESSES)) {
      return [sidebarKey.PayoffHostesses];
    } else if (pathname.includes(routes.ROUTE_PAYOFF_PROMOTERS)) {
      return [sidebarKey.PayoffPromoters];
    } else if (pathname.includes(routes.ROUTE_PAYOFF_TECHNICIANS)) {
      return [sidebarKey.PayoffTechnicians];
    } else if (pathname.includes(routes.ROUTE_REPRESENTATIVES)) {
      return [sidebarKey.Representatives];
    } else if (pathname.includes(routes.ROUTE_MAILS)) {
      return [sidebarKey.Mails];
    } else if (pathname.includes(routes.ROUTE_REGIONS)) {
      return [sidebarKey.Regions];
    } else if (pathname.includes(routes.ROUTE_LOCATION_TYPES)) {
      return [sidebarKey.LocationTypes];
    } else if (pathname.includes(routes.ROUTE_WEATHER_TYPES)) {
      return [sidebarKey.WeatherTypes];
    } else if (pathname.includes(routes.ROUTE_PROMOTION_TYPES)) {
      return [sidebarKey.PromotionTypes];
    } else if (pathname.includes(routes.ROUTE_MUSIC_TYPES)) {
      return [sidebarKey.MusicTypes];
    } else if (pathname.includes(routes.ROUTE_TRAVEL_COST)) {
      return [sidebarKey.TravelCost];
    } else if (pathname.includes(routes.ROUTE_HOURLY_RATE)) {
      return [sidebarKey.HourlyRate];
    } else {
      return [sidebarKey.Dashboard];
    }
  };

  const getSidebarItemsByUserRole = (): ItemType[] => {
    switch (userRole) {
      case UserRole.Admin:
        return [
          {
            key: sidebarKey.Dashboard,
            icon: <HomeOutlined />,
            label: (
              <Link to={Routes.ROUTE_DASHBOARD}>
                {translations[language].dashboard}
              </Link>
            ),
          },
          {
            key: sidebarKey.Orders,
            icon: <FileOutlined />,
            label: (
              <Link to={Routes.ROUTE_ORDERS}>
                {translations[language].orders}
              </Link>
            ),
          },
          {
            key: sidebarKey.Reports,
            icon: <FileTextOutlined />,
            label: (
              <Link to={Routes.ROUTE_REPORTS}>
                {translations[language].reports}
              </Link>
            ),
          },
          {
            key: sidebarKey.PayoffPromoters,
            icon: <EuroOutlined />,
            label: (
              <Link to={Routes.ROUTE_PAYOFF_PROMOTERS}>
                {translations[language].payoffsPromoters}
              </Link>
            ),
          },
          {
            key: sidebarKey.PayoffHostesses,
            icon: <EuroOutlined />,
            label: (
              <Link to={Routes.ROUTE_PAYOFF_HOSTESSES}>
                {translations[language].payoffsHossteses}
              </Link>
            ),
          },
          {
            key: sidebarKey.PayoffBartenders,
            icon: <EuroOutlined />,
            label: (
              <Link to={Routes.ROUTE_PAYOFF_BARTENDERS}>
                {translations[language].payoffsBartenders}
              </Link>
            ),
          },
          {
            key: sidebarKey.PayoffTechnicians,
            icon: <EuroOutlined />,
            label: (
              <Link to={Routes.ROUTE_PAYOFF_TECHNICIANS}>
                {translations[language].payoffsTechnicians}
              </Link>
            ),
          },
          {
            key: sidebarKey.Settings,
            icon: <SettingOutlined />,
            label: translations[language].settings,
            children: [
              {
                key: sidebarKey.Promoters,
                icon: <UserOutlined />,
                label: (
                  <Link to={Routes.ROUTE_PROMOTERS}>
                    {translations[language].promoters}
                  </Link>
                ),
              },
              {
                key: sidebarKey.Hostesses,
                icon: <UserOutlined />,
                label: (
                  <Link to={Routes.ROUTE_HOSTESSES}>
                    {translations[language].hostesses}
                  </Link>
                ),
              },
              {
                key: sidebarKey.Bartenders,
                icon: <UserOutlined />,
                label: (
                  <Link to={Routes.ROUTE_BARTENDERS}>
                    {translations[language].bartenders}
                  </Link>
                ),
              },
              {
                key: sidebarKey.Technicians,
                icon: <UserOutlined />,
                label: (
                  <Link to={Routes.ROUTE_TECHNICIANS}>
                    {translations[language].technicians}
                  </Link>
                ),
              },
              {
                key: sidebarKey.BrandManagers,
                icon: <UserOutlined />,
                label: (
                  <Link to={Routes.ROUTE_BRAND_MANAGERS}>
                    {translations[language].brandManagers}
                  </Link>
                ),
              },
              {
                key: sidebarKey.Principals,
                icon: <UserOutlined />,
                label: (
                  <Link to={Routes.ROUTE_PRINCIPALS}>
                    {translations[language].principals}
                  </Link>
                ),
              },

              {
                key: sidebarKey.Locations,
                icon: <ShopOutlined />,
                label: (
                  <Link to={Routes.ROUTE_LOCATIONS}>
                    {translations[language].locations}
                  </Link>
                ),
              },

              {
                key: sidebarKey.Areas,
                icon: <CompressOutlined />,
                label: (
                  <Link to={Routes.ROUTE_AREAS}>
                    {translations[language].areas}
                  </Link>
                ),
              },
              {
                key: sidebarKey.Cities,
                icon: <BuildOutlined />,
                label: (
                  <Link to={Routes.ROUTE_CITIES}>
                    {translations[language].cities}
                  </Link>
                ),
              },
              {
                key: sidebarKey.Regions,
                icon: <DingtalkOutlined />,
                label: (
                  <Link to={Routes.ROUTE_REGIONS}>
                    {translations[language].regions}
                  </Link>
                ),
              },
              {
                key: sidebarKey.Representatives,
                icon: <SolutionOutlined />,
                label: (
                  <Link to={Routes.ROUTE_REPRESENTATIVES}>
                    {translations[language].representatives}
                  </Link>
                ),
              },
              {
                key: sidebarKey.Brands,
                icon: <TagOutlined />,
                label: (
                  <Link to={Routes.ROUTE_BRANDS}>
                    {translations[language].brands}
                  </Link>
                ),
              },
              {
                key: sidebarKey.SignatureDrinks,
                icon: <TagsOutlined />,
                label: (
                  <Link to={Routes.ROUTE_SIGNATURE_DRINKS}>
                    {translations[language].signatureDrinks}
                  </Link>
                ),
              },
              {
                key: sidebarKey.LocationTypes,
                icon: <ShopOutlined />,
                label: (
                  <Link to={Routes.ROUTE_LOCATION_TYPES}>
                    {translations[language].locationTypes}
                  </Link>
                ),
              },
              {
                key: sidebarKey.PromotionTypes,
                icon: <ShopOutlined />,
                label: (
                  <Link to={Routes.ROUTE_PROMOTION_TYPES}>
                    {translations[language].promotionTypes}
                  </Link>
                ),
              },
              {
                key: sidebarKey.MusicTypes,
                icon: <ShopOutlined />,
                label: (
                  <Link to={Routes.ROUTE_MUSIC_TYPES}>
                    {translations[language].musicTypes}
                  </Link>
                ),
              },
              {
                key: sidebarKey.WeatherTypes,
                icon: <CloudOutlined />,
                label: (
                  <Link to={Routes.ROUTE_WEATHER_TYPES}>
                    {translations[language].weatherTypes}
                  </Link>
                ),
              },
              {
                key: sidebarKey.Mails,
                icon: <MailOutlined />,
                label: (
                  <Link to={Routes.ROUTE_MAILS}>
                    {translations[language].mails}
                  </Link>
                ),
              },
              {
                key: sidebarKey.TravelCost,
                icon: <CarOutlined />,
                label: (
                  <Link to={Routes.ROUTE_TRAVEL_COST}>
                    {translations[language].travelCost}
                  </Link>
                ),
              },
              {
                key: sidebarKey.HourlyRate,
                icon: <EuroCircleOutlined />,
                label: (
                  <Link to={Routes.ROUTE_HOURLY_RATE}>
                    {translations[language].hourlyRates}
                  </Link>
                ),
              },
            ],
          },
        ];
      case UserRole.Promoter:
        return [
          {
            key: sidebarKey.Reports,
            icon: <FileTextOutlined />,
            label: (
              <Link to={Routes.ROUTE_REPORTS}>
                {translations[language].reports}
              </Link>
            ),
          },
          {
            key: sidebarKey.PayoffPromoters,
            icon: <EuroOutlined />,
            label: (
              <Link to={Routes.ROUTE_PAYOFF_PROMOTERS}>
                {translations[language].payoffsPromoters}
              </Link>
            ),
          },
        ];
      case UserRole.Technician:
        return [
          {
            key: sidebarKey.Reports,
            icon: <FileTextOutlined />,
            label: (
              <Link to={Routes.ROUTE_REPORTS}>
                {translations[language].reports}
              </Link>
            ),
          },
          {
            key: sidebarKey.PayoffTechnicians,
            icon: <EuroOutlined />,
            label: (
              <Link to={Routes.ROUTE_PAYOFF_TECHNICIANS}>
                {translations[language].payoffsTechnicians}
              </Link>
            ),
          },
        ];
      case UserRole.Bartender:
        return [
          {
            key: sidebarKey.Reports,
            icon: <FileTextOutlined />,
            label: (
              <Link to={Routes.ROUTE_REPORTS}>
                {translations[language].reports}
              </Link>
            ),
          },
          {
            key: sidebarKey.PayoffBartenders,
            icon: <EuroOutlined />,
            label: (
              <Link to={Routes.ROUTE_PAYOFF_BARTENDERS}>
                {translations[language].payoffsBartenders}
              </Link>
            ),
          },
        ];
      case UserRole.BrandManager:
        return [
          {
            key: sidebarKey.Reports,
            icon: <FileTextOutlined />,
            label: (
              <Link to={Routes.ROUTE_REPORTS}>
                {translations[language].reports}
              </Link>
            ),
          },
        ];
      default:
        return [];
    }
  };

  const closeSidebar = (): void => {
    if (isMobile) {
      collapseSidebar();
    }
  };

  return (
    <Menu
      className="side-menu"
      theme="dark"
      mode="inline"
      defaultSelectedKeys={getInitialSidebarKey()}
      onSelect={closeSidebar}
      items={getSidebarItemsByUserRole()}
    />
  );
};

export default SideMenu;
