/* eslint-disable react-hooks/exhaustive-deps */
import { PlusOutlined } from "@ant-design/icons";
import { Button, Drawer, PageHeader } from "antd";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../components/delete-modal/DeleteModal";
import { DrawerButtons } from "../../components/drawer-buttons/DrawerButtons";
import {
  DRAWER_MEDIUM_WIDTH,
  DRAWER_SMALL_WIDTH
} from "../../config/constants";
import { translations } from "../../config/translations";
import {
  DrawerState,
  ImageEntityType, PersonType, SimpleDrawerState
} from "../../core/models/Enum";
import { deletePerson } from "../../core/services/PersonService";
import { useAuthContext } from "../../helpers/AuthContext";
import { PageHeaderButtonSettings } from "../../helpers/ButtonHelper";
import { submitForm } from "../../helpers/FormHelper";
import {
  getDeleteModalMessage,
  getDeleteModalTitle,
  getDrawerTitle,
  getPageHeaderAddLabel,
  getPageTitle,
  getPersonTypeFromLocation,
  getRedirectUrl,
  handleInitialRoute
} from "../../helpers/PersonHelper";
import ImagesForm from "../Image/ImagesForm";
import ChangePasswordPersonForm from "./ChangePasswordPersonForm";
import PersonForm from "./PersonForm";
import PersonsTable from "./PersonTable";

const Person: React.FC = () => {
  const { language, setLoading } = useAuthContext();
  const authContext = useAuthContext();
  const [drawerState, setDrawerState] = useState<DrawerState>(
    DrawerState.Closed
  );
  const [imagesDrawerState, setImagesDrawerState] = useState<SimpleDrawerState>(
    SimpleDrawerState.Closed
  );
  const [changePasswordDrawerState, setChangePasswordDrawerState] =
    useState<SimpleDrawerState>(SimpleDrawerState.Closed);
  const [shouldTableUpdate, setShouldTableUpdate] = useState<boolean>(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [personType, setPersonType] = useState<PersonType | undefined>();
  const [selectedEntityId, setSelectedEntityId] = useState<
    number | undefined
  >();
  const { promoterId, bartenderId, technicianId, hostessId, brandManagerId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const ref = React.createRef();

  useEffect(() => {
    const newType = getPersonTypeFromLocation(location);
    if (newType !== personType) {
      checkInitialRoute();
    }
  }, [location.pathname]);

  const checkInitialRoute = (): void => {
    handleInitialRoute(
      handleInitialSetup,
      navigate,
      location,
      promoterId,
      bartenderId,
      technicianId,
      hostessId,
      brandManagerId
    );
  };

  const handleInitialSetup = (
    newDrawerState: DrawerState,
    newShouldUpdate: boolean,
    newPersonType: PersonType,
    id?: number
  ): void => {
    setPersonType(newPersonType);
    handleDrawerChange(newDrawerState, newShouldUpdate, id, newPersonType);
  };

  const handleDrawerChange = (
    newDrawerState: DrawerState,
    newShouldUpdate: boolean,
    id?: number,
    newPersonType?: PersonType
  ) => {
    setSelectedEntityId(id);
    setShouldTableUpdate(newShouldUpdate);
    setDrawerState(newDrawerState);
    navigate(
      getRedirectUrl(
        newDrawerState,
        newPersonType !== undefined ? newPersonType : personType,
        id
      )
    );
  };

  const handleImagesModalChange = (
    newDrawerState: SimpleDrawerState,
    id?: number
  ) => {
    setSelectedEntityId(id);
    setImagesDrawerState(newDrawerState);
  };

  const handleChangePasswordModalChange = (
    newDrawerState: SimpleDrawerState,
    id?: number
  ) => {
    setSelectedEntityId(id);
    setChangePasswordDrawerState(newDrawerState);
  };

  const handleDeleteModalChange = (
    newModalVisibility: boolean,
    newShouldUpdate: boolean,
    id?: number
  ) => {
    setSelectedEntityId(id);
    setShouldTableUpdate(newShouldUpdate);
    setDeleteModalVisible(newModalVisibility);
  };

  const deleteEntity = async (): Promise<void> => {
    if (!selectedEntityId) return;

    setLoading(true, translations[language].deleting);

    const result = await deletePerson(authContext, selectedEntityId);

    if (result) {
      handleDeleteModalChange(false, true);
    }

    setLoading(false);
  };

  return (
    <>
      <PageHeader
        title={getPageTitle(language, personType)}
        extra={[
          <Button
            key="persons-button"
            {...PageHeaderButtonSettings}
            onClick={() => handleDrawerChange(DrawerState.Create, false)}
          >
            <PlusOutlined />
            {getPageHeaderAddLabel(language, personType)}
          </Button>,
        ]}
      />
      <PersonsTable
        shouldUpdate={shouldTableUpdate}
        personType={personType}
        setShouldUpdateState={setShouldTableUpdate}
        handleDrawerChange={handleDrawerChange}
        handleDeleteModalChange={handleDeleteModalChange}
        handleImagesDrawerChange={handleImagesModalChange}
        handleChangePasswordDrawerChange={handleChangePasswordModalChange}
      />
      <Drawer
        title={getDrawerTitle(language, personType, selectedEntityId)}
        open={!!drawerState}
        width={isMobile ? window.screen.width : DRAWER_SMALL_WIDTH}
        destroyOnClose
        onClose={() => handleDrawerChange(DrawerState.Closed, false)}
        footer={
          <DrawerButtons
            onCancelAction={() => handleDrawerChange(DrawerState.Closed, false)}
            onSubmitAction={() => submitForm(ref)}
          />
        }
      >
        <PersonForm
          drawerState={drawerState}
          personType={personType}
          selectedEntityId={selectedEntityId}
          wrappedComponentRef={ref}
          onClose={handleDrawerChange}
        />
      </Drawer>
      <Drawer
        title={translations[language].editImages}
        open={!!imagesDrawerState}
        width={isMobile ? window.screen.width : DRAWER_MEDIUM_WIDTH}
        destroyOnClose
        onClose={() => handleImagesModalChange(SimpleDrawerState.Closed)}
        footer={
          <DrawerButtons
            onCancelAction={() =>
              handleImagesModalChange(SimpleDrawerState.Closed)
            }
            onSubmitAction={() => submitForm(ref)}
          />
        }
      >
        <ImagesForm
          drawerState={imagesDrawerState}
          entityType={ImageEntityType.Person}
          selectedEntityId={selectedEntityId}
          wrappedComponentRef={ref}
          onClose={handleImagesModalChange}
        />
      </Drawer>
      <Drawer
        title={translations[language].changePassword}
        open={!!changePasswordDrawerState}
        width={isMobile ? window.screen.width : DRAWER_SMALL_WIDTH}
        destroyOnClose
        onClose={() =>
          handleChangePasswordModalChange(SimpleDrawerState.Closed)
        }
        footer={
          <DrawerButtons
            onCancelAction={() =>
              handleChangePasswordModalChange(SimpleDrawerState.Closed)
            }
            onSubmitAction={() => submitForm(ref)}
          />
        }
      >
        <ChangePasswordPersonForm
          selectedEntityId={selectedEntityId}
          wrappedComponentRef={ref}
          onClose={handleChangePasswordModalChange}
        />
      </Drawer>
      <DeleteModal
        title={getDeleteModalTitle(language, personType)}
        message={getDeleteModalMessage(language, personType)}
        isVisible={deleteModalVisible}
        onOkAction={deleteEntity}
        onCancelAction={(): void => handleDeleteModalChange(false, false)}
      />
    </>
  );
};

export default Person;
