/* eslint-disable react-hooks/exhaustive-deps */
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { DatePicker, Form, Input, InputNumber } from "antd";
import Select, { DefaultOptionType } from "antd/lib/select";
import React, { useEffect, useState } from "react";
import { defaultFormatDate } from "../../config/constants";
import { translations } from "../../config/translations";
import { AreaVM } from "../../core/models/Area";
import { BaseViewModel } from "../../core/models/Base";
import { CityVM } from "../../core/models/City";
import { DrawerState, PersonType } from "../../core/models/Enum";
import { HostessDetailVM, HostessFormModel } from "../../core/models/Hostess";
import { PersonDetailVM, PersonFormModel } from "../../core/models/Person";
import { getAreasList } from "../../core/services/AreaService";
import { getBrandsList } from "../../core/services/BrandService";
import { getCitiesList } from "../../core/services/CityService";
import {
  createHostess,
  getHostessDetails,
  updateHostess
} from "../../core/services/HostessService";
import {
  createPerson,
  getPersonDetails,
  updatePerson
} from "../../core/services/PersonService";
import { getRegionsList } from "../../core/services/RegionService";
import { getRepresentativesList } from "../../core/services/RepresentativeService";
import { useAuthContext } from "../../helpers/AuthContext";
import {
  filterSelectOption,
  formGenericRules,
  formItemCrudDrawer
} from "../../helpers/FormHelper";
import { convertDateTimeToUtc } from "../../helpers/TimeHelper";

interface Props {
  drawerState: DrawerState;
  personType?: PersonType;
  selectedEntityId?: number;
  wrappedComponentRef?: React.RefObject<any>;
  onClose: (
    drawerState: DrawerState,
    shouldUpdate: boolean,
    id?: number
  ) => void;
}

const PersonForm: React.FC<Props> = (props: Props) => {
  const {
    drawerState,
    personType,
    selectedEntityId,
    wrappedComponentRef,
    onClose,
  } = props;
  const [form] = Form.useForm();
  const { language, setLoading } = useAuthContext();
  const authContext = useAuthContext();
  const [selectedEntity, setSelectedEntity] = useState<
    PersonDetailVM | undefined
  >();
  const [selectedEntityHostess, setSelectedEntityHostess] = useState<
    HostessDetailVM | undefined
  >();
  const [representatives, setRepresentatives] = useState<DefaultOptionType[]>(
    []
  );
  const [regions, setRegions] = useState<DefaultOptionType[]>([]);
  const [cities, setCities] = useState<CityVM[]>([]);
  const [filteredCities, setFilteredCities] = useState<DefaultOptionType[]>([]);
  const [areas, setAreas] = useState<AreaVM[]>([]);
  const [filteredAreas, setFilteredAreas] = useState<DefaultOptionType[]>([]);
  const [brands, setBrands] = useState<DefaultOptionType[]>([]);

  useEffect(() => {
    getCities();
    getRepresentatives();
    getRegions();
    getAreas();
    getBrands();
  }, []);

  useEffect(() => {
    if (
      cities.length > 0 &&
      representatives.length > 0 &&
      regions.length > 0 &&
      areas.length > 0 &&
      brands.length > 0
    ) {
      getFormData();
    }
  }, [cities, representatives, regions, areas, brands]);

  const getBrands = async () => {
    const data = await getBrandsList(authContext);

    if (data.length > 0) {
      setBrands(
        data.map(
          (item: BaseViewModel): DefaultOptionType => ({
            value: item.id,
            label: item.name,
          })
        )
      );
    }
  };

  const getCities = async () => {
    const data = await getCitiesList(authContext);

    if (data.length > 0) {
      setCities(data);
      setFilteredCities(
        data.map(
          (item: BaseViewModel): DefaultOptionType => ({
            value: item.id,
            label: item.name,
          })
        )
      );
    }
  };

  const filterCityOptions = (id: number): void => {
    const filteredValues = cities.filter(
      (city: CityVM): boolean => city.region.id === id
    );

    setFilteredCities(
      filteredValues.map(
        (item: BaseViewModel): DefaultOptionType => ({
          value: item.id,
          label: item.name,
        })
      )
    );
  };

  const getRepresentatives = async () => {
    const data = await getRepresentativesList(authContext);

    if (data.length > 0) {
      setRepresentatives(
        data.map(
          (item: BaseViewModel): DefaultOptionType => ({
            value: item.id,
            label: item.name,
          })
        )
      );
    }
  };

  const getRegions = async () => {
    const data = await getRegionsList(authContext);

    if (data.length > 0) {
      setRegions(
        data.map(
          (item: BaseViewModel): DefaultOptionType => ({
            value: item.id,
            label: item.name,
          })
        )
      );
    }
  };

  const getAreas = async () => {
    const data = await getAreasList(authContext);

    if (data.length > 0) {
      setAreas(data);
      setFilteredAreas(
        data.map(
          (item: BaseViewModel): DefaultOptionType => ({
            value: item.id,
            label: item.name,
          })
        )
      );
    }
  };

  const filterAreaOptions = (id: number): void => {
    const filteredValues = areas.filter(
      (area: AreaVM): boolean => area.city.id === id
    );

    setFilteredAreas(
      filteredValues.map(
        (item: BaseViewModel): DefaultOptionType => ({
          value: item.id,
          label: item.name,
        })
      )
    );
  };

  const getFormData = async () => {
    if (!selectedEntityId) return;

    setLoading(true);
    if (personType === PersonType.Hostess) {
      const result = await getHostessDetails(authContext, selectedEntityId);

      if (result) {
        setSelectedEntityHostess(result);
      }
    } else {
      const result = await getPersonDetails(authContext, selectedEntityId);

      if (result) {
        setSelectedEntity(result);
      }
    }

    setLoading(false);
  };

  const handleSave = async (formValues: PersonFormModel | HostessFormModel) => {
    if (personType === undefined) return;

    setLoading(true, translations[language].saving);

    const formatedDate = formValues.birthDate?.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    const requestModel = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      residence: formValues.residence,
      representativeId: formValues.representative,
      regionId: formValues.region,
      cityId: formValues.city,
      areaId: formValues.area,
      street: formValues.street,
      phone: formValues.phone,
      email: formValues.email,
      note: formValues.note,
      brandId: formValues.brand,
      birthDate: formatedDate,
      personType,
    };

    if (personType === PersonType.Hostess) {
      const result =
        drawerState === DrawerState.Edit && selectedEntityId
          ? await updateHostess(authContext, {
              ...requestModel,
              id: selectedEntityId,
              height: (formValues as HostessFormModel).height,
              confectionSize: (formValues as HostessFormModel).confectionSize,
            })
          : await createHostess(authContext, {
              ...requestModel,
              height: (formValues as HostessFormModel).height,
              confectionSize: (formValues as HostessFormModel).confectionSize,
            });

      if (result) {
        onClose(DrawerState.Closed, true, undefined);
      }
    } else {
      const result =
        drawerState === DrawerState.Edit && selectedEntityId
          ? await updatePerson(authContext, {
              ...requestModel,
              id: selectedEntityId,
            })
          : await createPerson(authContext, {
              ...requestModel,
              password: formValues.password,
            });

      if (result) {
        onClose(DrawerState.Closed, true, undefined);
      }
    }

    setLoading(false);
  };

  return (
    <>
      {(drawerState === DrawerState.Create ||
        (drawerState === DrawerState.Edit &&
          (selectedEntity || selectedEntityHostess))) && (
        <Form
          onFinish={handleSave}
          className="form"
          {...formItemCrudDrawer}
          ref={wrappedComponentRef}
          form={form}
        >
          <Form.Item
            {...formGenericRules(language, true)}
            name="firstName"
            label={translations[language].firstName}
            initialValue={
              selectedEntity?.firstName || selectedEntityHostess?.firstName
            }
          >
            <Input placeholder={translations[language].firstNameEnter} />
          </Form.Item>
          <Form.Item
            {...formGenericRules(language, true)}
            name="lastName"
            label={translations[language].lastName}
            initialValue={
              selectedEntity?.lastName || selectedEntityHostess?.lastName
            }
          >
            <Input placeholder={translations[language].lastNameEnter} />
          </Form.Item>
          {personType !== PersonType.BrandManager && (
            <>
              <Form.Item
                name="residence"
                label={translations[language].residence}
                initialValue={
                  selectedEntity?.residence || selectedEntityHostess?.residence
                }
              >
                <Input placeholder={translations[language].residenceEnter} />
              </Form.Item>
              <Form.Item
                name="birthDate"
                label={translations[language].dateOfBirth}
                initialValue={convertDateTimeToUtc(
                  selectedEntity?.birthDate || selectedEntityHostess?.birthDate
                )}
              >
                <DatePicker
                  placeholder={translations[language].dateOfBirthChoose}
                  format={defaultFormatDate}
                />
              </Form.Item>
              <Form.Item
                name="representative"
                label={translations[language].representative}
                initialValue={
                  selectedEntity?.representative?.id ||
                  selectedEntityHostess?.representative?.id
                }
              >
                <Select
                  showSearch
                  placeholder={translations[language].representativeChoose}
                  options={representatives}
                  filterOption={(input, option) =>
                    filterSelectOption(input, option)
                  }
                />
              </Form.Item>
              <Form.Item
                name="region"
                label={translations[language].region}
                initialValue={
                  selectedEntity?.region?.id ||
                  selectedEntityHostess?.region?.id
                }
              >
                <Select
                  showSearch
                  placeholder={translations[language].regionChoose}
                  options={regions}
                  onChange={(value: number | null): void => {
                    form.setFieldValue("region", value);
                    form.setFieldValue("city", undefined);
                    form.setFieldValue("area", undefined);
                    if (value) {
                      filterCityOptions(value);
                    }
                  }}
                  filterOption={(input, option) =>
                    filterSelectOption(input, option)
                  }
                />
              </Form.Item>
              <Form.Item
                name="city"
                label={translations[language].city}
                initialValue={
                  selectedEntity?.city?.id || selectedEntityHostess?.city?.id
                }
              >
                <Select
                  showSearch
                  placeholder={translations[language].cityChoose}
                  options={filteredCities}
                  onChange={(value: number | null): void => {
                    form.setFieldValue("city", value);
                    form.setFieldValue("area", undefined);
                    if (value) {
                      filterAreaOptions(value);
                    }
                  }}
                  filterOption={(input, option) =>
                    filterSelectOption(input, option)
                  }
                />
              </Form.Item>
              <Form.Item
                name="area"
                label={translations[language].area}
                initialValue={
                  selectedEntity?.area?.id || selectedEntityHostess?.area?.id
                }
              >
                <Select
                  showSearch
                  placeholder={translations[language].areaChoose}
                  options={filteredAreas}
                  filterOption={(input, option) =>
                    filterSelectOption(input, option)
                  }
                />
              </Form.Item>
              <Form.Item
                name="street"
                label={translations[language].street}
                initialValue={
                  selectedEntity?.street || selectedEntityHostess?.street
                }
              >
                <Input placeholder={translations[language].streetEnter} />
              </Form.Item>{" "}
              <Form.Item
                name="note"
                label={translations[language].note}
                initialValue={
                  selectedEntity?.note || selectedEntityHostess?.note
                }
              >
                <Input placeholder={translations[language].enterNote} />
              </Form.Item>
              {personType === PersonType.Hostess && (
                <>
                  <Form.Item
                    name="height"
                    label={translations[language].height}
                    initialValue={selectedEntityHostess?.height}
                  >
                    <InputNumber
                      min={1}
                      max={250}
                      placeholder={translations[language].heightEnter}
                      addonAfter={<span>cm</span>}
                    />
                  </Form.Item>
                  <Form.Item
                    name="confectionSize"
                    label={translations[language].garmentNumber}
                    initialValue={selectedEntityHostess?.confectionSize}
                  >
                    <Input
                      placeholder={translations[language].garmentNumberEnter}
                    />
                  </Form.Item>
                </>
              )}
            </>
          )}
          {personType === PersonType.BrandManager && (
            <Form.Item
              {...formGenericRules(language, true)}
              name="brand"
              label={translations[language].brand}
              initialValue={selectedEntity?.brand?.id}
            >
              <Select
                showSearch
                placeholder={translations[language].brandChoose}
                options={brands}
                filterOption={(input, option) =>
                  filterSelectOption(input, option)
                }
              />
            </Form.Item>
          )}
          <Form.Item
            name="phone"
            label={translations[language].mobilePhone}
            initialValue={selectedEntity?.phone || selectedEntityHostess?.phone}
          >
            <Input placeholder={translations[language].enterMobilePhone} />
          </Form.Item>
          <Form.Item
            {...formGenericRules(
              language,
              personType === PersonType.Hostess ? false : true
            )}
            name="email"
            label={translations[language].email}
            initialValue={selectedEntity?.email || selectedEntityHostess?.email}
          >
            <Input placeholder={translations[language].enterEmail} />
          </Form.Item>
          {personType !== PersonType.Hostess &&
            drawerState === DrawerState.Create && (
              <>
                <Form.Item
                  name="password"
                  label={translations[language].password}
                  rules={[
                    {
                      required: true,
                      message: translations[language].requiredField,
                    },
                    {
                      pattern:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                      message: translations[language].passwordValidation,
                    },
                  ]}
                >
                  <Input.Password
                    placeholder={translations[language].passwordEnter}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="repeatedPassword"
                  label={translations[language].repeatedPassword}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(translations[language].notSamePassword)
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    placeholder={translations[language].repeatedPasswordEnter}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
              </>
            )}
        </Form>
      )}
    </>
  );
};

export default PersonForm;
