export const translations = {
  hr: {
    actions: "Akcije",
    addArea: "Dodaj kvart",
    addBartender: "Dodaj barmena",
    addBrand: "Dodaj brand",
    addBrandGroup: "Dodaj grupu brandova",
    addBrandManager: "Dodaj brand managera",
    addCity: "Dodaj grad",
    addHostess: "Dodaj hostesu",
    addLocation: "Dodaj lokal",
    addLocationType: "Dodaj tip lokala",
    addMail: "Dodaj mail",
    addManager: "Dodaj managera",
    addMusicType: "Dodaj tip glazbe",
    addNewPhotos: "Dodaj nove fotografije",
    addOrder: "Dodaj radni nalog",
    addPrincipal: "Dodaj nalogodavca",
    addPromoter: "Dodaj promotera",
    addPromotionType: "Dodaj tip promocije",
    addQuestion: "Dodaj pitanje",
    addRegion: "Dodaj županiju",
    addReport: "Dodaj izvještaj",
    addRepresentative: "Dodaj zastupništvo",
    addressLink: "Link adrese",
    addSignatureDrink: "Dodaj signature drink",
    addTechnician: "Dodaj tehničara",
    addWeatherType: "Dodaj tip vremena",
    ageGroup: "Dobna skupina posjetitelja",
    ageGroupChoose: "Odaberi dobnu skupinu posjetitelja",
    all: "Svi",
    april: "Travanj",
    area: "Kvart",
    areaChoose: "Odaberi kvart",
    areas: "Kvartovi",
    atLeastOneFile: "Učitaj barem jednu datoteku!",
    attendeesNumber: "Broj prisutnih ljudi na promociji",
    attendeesNumberEnter: "Unesi broj prisutnih ljudi na promociji",
    august: "Kolovoz",
    bartender: "Barmen",
    bartenderChoose: "Odaberi barmena",
    bartenders: "Barmeni",
    bartendersNo: "Broj barmena",
    brand: "Brand",
    brandChoose: "Odaberi brand",
    brandGroup: "Grupa brandova",
    brandGroupChoose: "Odaberi grupu brandova",
    brandGroups: "Grupe brandova",
    brandManagers: "Brand manageri",
    brands: "Brandovi",
    cancel: "Odustani",
    changePassword: "Izmijeni lozinku",
    choose: "Odaberi",
    chooseBrandAndMonthToDownload:
      "Odaberite mjesec i brand kako biste mogli preuzeti fotografije.",
    chooseMonthToDownload:
      "Odaberite mjesec kako biste mogli preuzeti fotografije.",
    cities: "Gradovi",
    city: "Grad",
    cityChoose: "Odaberi grad",
    clear: "Očisti",
    close: "Zatvori",
    competitionBrand: "Konkurentsko žestoko alkoholno piće",
    competitionBrandEnter: "Unesi konkurentsko žestoko alkoholno piće",
    confirmDeleteArea: "Jesi li siguran da želiš obrisati kvart?",
    confirmDeleteBartender: "Jesi li siguran da želiš obrisati barmena?",
    confirmDeleteBrand: "Jesi li siguran da želiš obrisati brand?",
    confirmDeleteBrandGroup:
      "Jesi li siguran da želiš obrisati grupu brandova?",
    confirmDeleteBrandManager:
      "Jesi li siguran da želiš obrisati brand managera?",
    confirmDeleteCity: "Jesi li siguran da želiš obrisati grad?",
    confirmDeleteHostess: "Jesi li siguran da želiš obrisati hostesu?",
    confirmDeleteLocation: "Jesi li siguran da želiš obrisati lokal?",
    confirmDeleteLocationType: "Jesi li siguran da želiš obrisati tip lokala?",
    confirmDeleteMail: "Jesi li siguran da želiš obrisati mail?",
    confirmDeleteMusicType: "Jesi li siguran da želiš obrisati tip glazbe?",
    confirmDeleteOrder: "Jesi li siguran da želiš obrisati radni nalog?",
    confirmDeletePrincipal: "Jesi li siguran da želiš obrisati nalogodavca?",
    confirmDeletePromoter: "Jesi li siguran da želiš obrisati promotera?",
    confirmDeletePromotionType:
      "Jesi li siguran da želiš obrisati tip promocije?",
    confirmDeleteRegion: "Jesi li siguran da želiš obrisati županiju?",
    confirmDeleteReport: "Jesi li siguran da želiš obrisati izvještaj?",
    confirmDeleteRepresentative:
      "Jesi li siguran da želiš obrisati zastupništvo?",
    confirmDeleteSignatureDrink:
      "Jesi li siguran da želiš obrisati signature drink?",
    confirmDeleteTechnician: "Jesi li siguran da želiš obrisati tehničara?",
    confirmDeleteWeatherType: "Jesi li siguran da želiš obrisati tip vremena?",
    contactPerson: "Kontakt osoba",
    contactPersonEnter: "Unesi kontakt osobu",
    continue: "Nastavi",
    costs: "Troškovi",
    createdAt: "Kreiran",
    cumulative07Sold: "Ukupno prodano boca u 0.7L",
    customFields: "Prilagođena polja",
    dashboard: "Početna",
    dateOfBirth: "Datum rođenja",
    dateOfBirthChoose: "Odaberi datum rođenja",
    day: "Dan",
    daysOfTheWeek: {
      friday: "Petak",
      monday: "Ponedjeljak",
      saturday: "Subota",
      sunday: "Nedjelja",
      thursday: "Četvrtak",
      tuesday: "Utorak",
      wednesday: "Srijeda",
    },
    daysOfTheWeekShort: {
      friday: "Pet",
      monday: "Pon",
      saturday: "Sub",
      sunday: "Ned",
      thursday: "Čet",
      tuesday: "Uto",
      wednesday: "Sri",
    },
    december: "Prosinac",
    delete: "Obriši",
    deleteArea: "Obriši kvart",
    deleteBartender: "Obriši barmena",
    deleteBrand: "Obriši brand",
    deleteBrandGroup: "Obriši grupu brandova",
    deleteBrandManager: "Obriši brand managera",
    deleteCity: "Obriši grad",
    deleteHostess: "Obriši hostesu",
    deleteLocation: "Obriši lokal",
    deleteLocationType: "Obriši tip lokala",
    deleteMail: "Obriši mail",
    deleteMusicType: "Obriši tip glazbe",
    deleteOrder: "Obriši radni nalog",
    deletePrincipal: "Obriši nalogodavca",
    deletePromoter: "Obriši promotera",
    deletePromotionType: "Obriši tip promocije",
    deleteRegion: "Obriši županiju",
    deleteReport: "Obriši izvještaj",
    deleteRepresentative: "Obriši zastupništvo",
    deleteSignatureDrink: "Obriši signature drink",
    deleteTechnician: "Obriši tehničara",
    deleteWeatherType: "Obriši tip vremena",
    deleting: "Brisanje podataka, molimo pričekaj.",
    details: "Detalji",
    download: "Preuzmi",
    downloadGroupedImages: "Preuzmi grupirane fotografije",
    downloadGroupedXlsx: "Preuzmi grupirani XLSX",
    downloadImages: "Preuzmi fotografije",
    downloadXlsx: "Preuzmi XLSX",
    edit: "Uredi",
    editArea: "Uredi kvart",
    editBartender: "Uredi barmena",
    editBrand: "Uredi brand",
    editBrandGroup: "Uredi grupu brandova",
    editBrandManager: "Uredi brand managera",
    editCity: "Uredi grad",
    editHostess: "Uredi hostesu",
    editImages: "Uredi fotografije",
    editLocation: "Uredi lokal",
    editLocationType: "Uredi tip lokala",
    editMail: "Uredi mail",
    editMusicType: "Uredi tip glazbe",
    editOrder: "Uredi radni nalog",
    editPrincipal: "Uredi nalogodavca",
    editPromoter: "Uredi promotera",
    editPromotionType: "Uredi tip promocije",
    editRegion: "Uredi županiju",
    editReport: "Uredi izvještaj",
    editRepresentative: "Uredi zastupništvo",
    editSignatureDrink: "Uredi signature drink",
    editSoldBottles: "Uredi količine prodanih boca",
    editTechnician: "Uredi tehničara",
    editWeatherType: "Uredi tip vremena",
    email: "Email",
    emptyFields: "Molimo popuni sva polja",
    enterCustomFields: "Unesi prilagođena polja",
    enterEmail: "Unesi email",
    enterHourlyRateBartender: "Unesite satnicu barmena",
    enterHourlyRateHostess: "Unesite satnicu hostesa",
    enterHourlyRatePromoter: "Unesite satnicu promotera",
    enterHourlyRateTechnician: "Unesite satnicu tehničara",
    enterKey: "Unesite ključ",
    enterMobilePhone: "Unesi broj mobitela",
    enterNote: "Unesi napomenu",
    enterQuestion: "Unesite pitanje",
    entireDay: "Cijeli dan",
    error: "Greška",
    eur: "€",
    expensesFee: "Ostali troškovi",
    february: "Veljača",
    fee: "Honorar",
    feeEnter: "Unesi honorar",
    firstName: "Ime",
    firstNameEnter: "Unesi ime",
    forSmallGlass: "za čašu 0.03L",
    from: "Od",
    fuelCost: "Ukupno za kilometražu",
    fullName: "Ime i prezime",
    fullNameEnter: "Unesi ime i prezime",
    functionNotImplemented: "Funkcija nije implementirana.",
    garmentNumber: "Konfekcijski broj",
    garmentNumberEnter: "Unesi konfekcijski broj",
    generate: "Generiraj",
    hasJmManifest: "Lokal ima u ponudi Jagermeister Manifest",
    hasJmScharf: "Lokal ima u ponudi Jagermeister Scharf",
    hasThomasHenry: "Lokal ima u ponudi Thomas Henry",
    height: "Visina",
    heightEnter: "Unesi visinu",
    hostess: "Hostesa",
    hostessChoose: "Odaberi hostesu",
    hostesses: "Hostese",
    hostessesNo: "Broj hostesa",
    hourlyRate: "Satnica",
    hourlyRateBartender: "Satnica barmena",
    hourlyRateHostess: "Satnica hostesa",
    hourlyRatePromoter: "Satnica promotera",
    hourlyRates: "Satnice",
    hourlyRateTechnician: "Satnica tehničara",
    images: "Fotografije",
    inTotal: "Ukupno",
    isJmServedCold: "Jagermeister serviran hladan",
    january: "Siječanj",
    july: "Srpanj",
    june: "Lipanj",
    key: "Ključ",
    lastName: "Prezime",
    lastNameEnter: "Unesi prezime",
    loading: "Učitavanje podataka, molimo pričekaj.",
    location: "Lokal",
    locationAddress: "Adresa lokala",
    locationCapacity: "Kapacitet lokala",
    locationCapacityEnter: "Unesi okviran kapacitet lokala",
    locationChoose: "Odaberi lokal",
    locationFullness: "Popunjenost lokala",
    locationFullnessChoose: "Odaberi popunjenost lokala",
    locations: "Lokali",
    locationType: "Tip lokala",
    locationTypeChoose: "Odaberi tip lokala",
    locationTypes: "Tipovi lokala",
    login: "Prijava",
    loginLoading: "Prijava korisnika, molimo pričekaj.",
    logout: "Odjava",
    logoutLoading: "Odjava korisnika, molimo pričekaj.",
    mail: "Mail",
    mailChoose: "Odaberi mail",
    mails: "Mailovi",
    mailsChoose: "Odaberi mailove",
    mapsLink: "Link adrese",
    mapsLinkEnter: "Unesi link adrese",
    march: "Ožujak",
    maxFileNumberReached:
      "Dosegli ste maksimalan broj fotografija koje možete dodati",
    may: "Svibanj",
    menShort: "M",
    menWomenRatio: "Omjer muškaraca i žena",
    menWomenRatioChoose: "Odaberi omjer muškaraca i žena",
    mileage: "Kilometraža",
    mileageEnter: "Unesi kilometražu",
    mobilePhone: "Broj mobitela",
    month: "Mjesec",
    monthChoose: "Odaberi mjesec",
    musicType: "Tip glazbe",
    musicTypeChoose: "Odaberi tip glazbe",
    musicTypes: "Tipovi glazbe",
    name: "Naziv",
    nameEnter: "Unesi naziv",
    no: "Ne",
    noData: "Nema podataka",
    note: "Napomena",
    notFoundDropdownContent: "Nema opcija za odabir.",
    notSamePassword: "Lozinke nisu jednake.",
    november: "Studeni",
    numberOfHours: "Broj sati",
    observations: "Ukratko o promociji",
    observationsEnter: "Unesi ukratko o promociji",
    october: "Listopad",
    oldPassword: "Stara lozinka",
    oldPasswordEnter: "Unesi staru lozinku",
    onlyOneSignatureDrink: "od ponuđenih odaberi samo jedan najčešći",
    order: "Nalog",
    orderNumber: "Broj naloga",
    orderNumberShort: "No.",
    orders: "Radni nalozi",
    overnight: "Noćenje",
    overnightEnter: "Unesi trošak noćenja",
    parking: "Parking",
    parkingEnter: "Unesi trošak parkinga",
    password: "Lozinka",
    passwordEnter: "Unesi lozinku",
    passwordValidation:
      "Lozinka mora imati barem 8 znakova, jedno malo slovo, jedno veliko slovo, broj i sprecijalan znak.",
    payoffs: "Obračuni",
    payoffsBartenders: "Obračuni barmeni",
    payoffsHossteses: "Obračuni hostese",
    payoffsPromoters: "Obračuni promoteri",
    payoffsTechnicians: "Obračuni tehničari",
    personChoose: "Odaberi osobu",
    phone: "Kontakt broj",
    phoneEnter: "Unesi kontakt broj",
    price: "Cijena na cjeniku",
    priceEnter: "Unesi cijenu na cjeniku",
    principal: "Nalogodavac",
    principalChoose: "Odaberi nalogodavca",
    principalEnter: "Unesi nalogodavca",
    principals: "Nalogodavci",
    promoter: "Promoter",
    promoterChoose: "Odaberi promotera",
    promoterContact: "Kontakt promotera",
    promoters: "Promoteri",
    promotionalPrice: "Promotivna cijena",
    promotionalPriceEnter: "Unesi promotivnu cijenu",
    promotionDate: "Datum promocije",
    promotionDateTime: "Datum i vrijeme promocije",
    promotionDateTimeChoose: "Odaberi datum i vrijeme promocije",
    promotionRatingOwner: "Ocjena promocije vlasnika",
    promotionRatingPromoter: "Ocjena promocije promotera",
    promotionTime: "Vrijeme promocije",
    promotionTimeDate: "Vrijeme i datum promocije",
    promotionType: "Tip promocije",
    promotionTypeChoose: "Odaberi tip promocije",
    promotionTypes: "Tipovi promocija",
    question: "Pitanje",
    questions: "Pitanja",
    questionValidation: "Dozvoljen je unos samo slova.",
    region: "Županija",
    regionChoose: "Odaberi županiju",
    regions: "Županije",
    register: "Registracija",
    removeBartender: "Ukloni barmena",
    removeHostess: "Ukloni hostesu",
    removeTechnician: "Ukloni tehničara",
    repeatedPassword: "Ponovljena lozinka",
    repeatedPasswordEnter: "Unesi ponovljenu lozinku",
    report: "Izvještaj",
    reportNotificationInfo:
      "Izvještaj se aktivno ispunjava  za vrijeme trajanja promocije. Prvi dio sa regularnom i promotivnom cijenom se   ispunjava   odmah   pri   dolasku   i   dogovoru   sa   voditeljem/vlasnikom   lokala   kada   se   saznaju cijene,uključujući kratak upitnik sa DA/NE pitanjima. Drugi dio izvještaja: od vrijeme,pa sve do pisanog dijela tj. „ukratko o promociji“ + obavezne fotografije s promocije, ispunjavate u zadnjih 15 min, tj. prije samog kraja promocije. Ukoliko ne možete saznati ukupnu prodaju jer npr. lokal radi duže od trajanja promocije tada u izvještaju u rubriku „Ukratko o promociji“ upišite „Molim da se prodaja provjeri sa nalogodavcem i naknadno upiše“. Brand manager može ući u vaš nalog i naknadno upisati ili ispraviti ukupnu količinu prodanog. Ukoliko pak znate prodaju, upišite ju. Kilometražu i troškove upisujete istu večer pri dolasku na kućnu dresu ili najkasnije drugi dan do 16:00 sati. Kada ste se uvjerili da ste dobro ispunili i spremili sve stavke izvještaja sa fotografijama isti možete odmah poslati.",
    reports: "Izvještaji",
    representative: "Zastupništvo",
    representativeChoose: "Odaberi zastupništvo",
    representatives: "Zastupništva",
    requiredField: "Obavezno polje.",
    residence: "Boravište",
    residenceEnter: "Unesi boravište",
    rest: "Ostalo",
    restEnter: "Unesi ostale troškove",
    save: "Spremi",
    saveAndSend: "Spremi i pošalji",
    saving: "Spremanje podataka, molimo pričekaj.",
    search: "Pretraži",
    send: "Pošalji",
    sendingReport: "Slanje izvještaja",
    sendReport: "Pošalji izvještaj",
    september: "Rujan",
    settings: "Postavke",
    signatureDrink: "Signature drink",
    signatureDrinkChoose: "Odaberi signature drink",
    signatureDrinks: "Signature drinks",
    slug: "Slug",
    sold1Bottles: "Prodano boca od 1L",
    sold1BottlesEnter: "Unesi broj prodanih boca od 1L",
    sold07Bottles: "Prodano boca od 0.7L",
    sold07BottlesEnter: "Unesi broj prodanih boca od 0.7L",
    street: "Ulica",
    streetEnter: "Unesi ulicu",
    successfulDeleting: "Uspješno brisanje.",
    successfulSaving: "Uspješno spremanje podataka.",
    supervisor: "Nadglednik",
    supervisorEnter: "Unesi nadglednika",
    supervisorNotification: "ukoliko nema nadglednika, ostavi prazno",
    technician: "Tehničar",
    technicianChoose: "Odaberi tehničara",
    technicians: "Tehničari",
    techniciansNo: "Broj tehničara",
    ticketPrice: "Cijena",
    to: "Do",
    tolls: "Cestarine, tunelarine, trajekt",
    tollsEnter: "Unesi cestarine, tunelarine i troškove trajekta",
    totalCost: "Ukupno za isplatu",
    totalRowCount: "Ukupan broj redaka",
    travelCost: "Trošak putovanja",
    travelCostChoose: "Odaberite trošak putovanja",
    travelCostEnter: "Unesi trošak putovanja po km",
    travelCostVan: "Trošak putovanja teretno vozilo",
    travelCostVanEnter: "Unesite trošak putovanja teretnog vozila",
    travelFree: "Troškovi po km",
    unsuccessfullyDeleting: "Neuspješno brisanje.",
    unsuccessfullySaving: "Neuspješno spremanje podataka.",
    updatedAt: "Uređen",
    upload: "Učitaj",
    uploadImages: "Učitavanje fotografija",
    uploadPersonImageDescription:
      "Klikni ili dovuci fotografije u ovaj prostor kako bi ih učitali",
    uploadReportImageDescription:
      "Klikni ili dovuci fotografije u ovaj prostor kako bi ih učitali",
    user: "Korisnik",
    weather: "Vrijeme",
    weatherChoose: "Odaberi vrijeme",
    weatherOptions: {
      brightly: "Vedro",
      rain: "Kiša",
      snow: "Snijeg",
      windy: "Vjetrovito",
    },
    weatherType: "Tip vremena",
    weatherTypes: "Tipovi vremena",
    week: "Tjedan",
    welcomeToPromobook: "Dobro došli u Promobook aplikaciju",
    womenShort: "Ž",
    year: "Godina",
    yes: "Da",
  },
  en: {
    actions: "Actions",
    addArea: "Add area",
    addBartender: "Add bartender",
    addBrand: "Add brand",
    addBrandGroup: "Add brand group",
    addBrandManager: "Add brand manager",
    addCity: "Add city",
    addHostess: "Add hostess",
    addLocation: "Add location",
    addLocationType: "Add location type",
    addMail: "Add mail",
    addManager: "Add manager",
    addMusicType: "Add music type",
    addNewPhotos: "Add new photos",
    addOrder: "Add order",
    addPrincipal: "Add principal",
    addPromoter: "Add promoter",
    addPromotionType: "Add promotion type",
    addQuestion: "Add question",
    addRegion: "Add region",
    addReport: "Add report",
    addRepresentative: "Add representative",
    addressLink: "Address link",
    addSignatureDrink: "Add signature drink",
    addTechnician: "Add technician",
    addWeatherType: "Add weather type",
    ageGroup: "Age group of visitors",
    ageGroupChoose: "Choose age group of visitors",
    all: "All",
    april: "April",
    area: "Area",
    areaChoose: "Choose area",
    areas: "Areas",
    atLeastOneFile: "Upload at least one file!",
    attendeesNumber: "Attendees number",
    attendeesNumberEnter: "Enter attendees number",
    august: "August",
    bartender: "Bartender",
    bartenderChoose: "Choose bartender",
    bartenders: "Bartenders",
    bartendersNo: "Bartenders no.",
    brand: "Brand",
    brandChoose: "Choose brand",
    brandGroup: "Brand group",
    brandGroupChoose: "Choose brand group",
    brandGroups: "Brand groups",
    brandManagers: "Brand managers",
    brands: "Brands",
    cancel: "Cancel",
    changePassword: "Change password",
    choose: "Choose",
    chooseBrandAndMonthToDownload:
      "Select the month and brand to be able to download the photos.",
    chooseMonthToDownload:
      "Select the month to be able to download the photos.",
    cities: "Cities",
    city: "City",
    cityChoose: "Choose city",
    clear: "Clear",
    close: "Close",
    competitionBrand: "Competitive spirit",
    competitionBrandEnter: "Enter competitive spirit",
    confirmDeleteArea: "Are you sure you want to delete area?",
    confirmDeleteBartender: "Are you sure you want to delete bartender?",
    confirmDeleteBrand: "Are you sure you want to delete brand?",
    confirmDeleteBrandGroup: "Are you sure you want to delete brand group?",
    confirmDeleteBrandManager: "Are you sure you want to delete brand manager?",
    confirmDeleteCity: "Are you sure you want to delete city?",
    confirmDeleteHostess: "Are you sure you want to delete hostess?",
    confirmDeleteLocation: "Are you sure you want to delete location?",
    confirmDeleteLocationType: "Are you sure you want to delete location type?",
    confirmDeleteMail: "Are you sure you want to delete mail?",
    confirmDeleteMusicType: "Are you sure you want to delete music type?",
    confirmDeleteOrder: "Are you sure you want to delete order?",
    confirmDeletePrincipal: "Are you sure you want to delete principal?",
    confirmDeletePromoter: "Are you sure you want to delete promoter?",
    confirmDeletePromotionType:
      "Are you sure you want to delete promotion type?",
    confirmDeleteRegion: "Are you sure you want to delete region?",
    confirmDeleteReport: "Are you sure you want to delete report?",
    confirmDeleteRepresentative:
      "Are you sure you want to delete representative?",
    confirmDeleteSignatureDrink:
      "Are you sure you want to delete signature drink?",
    confirmDeleteTechnician: "Are you sure you want to delete technician?",
    confirmDeleteWeatherType: "Are you sure you want to delete weather type?",
    contactPerson: "Contact person",
    contactPersonEnter: "Enter contact person",
    continue: "Continue",
    costs: "Costs",
    createdAt: "Created at",
    cumulative07Sold: "Total sold bottles in 0.7L",
    customFields: "Custom fields",
    dashboard: "Dashboard",
    dateOfBirth: "Date of birth",
    dateOfBirthChoose: "Choose date of birth",
    day: "Day",
    daysOfTheWeek: {
      friday: "Friday",
      monday: "Monday",
      saturday: "Saturday",
      sunday: "Sunday",
      thursday: "Thursday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
    },
    daysOfTheWeekShort: {
      friday: "Fri",
      monday: "Mon",
      saturday: "Sat",
      sunday: "Sun",
      thursday: "Thu",
      tuesday: "Tue",
      wednesday: "Wed",
    },
    december: "December",
    delete: "Delete",
    deleteArea: "Delete area",
    deleteBartender: "Delete bartender",
    deleteBrand: "Delete brand",
    deleteBrandGroup: "Delete brand group",
    deleteBrandManager: "Delete brand manager",
    deleteCity: "Delete city",
    deleteHostess: "Delete hostess",
    deleteLocation: "Delete location",
    deleteLocationType: "Delete location type",
    deleteMail: "Delete mail",
    deleteMusicType: "Delete music type",
    deleteOrder: "Delete order",
    deletePrincipal: "Delete principal",
    deletePromoter: "Delete promoter",
    deletePromotionType: "Delete promotion type",
    deleteRegion: "Delete region",
    deleteReport: "Delete report",
    deleteRepresentative: "Delete representative",
    deleteSignatureDrink: "Delete signature drink",
    deleteTechnician: "Delete technician",
    deleteWeatherType: "Delete weather type",
    deleting: "Deleting data, please wait.",
    details: "Details",
    download: "Download",
    downloadGroupedImages: "Download grouped images",
    downloadGroupedXlsx: "Download grouped XLSX",
    downloadImages: "Download images",
    downloadXlsx: "Download XLSX",
    edit: "Edit",
    editArea: "Edit area",
    editBartender: "Edit bartender",
    editBrand: "Edit brand",
    editBrandGroup: "Edit brand group",
    editBrandManager: "Edit brand manager",
    editCity: "Edit city",
    editHostess: "Edit hostess",
    editImages: "Edit images",
    editLocation: "Edit location",
    editLocationType: "Edit location type",
    editMail: "Edit mail",
    editMusicType: "Edit music type",
    editOrder: "Edit order",
    editPrincipal: "Edit principal",
    editPromoter: "Edit promoter",
    editPromotionType: "Edit promotion type",
    editRegion: "Edit region",
    editReport: "Edit report",
    editRepresentative: "Edit representative",
    editSignatureDrink: "Edit signature drink",
    editSoldBottles: "Edit sold bottles quantities",
    editTechnician: "Edit technician",
    editWeatherType: "Edit weather type",
    email: "Email",
    emptyFields: "Please fill in all fields",
    enterCustomFields: "Enter custom fields",
    enterEmail: "Enter email",
    enterHourlyRateBartender: "Enter bartender hourly rate",
    enterHourlyRateHostess: "Enter hostess hourly rate",
    enterHourlyRatePromoter: "Enter promoter hourly rate",
    enterHourlyRateTechnician: "Enter technician hourly rate",
    enterKey: "Enter key",
    enterMobilePhone: "Enter mobile phone",
    enterNote: "Enter note",
    enterQuestion: "Enter question",
    entireDay: "Entire day",
    error: "Error",
    eur: "€",
    expensesFee: "Other expenses",
    february: "February",
    fee: "Fee",
    feeEnter: "Enter fee",
    firstName: "First name",
    firstNameEnter: "Enter first name",
    forSmallGlass: "for glass 0.03L",
    from: "From",
    fuelCost: "Fuel costs",
    fullName: "Full name",
    fullNameEnter: "Enter full name",
    functionNotImplemented: "Function not implemented.",
    garmentNumber: "Garment number",
    garmentNumberEnter: "Enter garment number",
    generate: "Generate",
    hasJmManifest: "Location offers Jagermeister Manifest",
    hasJmScharf: "Location offers Jagermeister Scharf",
    hasThomasHenry: "Location offers Thomas Henry",
    height: "Height",
    heightEnter: "Enter height",
    hostess: "Hostess",
    hostessChoose: "Choose hostess",
    hostesses: "Hostesses",
    hostessesNo: "Hostesses no.",
    hourlyRate: "Hourly rate",
    hourlyRateBartender: "Bartender hourly rate",
    hourlyRateHostess: "Hostess hourly rate",
    hourlyRatePromoter: "Promoter hourly rate",
    hourlyRates: "Hourly rates",
    hourlyRateTechnician: "Technician hourly rate",
    images: "Images",
    inTotal: "In total",
    isJmServedCold: "Jagermeister served cold",
    january: "January",
    july: "July",
    june: "June",
    key: "Key",
    lastName: "Last name",
    lastNameEnter: "Enter last name",
    loading: "Loading data, please wait.",
    location: "Location",
    locationAddress: "Location address",
    locationCapacity: "Location capacity",
    locationCapacityEnter: "Enter approximate location capacity",
    locationChoose: "Choose location",
    locationFullness: "Location occupancy",
    locationFullnessChoose: "Choose location occupancy",
    locations: "Locations",
    locationType: "Location type",
    locationTypeChoose: "Choose location type",
    locationTypes: "Location types",
    login: "Login",
    loginLoading: "User logging in, please wait.",
    logout: "Logout",
    logoutLoading: "User logging out, please wait.",
    mail: "Mail",
    mailChoose: "Choose mail",
    mails: "Mails",
    mailsChoose: "Choose mails",
    mapsLink: "Maps link",
    mapsLinkEnter: "Enter maps link",
    march: "March",
    maxFileNumberReached:
      "You have reached the maximum number of images you can add",
    may: "May",
    menShort: "M",
    menWomenRatio: "Ratio of men to women",
    menWomenRatioChoose: "Choose ratio of men to women",
    mileage: "Mileage",
    mileageEnter: "Enter mileage",
    mobilePhone: "Mobile phone",
    month: "Month",
    monthChoose: "Choose month",
    musicType: "Music type",
    musicTypeChoose: "Choose music type",
    musicTypes: "Music types",
    name: "Name",
    nameEnter: "Enter name",
    no: "No",
    noData: "No data",
    note: "Note",
    notFoundDropdownContent: "There are no selectable options.",
    notSamePassword: "Passwords are not equal.",
    november: "November",
    numberOfHours: "Number of hours",
    observations: "Suggestions and observations",
    observationsEnter: "Enter suggestions and observations",
    october: "October",
    oldPassword: "Old password",
    oldPasswordEnter: "Enter old password",
    onlyOneSignatureDrink: "choose only one of the offered most common options",
    order: "Order",
    orderNumber: "Order number",
    orderNumberShort: "No.",
    orders: "Orders",
    overnight: "Overnight",
    overnightEnter: "Enter overnight cost",
    parking: "Parking",
    parkingEnter: "Enter parking cost",
    password: "Password",
    passwordEnter: "Enter password",
    passwordValidation:
      "Password must have at least 8 characters, one lowercase letter, one uppercase letter, a number and a special character.",
    payoffs: "Payoffs",
    payoffsBartenders: "Payoffs bartenders",
    payoffsHossteses: "Payoffs hostesses",
    payoffsPromoters: "Payoffs promoters",
    payoffsTechnicians: "Payoffs technicians",
    personChoose: "Choose person",
    phone: "Contact number",
    phoneEnter: "Enter contact number",
    price: "Price on the price list",
    priceEnter: "Enter price on the price list",
    principal: "Principal",
    principalChoose: "Choose principal",
    principalEnter: "Enter principal",
    principals: "Principals",
    promoter: "Promoter",
    promoterChoose: "Choose promoter",
    promoterContact: "Promoter contact",
    promoters: "Promoters",
    promotionalPrice: "Promotional price",
    promotionalPriceEnter: "Enter promotional price",
    promotionDate: "Promotion date",
    promotionDateTime: "Promotion date and time",
    promotionDateTimeChoose: "Choose promotion date and time",
    promotionRatingOwner: "Owner promotion rating",
    promotionRatingPromoter: "Promoter promotion rating",
    promotionTime: "Promotion time",
    promotionTimeDate: "Promotion time and date",
    promotionType: "Promotion type",
    promotionTypeChoose: "Choose promotion type",
    promotionTypes: "Promotion types",
    question: "Question",
    questions: "Questions",
    questionValidation: "Only letters are allowed.",
    region: "Region",
    regionChoose: "Choose region",
    regions: "Regions",
    register: "Register",
    removeBartender: "Remove bartender",
    removeHostess: "Remove hostess",
    removeTechnician: "Remove technician",
    repeatedPassword: "Repeated password",
    repeatedPasswordEnter: "Enter repeated password",
    report: "Report",
    reportNotificationInfo:
      "The report is actively filled out during the promotion. The first part, with regular and promotional prices, is filled out immediately upon arrival and agreement with the manager/owner of the venue when the prices are determined, including a short questionnaire with YES/NO questions. The second part of the report: from the time until the written section, i.e., 'Summary of the Promotion,' plus the required photos from the promotion, is filled out in the last 15 minutes, just before the end of the promotion. If you are unable to determine the total sales because, for example, the venue is open longer than the promotion lasts, then in the report under 'Summary of the Promotion,' write 'Please verify the sales with the client and enter it later.' The brand manager can access your account and subsequently enter or correct the total amount sold. However, if you do know the sales figures, enter them. You should enter the mileage and expenses the same evening upon arrival at your home address or no later than the next day by 16:00. Once you have ensured that you have correctly filled out and saved all the items in the report, including the photos, you can immediately send it.",
    reports: "Reports",
    representative: "Representative",
    representativeChoose: "Choose representative",
    representatives: "Representatives",
    requiredField: "Required field.",
    residence: "Residence",
    residenceEnter: "Enter residence",
    rest: "Other",
    restEnter: "Enter other costs",
    save: "Save",
    saveAndSend: "Save and send",
    saving: "Saving data, please wait.",
    search: "Search",
    send: "Send",
    sendingReport: "Sending report",
    sendReport: "Send report",
    september: "September",
    settings: "Settings",
    signatureDrink: "Signature drink",
    signatureDrinkChoose: "Choose signature drink",
    signatureDrinks: "Signature drinks",
    slug: "Slug",
    sold1Bottles: "1L bottles sold",
    sold1BottlesEnter: "Enter 1L bottles sold",
    sold07Bottles: "0.7L bottles sold",
    sold07BottlesEnter: "Enter 0.7L bottles sold",
    street: "Street",
    streetEnter: "Enter street",
    successfulDeleting: "Successful deleting.",
    successfulSaving: "Successful saving.",
    supervisor: "Supervisor",
    supervisorEnter: "Enter supervisor",
    supervisorNotification: "if there is no supervisor, leave it blank",
    technician: "Technician",
    technicianChoose: "Choose technician",
    technicians: "Technicians",
    techniciansNo: "Technician no.",
    to: "To",
    tolls: "Tolls, tunnel tolls, ferry",
    tollsEnter: "Enter tolls, tunnel tolls, ferry",
    totalCost: "Total for payment",
    totalRowCount: "Total row count",
    travelCost: "Travel cost",
    travelCostChoose: "Choose travel cost",
    travelCostEnter: "Enter travel cost per km",
    travelCostVan: "Van travel cost",
    travelCostVanEnter: "Enter van travel cost",
    travelFree: "Expenses per km",
    unsuccessfullyDeleting: "Unsuccessful deleting.",
    unsuccessfullySaving: "Unsuccessful saving.",
    updatedAt: "Updated at",
    upload: "Upload",
    uploadImages: "Upload images",
    uploadPersonImageDescription: "Click or drag images to this area to upload",
    uploadReportImageDescription: "Click or drag images to this area to upload",
    user: "User",
    weather: "Weather",
    weatherChoose: "Choose weather",
    weatherOptions: {
      brightly: "Brightly",
      rain: "Rain",
      snow: "Snow",
      windy: "Windy",
    },
    weatherType: "Weather type",
    weatherTypes: "Weather types",
    week: "Week",
    welcomeToPromobook: "Welcome to Promobook app",
    womenShort: "W",
    year: "Year",
    yes: "Yes",
  },
};
