/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input } from "antd";
import Select, { DefaultOptionType } from "antd/lib/select";
import React, { useEffect, useState } from "react";
import { translations } from "../../config/translations";
import { BaseViewModel } from "../../core/models/Base";
import { DrawerState } from "../../core/models/Enum";
import {
  PrincipalDetailVM,
  PrincipalFormModel
} from "../../core/models/Principal";
import {
  createPrincipal,
  getPrincipalDetails,
  updatePrincipal
} from "../../core/services/PrincipalService";
import { getRepresentativesList } from "../../core/services/RepresentativeService";
import { useAuthContext } from "../../helpers/AuthContext";
import {
  filterSelectOption,
  formGenericRules,
  formItemCrudDrawer
} from "../../helpers/FormHelper";

interface Props {
  drawerState: DrawerState;
  selectedEntityId?: number;
  wrappedComponentRef?: React.RefObject<any>;
  onClose: (
    drawerState: DrawerState,
    shouldUpdate: boolean,
    id?: number
  ) => void;
}

const PrincipalForm: React.FC<Props> = (props: Props) => {
  const { drawerState, selectedEntityId, wrappedComponentRef, onClose } = props;
  const { language, setLoading } = useAuthContext();
  const authContext = useAuthContext();
  const [selectedEntity, setSelectedEntity] = useState<
    PrincipalDetailVM | undefined
  >();
  const [representatives, setRepresentatives] = useState<DefaultOptionType[]>(
    []
  );

  useEffect(() => {
    getRepresentatives();
  }, []);

  useEffect(() => {
    if (representatives.length > 0) {
      getFormData();
    }
  }, [representatives]);

  const getRepresentatives = async () => {
    const data = await getRepresentativesList(authContext);

    if (data.length > 0) {
      setRepresentatives(
        data.map(
          (item: BaseViewModel): DefaultOptionType => ({
            value: item.id,
            label: item.name,
          })
        )
      );
    }
  };

  const getFormData = async () => {
    if (!selectedEntityId) return;

    setLoading(true);

    const result = await getPrincipalDetails(authContext, selectedEntityId);

    if (result) {
      setSelectedEntity(result);
    }

    setLoading(false);
  };

  const handleSave = async (formValues: PrincipalFormModel) => {
    setLoading(true, translations[language].saving);

    const result =
      drawerState === DrawerState.Edit && selectedEntityId
        ? await updatePrincipal(authContext, {
            id: selectedEntityId,
            name: formValues.name,
            representativeId: formValues.representative,
          })
        : await createPrincipal(authContext, {
            name: formValues.name,
            representativeId: formValues.representative,
          });

    if (result) {
      onClose(DrawerState.Closed, true, undefined);
    }

    setLoading(false);
  };

  return (
    <>
      {(drawerState === DrawerState.Create ||
        (drawerState === DrawerState.Edit && selectedEntity)) && (
        <Form
          onFinish={handleSave}
          className="form"
          {...formItemCrudDrawer}
          ref={wrappedComponentRef}
        >
          <Form.Item
            {...formGenericRules(language, true)}
            name="name"
            label={translations[language].fullName}
            initialValue={selectedEntity?.name}
          >
            <Input placeholder={translations[language].fullNameEnter} />
          </Form.Item>
          <Form.Item
            name="representative"
            label={translations[language].representative}
            initialValue={selectedEntity?.representative.id}
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              placeholder={translations[language].representativeChoose}
              options={representatives}
              filterOption={(input, option) =>
                filterSelectOption(input, option)
              }
            />
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default PrincipalForm;
