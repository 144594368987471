import { IUseAuthValues } from "../../helpers/AuthContext";
import {
  ConfigFetchVM,
  ConfigRequest,
  ConfigUpdateRequestModel,
  ConfigVM
} from "../models/Config";
import { ConfigKey } from "../models/Enum";

export const getTravelCostsList = async (
  context: IUseAuthValues,
): Promise<ConfigVM[]> => {
  const { post } = context;

  const entities = await post<ConfigFetchVM | undefined>(
    `/Config/keys/fetch-multiple`, { keys: [ConfigKey.TravelCost, ConfigKey.TravelCostVan] }, true
  );

  return entities?.configValues || [];
};

export const getTravelCostDetails = async (
  context: IUseAuthValues,
  key: ConfigKey,
): Promise<ConfigVM | undefined> => {
  const { get } = context;

  const entity = await get<ConfigVM[] | undefined>(
    `/Config/key/${key}`
  );

  return entity && entity?.length > 0 ? entity[0] : undefined;
};

export const updateTravelCosts = async (
  context: IUseAuthValues,
  updateEntities: ConfigRequest
): Promise<number | undefined> => {
  const { put } = context;

  const entity = await put<number | undefined>(
    `/Config/keys/update-multiple`,
    updateEntities
  );

  return entity;
};

export const updateTravelCost = async (
  context: IUseAuthValues,
  updateEntity: ConfigUpdateRequestModel
): Promise<number | undefined> => {
  const { put } = context;

  const entity = await put<number | undefined>(
    `/Config/${updateEntity.id}`,
    updateEntity
  );

  return entity;
};
