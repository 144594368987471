import { IUseAuthValues } from "../../helpers/AuthContext";
import {
  LocationCreateRequestModel,
  LocationDetailVM,
  LocationUpdateRequestModel,
  LocationVM
} from "../models/Location";

export const getLocationsList = async (
  context: IUseAuthValues
): Promise<LocationVM[]> => {
  const { get } = context;

  const entities = await get<LocationVM[] | undefined>(`/Location`);

  return entities || [];
};

export const getLocationDetails = async (
  context: IUseAuthValues,
  id: number
): Promise<LocationDetailVM | undefined> => {
  const { get } = context;

  const entity = await get<LocationDetailVM | undefined>(`/Location/${id}`);

  return entity;
};

export const createLocation = async (
  context: IUseAuthValues,
  newEntity: LocationCreateRequestModel
): Promise<number | undefined> => {
  const { post } = context;

  const entity = await post<number | undefined>(`/Location`, newEntity);

  return entity;
};

export const updateLocation = async (
  context: IUseAuthValues,
  updateEntity: LocationUpdateRequestModel
): Promise<number | undefined> => {
  const { put } = context;

  const entity = await put<number | undefined>(`/Location/${updateEntity.id}`, updateEntity);

  return entity;
};

export const deleteLocation = async (context: IUseAuthValues, id: number): Promise<number | undefined> => {
  const { remove } = context;

  const entity = await remove<number | undefined>(`/Location/${id}`);

  return entity;
};
