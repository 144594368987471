/* eslint-disable react-hooks/exhaustive-deps */
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Form, Input } from "antd";
import React from "react";
import { translations } from "../../config/translations";
import { SimpleDrawerState } from "../../core/models/Enum";
import { ChangePersonPasswordFormModel } from "../../core/models/Person";
import { changePersonPassword } from "../../core/services/PersonService";
import { useAuthContext } from "../../helpers/AuthContext";
import { formItemCrudDrawer } from "../../helpers/FormHelper";

interface Props {
  selectedEntityId?: number;
  wrappedComponentRef?: React.RefObject<any>;
  onClose: (drawerState: SimpleDrawerState, id?: number) => void;
}

const ChangePasswordPersonForm: React.FC<Props> = (props: Props) => {
  const { selectedEntityId, wrappedComponentRef, onClose } = props;
  const [form] = Form.useForm();
  const { language, setLoading } = useAuthContext();
  const authContext = useAuthContext();

  const handleSave = async (formValues: ChangePersonPasswordFormModel) => {
    if (!selectedEntityId) return;

    setLoading(true, translations[language].saving);

    const result = await changePersonPassword(authContext, {
      id: selectedEntityId,
      newPassword: formValues.newPassword,
      oldPassword: formValues.oldPassword,
    });

    if (result) {
      onClose(SimpleDrawerState.Closed, undefined);
    }

    setLoading(false);
  };

  return (
    <Form
      onFinish={handleSave}
      className="form"
      {...formItemCrudDrawer}
      ref={wrappedComponentRef}
      form={form}
    >
      <Form.Item
        name="oldPassword"
        label={translations[language].oldPassword}
        rules={[
          {
            required: true,
            message: translations[language].requiredField,
          },
        ]}
      >
        <Input.Password
          placeholder={translations[language].oldPasswordEnter}
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
      </Form.Item>
      <Form.Item
        name="newPassword"
        label={translations[language].password}
        rules={[
          {
            required: true,
            message: translations[language].requiredField,
          },
          {
            pattern:
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            message: translations[language].passwordValidation,
          },
        ]}
      >
        <Input.Password
          placeholder={translations[language].passwordEnter}
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
      </Form.Item>
      <Form.Item
        name="repeatedPassword"
        label={translations[language].repeatedPassword}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (getFieldValue("newPassword") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(translations[language].notSamePassword)
              );
            },
          }),
        ]}
      >
        <Input.Password
          placeholder={translations[language].repeatedPasswordEnter}
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
      </Form.Item>
    </Form>
  );
};

export default ChangePasswordPersonForm;
