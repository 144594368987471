export const defaultFormatDate = "DD.MM.YYYY.";
export const defaultFormatDateTime = "DD.MM.YYYY. - HH:mm:ss";
export const defaultFormatTime = "HH:mm";
export const defaultFormatTimeWithSeconds = "HH:mm:ss";
export const defaultFormatDateTimeWithoutSeconds = "DD.MM.YYYY. - HH:mm";
export const defaultFormatDateTimeWithoutSecondTimeFirst = "HH:mm - DD.MM.";
export const defaultFormatDateTimeWithoutSecondWithYearTimeFirst = "HH:mm - DD.MM.YYYY.";

export const DRAWER_SMALL_WIDTH = 600;
export const DRAWER_MEDIUM_WIDTH = 700;
export const DRAWER_LARGE_WIDTH = 1000;

export const dropdownStyle = { zIndex: 1030 };

export const costPerKm = 0.28;

export const hourlyRateHostess = 8.63;
export const hourlyRatePromoter = 8.63;
export const hourlyRateBartender = 8.63;
export const hourlyRateTechnician = 8.63;

export const tokenLocalStorageKey = 'accessToken';
export const returnUrlLocalStorageKey = 'returnUrl';
export const userIpAddressLocalStorageKey = 'userIpAddress';
export const configurationLocalStorageKey = 'configuration';

export const maxPersonImages = 5;
export const maxReportImages = 10;

