import { AnchorButtonProps, NativeButtonProps } from "antd/lib/button/button";

export const PageHeaderButtonSettings: Partial<
  AnchorButtonProps & NativeButtonProps
> = {
  type: "primary",
  style: {
    zIndex: 10,
    float: "right",
    marginLeft: "10px",
  },
  className: "no-print",
};
