import { IUseAuthValues } from "../../helpers/AuthContext";
import {
  BrandCreateRequestModel,
  BrandDetailVM,
  BrandUpdateRequestModel,
  BrandVM
} from "../models/Brand";

export const getBrandsList = async (
  context: IUseAuthValues
): Promise<BrandVM[]> => {
  const { get } = context;

  const entities = await get<BrandVM[] | undefined>(`/Brand`);

  return entities || [];
};

export const getBrandDetails = async (
  context: IUseAuthValues,
  id: number
): Promise<BrandDetailVM | undefined> => {
  const { get } = context;

  const entity = await get<BrandDetailVM | undefined>(`/Brand/${id}`);

  return entity;
};

export const createBrand = async (
  context: IUseAuthValues,
  newEntity: BrandCreateRequestModel
): Promise<number | undefined> => {
  const { post } = context;

  const entity = await post<number | undefined>(`/Brand`, newEntity);

  return entity;
};

export const updateBrand = async (
  context: IUseAuthValues,
  updateEntity: BrandUpdateRequestModel
): Promise<number | undefined> => {
  const { put } = context;

  const entity = await put<number | undefined>(`/Brand/${updateEntity.id}`, updateEntity);

  return entity;
};

export const deleteBrand = async (context: IUseAuthValues, id: number): Promise<number | undefined> => {
  const { remove } = context;

  const entity = await remove<number | undefined>(`/Brand/${id}`);

  return entity;
};
