/* eslint-disable react-hooks/exhaustive-deps */
import { Row } from "antd";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { translations } from "../../config/translations";
import { PersonType } from "../../core/models/Enum";
import { PayoffVM } from "../../core/models/Payoff";
import { useAuthContext } from "../../helpers/AuthContext";
import "./styles.css";

interface Props {
  rows: PayoffVM[];
  personType?: PersonType;
}

export const CalculationFooter: React.FC<Props> = (props: Props) => {
  const { language } = useAuthContext();
  const [feeSum, setFeeSum] = useState<number>(0);
  const [travelSum, setTravelSum] = useState<number>(0);
  const [toolsSum, setToolsSum] = useState<number>(0);
  const [parkingSum, setParkingSum] = useState<number>(0);
  const [accomodationSum, setAccomodationSum] = useState<number>(0);
  const [restSum, setRestSum] = useState<number>(0);
  const [masterSum, setMasterSum] = useState<number>(0);
  const { rows, personType } = props;

  useEffect(() => {
    calculateSums();
  }, [personType, rows]);

  const calculateSums = (): void => {
    if (personType === PersonType.Hostess) {
      let calcFee: number = 0;
      rows.forEach((row: PayoffVM): void => {
        calcFee += row.fee;
      });
      setFeeSum(calcFee);
    } else {
      let calcFee: number = 0;
      let calcTravel: number = 0;
      let calcTools: number = 0;
      let calcParking: number = 0;
      let calcAccomodation: number = 0;
      let calcRest: number = 0;
      let calcMaster: number = 0;
      rows.forEach((row: PayoffVM): void => {
        calcFee += row.fee;
        calcTools += row.tollsCost || 0;
        calcParking += row.parkingCost || 0;
        calcAccomodation += row.accomodationCost || 0;
        calcRest += row.otherExpensesCost || 0;
        calcTravel += row.travelCost || 0;
        calcMaster += row.totalCost || 0;
      });
      setFeeSum(calcFee);
      setToolsSum(calcTools);
      setParkingSum(calcParking);
      setAccomodationSum(calcAccomodation);
      setRestSum(calcRest);
      setTravelSum(calcTravel);
      setMasterSum(calcMaster);
    }
  };

  return (
    <Row style={{ display: "flow-root" }}>
      <div style={{ float: "right", display: "flex" }}>
        <div className="calculation-column-total">
          {translations[language].inTotal.toUpperCase()}:
        </div>
        {isMobile ? (
          <div className="calculation-column">{`${masterSum.toFixed(
            2
          )} €`}</div>
        ) : (
          <>
            <div className="calculation-column">{`${feeSum.toFixed(2)} €`}</div>
            {personType !== PersonType.Hostess && (
              <>
                <div className="calculation-column">{`${travelSum.toFixed(
                  2
                )} €`}</div>
                <div className="calculation-column">{`${toolsSum.toFixed(
                  2
                )} €`}</div>
                <div className="calculation-column">{`${parkingSum.toFixed(
                  2
                )} €`}</div>
                <div className="calculation-column">{`${accomodationSum.toFixed(
                  2
                )} €`}</div>
                <div className="calculation-column">{`${restSum.toFixed(
                  2
                )} €`}</div>
                <div className="calculation-column">{`${masterSum.toFixed(
                  2
                )} €`}</div>
              </>
            )}
          </>
        )}
      </div>
    </Row>
  );
};
