/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, Form, Input, InputNumber, Radio } from "antd";
import Select, { DefaultOptionType } from "antd/lib/select";
import React, { ReactElement, useEffect, useState } from "react";
import { costPerKm } from "../../config/constants";
import { translations } from "../../config/translations";
import { BaseViewModel } from "../../core/models/Base";
import { CustomFields } from "../../core/models/Brand";
import { CostFormModel } from "../../core/models/Cost";
import { DrawerState, UserRole } from "../../core/models/Enum";
import { PersonFeeRequestModel, PersonFeeVM } from "../../core/models/Order";
import {
  QuestionsReportFormModel,
  ReportDetailVM,
  ReportFormModel,
} from "../../core/models/Report";
import { SignatureDrinkVM } from "../../core/models/SignatureDrink";
import { getAgeGroupList } from "../../core/services/AgeGroupService";
import { getLocationFullnessList } from "../../core/services/LocationFullnessService";
import { getMailsList } from "../../core/services/MailService";
import { getMenWomenRatiosList } from "../../core/services/MenWomenRatioService";
import { getMusicTypesList } from "../../core/services/MusicTypeService";
import {
  getReportDetails,
  updateReportAdmin,
  updateReportParticipant,
  updateReportPromoter,
} from "../../core/services/ReportService";
import { getSignatureDrinksList } from "../../core/services/SignatureDrinkService";
import { getWeatherTypesList } from "../../core/services/WeatherTypeService";
import { useAuthContext } from "../../helpers/AuthContext";
import {
  filterSelectOption,
  formGenericRules,
  formInputNumberRules,
  formItemLayoutDrawer10_14,
  inputNumberGenericFormatter,
} from "../../helpers/FormHelper";
import { isAdmin, isAdminOrPromoter } from "../../helpers/PersonHelper";
import OrderDetailsView from "./OrderDetailsView";

interface Props {
  drawerState: DrawerState;
  selectedEntityId?: number;
  wrappedComponentRef?: React.RefObject<any>;
  onClose: (
    drawerState: DrawerState,
    shouldUpdate: boolean,
    id?: number
  ) => void;
}

const ReportForm: React.FC<Props> = (props: Props) => {
  const { drawerState, selectedEntityId, wrappedComponentRef, onClose } = props;
  const [form] = Form.useForm();
  const { language, userRole, userPersonId, setLoading } = useAuthContext();
  const authContext = useAuthContext();
  const [selectedEntity, setSelectedEntity] = useState<
    ReportDetailVM | undefined
  >();
  const [weathers, setWeathers] = useState<DefaultOptionType[]>([]);
  const [musicTypes, setMusicTypes] = useState<DefaultOptionType[]>([]);
  const [signatureDrinks, setSignatureDrinks] = useState<DefaultOptionType[]>(
    []
  );
  const [locationFullnesses, setLocationFullnesses] = useState<
    DefaultOptionType[]
  >([]);
  const [menWomenRatios, setMenWomenRatios] = useState<DefaultOptionType[]>([]);
  const [ageGroups, setAgeGroups] = useState<DefaultOptionType[]>([]);
  const [mails, setMails] = useState<DefaultOptionType[]>([]);
  const [additionalQuestions, setAdditionalQuestions] = useState<
    ReactElement[]
  >([]);

  useEffect(() => {
    getMusicTypes();
    getWeathers();
    getLocationFullnesses();
    getMenWomenRatios();
    getAgeGroups();
    getMails();
  }, []);

  useEffect(() => {
    if (
      musicTypes.length > 0 &&
      weathers.length > 0 &&
      locationFullnesses.length > 0 &&
      menWomenRatios.length > 0 &&
      ageGroups.length > 0 &&
      mails.length > 0
    ) {
      getFormData();
    }
  }, [
    musicTypes,
    weathers,
    locationFullnesses,
    menWomenRatios,
    ageGroups,
    mails,
  ]);

  useEffect(() => {
    getSignatureDrinks();
    mapCustomFieldsData();
  }, [selectedEntity]);

  const getMails = async () => {
    const data = await getMailsList(authContext);

    if (data.length > 0) {
      setMails(
        data.map(
          (item: BaseViewModel): DefaultOptionType => ({
            value: item.id,
            label: item.name,
          })
        )
      );
    }
  };

  const getMusicTypes = async () => {
    const data = await getMusicTypesList(authContext);

    if (data.length > 0) {
      setMusicTypes(
        data.map(
          (item: BaseViewModel): DefaultOptionType => ({
            value: item.id,
            label: item.name,
          })
        )
      );
    }
  };

  const getWeathers = async () => {
    const data = await getWeatherTypesList(authContext);

    if (data.length > 0) {
      setWeathers(
        data.map(
          (item: BaseViewModel): DefaultOptionType => ({
            value: item.id,
            label: item.name,
          })
        )
      );
    }
  };

  const getLocationFullnesses = async () => {
    const data = await getLocationFullnessList();
    if (data.length > 0) {
      setLocationFullnesses(
        data.map(
          (item: BaseViewModel): DefaultOptionType => ({
            value: item.id,
            label: item.name,
          })
        )
      );
    }
  };

  const getSignatureDrinks = async () => {
    const data = await getSignatureDrinksList(authContext);
    if (data.length > 0) {
      const filteredSignatureDrinkOptions = selectedEntity
        ? data.filter(
            (val: SignatureDrinkVM): boolean =>
              val.brand.id === selectedEntity.brand.id
          )
        : data;
      setSignatureDrinks(
        filteredSignatureDrinkOptions.map(
          (item: SignatureDrinkVM): DefaultOptionType => ({
            value: item.id,
            label: item.name,
          })
        )
      );
    }
  };

  const getMenWomenRatios = async () => {
    const data = await getMenWomenRatiosList(language);
    if (data.length > 0) {
      setMenWomenRatios(
        data.map(
          (item: BaseViewModel): DefaultOptionType => ({
            value: item.id,
            label: item.name,
          })
        )
      );
    }
  };

  const getAgeGroups = async () => {
    const data = await getAgeGroupList();
    if (data.length > 0) {
      setAgeGroups(
        data.map(
          (item: BaseViewModel): DefaultOptionType => ({
            value: item.id,
            label: item.name,
          })
        )
      );
    }
  };

  const getFormData = async () => {
    if (!selectedEntityId) return;

    setLoading(true);

    const result = await getReportDetails(authContext, selectedEntityId);

    if (result) {
      setSelectedEntity(result);
    }

    setLoading(false);
  };

  const mapCustomFieldsData = async (): Promise<void> => {
    if (selectedEntity?.brand?.questions) {
      const questions: CustomFields[] = JSON.parse(
        selectedEntity?.brand.questions
      );
      const mappedQuestions: ReactElement[] = [];

      questions.forEach((question: CustomFields): void => {
        const defValues: QuestionsReportFormModel[] = selectedEntity?.questions
          ? JSON.parse(selectedEntity.questions)
          : [];
        const selectedDefValue = defValues.find(
          (defValue: QuestionsReportFormModel): boolean =>
            defValue.key === question.key
        );

        mappedQuestions.push(
          <Form.Item
            {...formGenericRules(language, true)}
            name={question.key}
            label={
              <div dangerouslySetInnerHTML={{ __html: question.question }} />
            }
            initialValue={selectedDefValue?.answer}
          >
            <Radio.Group>
              <Radio value={true}>{translations[language].yes}</Radio>
              <Radio value={false}>{translations[language].no}</Radio>
            </Radio.Group>
          </Form.Item>
        );
      });

      setAdditionalQuestions(mappedQuestions);
    } else {
      setAdditionalQuestions([]);
    }
  };

  const mapCustomFieldsDataAnswers = (
    formValues: ReportFormModel
  ): string | undefined => {
    if (!selectedEntity?.brand?.questions) return undefined;

    const questions: CustomFields[] = JSON.parse(
      selectedEntity?.brand?.questions
    );
    const keys = questions.map(
      (question: CustomFields): string => question.key
    );

    const answers = keys.map(
      (key: string): QuestionsReportFormModel => ({
        key,
        answer: (formValues as any)[key],
      })
    );

    return JSON.stringify(answers);
  };

  const handleSave = async (formValues: ReportFormModel) => {
    if (!selectedEntity) return;

    setLoading(true, translations[language].saving);

    let result;

    if (isAdmin(userRole)) {
      result = await updateReportAdmin(authContext, {
        ...formValues,
        orderId: selectedEntity.id,
        mails: formValues.mails || [],
        hostesses: selectedEntity.hostesses?.map(
          (person: PersonFeeVM): PersonFeeRequestModel => ({
            participantId: person.participantId,
            personId: person.person?.id,
            fee: person.fee,
          })
        ),
        promoter:
          formValues.promoters && formValues.promoters?.length > 0
            ? {
                participantId: formValues.promoters[0].participantId,
                personId: formValues.promoters[0].personId,
                fee: formValues.promoters[0].fee,
                cost: {
                  costId: formValues.promoters[0].costId,
                  participantId: formValues.promoters[0].participantId,
                  pricePerKm: formValues.promoters[0].pricePerKm,
                  mileage: formValues.promoters[0].mileage,
                  mileagePrice: formValues.promoters[0].mileagePrice,
                  tolls: formValues.promoters[0].tolls,
                  parking: formValues.promoters[0].parking,
                  accomodation: formValues.promoters[0].accomodation,
                  otherExpenses: formValues.promoters[0].otherExpenses,
                  totalCost: formValues.promoters[0].totalCost,
                },
              }
            : undefined,
        bartenders: formValues.bartenders?.map(
          (person: CostFormModel): PersonFeeRequestModel => ({
            participantId: person.participantId,
            personId: person.personId,
            fee: person.fee,
            cost: {
              costId: person.costId,
              participantId: person.participantId,
              pricePerKm: person.pricePerKm,
              mileage: person.mileage,
              mileagePrice: person.mileagePrice,
              tolls: person.tolls,
              parking: person.parking,
              accomodation: person.accomodation,
              otherExpenses: person.otherExpenses,
              totalCost: person.totalCost,
            },
          })
        ),
        technicians: formValues.technicians?.map(
          (person: CostFormModel): PersonFeeRequestModel => ({
            participantId: person.participantId,
            personId: person.personId,
            fee: person.fee,
            cost: {
              costId: person.costId,
              participantId: person.participantId,
              pricePerKm: person.pricePerKm,
              mileage: person.mileage,
              mileagePrice: person.mileagePrice,
              tolls: person.tolls,
              parking: person.parking,
              accomodation: person.accomodation,
              otherExpenses: person.otherExpenses,
              totalCost: person.totalCost,
            },
          })
        ),
        questions: mapCustomFieldsDataAnswers(formValues),
      });
    } else if (userRole === UserRole.Promoter) {
      result = await updateReportPromoter(authContext, {
        ...formValues,
        orderId: selectedEntity.id,
        mails: formValues.mails || [],
        promoter:
          formValues.promoters && formValues.promoters?.length > 0
            ? {
                participantId: formValues.promoters[0].participantId,
                personId: formValues.promoters[0].personId,
                fee: formValues.promoters[0].fee,
                cost: {
                  costId: formValues.promoters[0].costId,
                  participantId: formValues.promoters[0].participantId,
                  pricePerKm: formValues.promoters[0].pricePerKm,
                  mileage: formValues.promoters[0].mileage,
                  mileagePrice: formValues.promoters[0].mileagePrice,
                  tolls: formValues.promoters[0].tolls,
                  parking: formValues.promoters[0].parking,
                  accomodation: formValues.promoters[0].accomodation,
                  otherExpenses: formValues.promoters[0].otherExpenses,
                  totalCost: formValues.promoters[0].totalCost,
                },
              }
            : undefined,
        questions: mapCustomFieldsDataAnswers(formValues),
      });
    } else if (userRole === UserRole.Bartender) {
      const realSelectedPerson = formValues.bartenders?.find(
        (bartender: CostFormModel): boolean =>
          bartender.personId === userPersonId
      );

      if (!realSelectedPerson) return;

      result = await updateReportParticipant(authContext, selectedEntity.id, {
        participantId: realSelectedPerson.participantId,
        personId: realSelectedPerson.personId,
        fee: realSelectedPerson.fee,
        cost: {
          pricePerKm: realSelectedPerson.pricePerKm,
          costId: realSelectedPerson.costId,
          participantId: realSelectedPerson.participantId,
          mileage: realSelectedPerson.mileage,
          mileagePrice: realSelectedPerson.mileagePrice,
          tolls: realSelectedPerson.tolls,
          parking: realSelectedPerson.parking,
          accomodation: realSelectedPerson.accomodation,
          otherExpenses: realSelectedPerson.otherExpenses,
          totalCost: realSelectedPerson.totalCost,
        },
      });
    } else if (userRole === UserRole.Technician) {
      const realSelectedPerson = formValues.technicians?.find(
        (technician: CostFormModel): boolean =>
          technician.personId === userPersonId
      );

      if (!realSelectedPerson) return;

      result = await updateReportParticipant(authContext, selectedEntity.id, {
        participantId: realSelectedPerson.participantId,
        personId: realSelectedPerson.personId,
        fee: realSelectedPerson.fee,
        cost: {
          pricePerKm: realSelectedPerson.pricePerKm,
          costId: realSelectedPerson.costId,
          participantId: realSelectedPerson.participantId,
          mileage: realSelectedPerson.mileage,
          mileagePrice: realSelectedPerson.mileagePrice,
          tolls: realSelectedPerson.tolls,
          parking: realSelectedPerson.parking,
          accomodation: realSelectedPerson.accomodation,
          otherExpenses: realSelectedPerson.otherExpenses,
          totalCost: realSelectedPerson.totalCost,
        },
      });
    }

    if (result) {
      onClose(DrawerState.Closed, true, undefined);
    }

    setLoading(false);
  };

  const getTravelCostTechnicians = (index: number): number => {
    const technician =
      selectedEntity?.technicians && selectedEntity.technicians[index];
    if (technician && technician.cost) {
      return technician.cost.pricePerKm;
    } else {
      return costPerKm;
    }
  };

  const getTravelCostBartenders = (index: number): number => {
    const bartender =
      selectedEntity?.bartenders && selectedEntity.bartenders[index];
    if (bartender && bartender.cost) {
      return bartender.cost.pricePerKm;
    } else {
      return costPerKm;
    }
  };

  return (
    <>
      {(drawerState === DrawerState.Create ||
        (drawerState === DrawerState.Edit && selectedEntity)) && (
        <Form
          onFinish={handleSave}
          className="form"
          {...formItemLayoutDrawer10_14}
          ref={wrappedComponentRef}
          form={form}
        >
          <Divider plain>{translations[language].order}</Divider>
          {selectedEntity && (
            <OrderDetailsView selectedEntity={selectedEntity} />
          )}
          {isAdminOrPromoter(userRole) && (
            <>
              <Divider plain>{translations[language].report}</Divider>
              <span className="notification-text">
                {translations[language].reportNotificationInfo}
              </span>
              <Form.Item
                name="price"
                label={
                  <span>
                    {translations[language].price}
                    <span className="red-text-in-label">
                      ({translations[language].forSmallGlass})
                    </span>
                  </span>
                }
                initialValue={selectedEntity?.price || 0}
              >
                <InputNumber
                  min={0}
                  placeholder={translations[language].priceEnter}
                  addonAfter={<span>€</span>}
                />
              </Form.Item>
              <Form.Item
                name="promoPrice"
                label={
                  <span>
                    {translations[language].promotionalPrice}
                    <span className="red-text-in-label">
                      ({translations[language].forSmallGlass})
                    </span>
                  </span>
                }
                initialValue={selectedEntity?.promoPrice || 0}
              >
                <InputNumber
                  min={0}
                  placeholder={translations[language].promotionalPriceEnter}
                  addonAfter={<span>€</span>}
                />
              </Form.Item>
              {additionalQuestions}
              <Form.Item
                name="weather"
                label={translations[language].weather}
                initialValue={selectedEntity?.weather?.id}
              >
                <Select
                  showSearch
                  placeholder={translations[language].weatherChoose}
                  options={weathers}
                  filterOption={(input, option) =>
                    filterSelectOption(input, option)
                  }
                />
              </Form.Item>
              <Form.Item
                name="musicType"
                label={translations[language].musicType}
                initialValue={selectedEntity?.musicType?.id}
              >
                <Select
                  showSearch
                  placeholder={translations[language].musicTypeChoose}
                  options={musicTypes}
                  filterOption={(input, option) =>
                    filterSelectOption(input, option)
                  }
                />
              </Form.Item>
              <Form.Item
                name="ageGroup"
                label={translations[language].ageGroup}
                initialValue={selectedEntity?.ageGroup}
              >
                <Select
                  showSearch
                  placeholder={translations[language].ageGroupChoose}
                  options={ageGroups}
                  filterOption={(input, option) =>
                    filterSelectOption(input, option)
                  }
                />
              </Form.Item>
              <Form.Item
                name="attendeesNumber"
                label={translations[language].attendeesNumber}
                initialValue={selectedEntity?.attendeesNumber || 0}
              >
                <InputNumber
                  min={0}
                  placeholder={translations[language].attendeesNumberEnter}
                />
              </Form.Item>
              <Form.Item
                name="locationCapacity"
                label={translations[language].locationCapacity}
                initialValue={selectedEntity?.locationCapacity || 0}
              >
                <InputNumber
                  min={0}
                  placeholder={translations[language].locationCapacityEnter}
                />
              </Form.Item>
              <Form.Item
                name="locationFulness"
                label={translations[language].locationFullness}
                initialValue={selectedEntity?.locationFulness}
              >
                <Select
                  showSearch
                  placeholder={translations[language].locationFullnessChoose}
                  options={locationFullnesses}
                  filterOption={(input, option) =>
                    filterSelectOption(input, option)
                  }
                />
              </Form.Item>
              <Form.Item
                name="sexRatio"
                label={translations[language].menWomenRatio}
                initialValue={selectedEntity?.menWomenRatio}
              >
                <Select
                  showSearch
                  placeholder={translations[language].menWomenRatioChoose}
                  options={menWomenRatios}
                  filterOption={(input, option) =>
                    filterSelectOption(input, option)
                  }
                />
              </Form.Item>
              <Form.Item
                name="competitionDrink"
                label={translations[language].competitionBrand}
                initialValue={selectedEntity?.competitionDrink}
              >
                <Input
                  placeholder={translations[language].competitionBrandEnter}
                />
              </Form.Item>
              <Form.Item
                name="smallBottlesCount"
                label={translations[language].sold07Bottles}
                initialValue={selectedEntity?.smallBottlesCount || 0}
              >
                <InputNumber
                  min={0}
                  placeholder={translations[language].sold07BottlesEnter}
                  onChange={(value: number | null): void => {
                    const otherValue = form.getFieldValue("largeBottlesCount");
                    form.setFieldValue(
                      "cumulativeSold",
                      (otherValue
                        ? form.getFieldValue("largeBottlesCount") / 0.7
                        : 0) +
                        (value !== null && value !== undefined ? value : 0)
                    );
                  }}
                />
              </Form.Item>
              <Form.Item
                name="largeBottlesCount"
                label={translations[language].sold1Bottles}
                initialValue={selectedEntity?.largeBottlesCount || 0}
              >
                <InputNumber
                  min={0}
                  placeholder={translations[language].sold1BottlesEnter}
                  onChange={(value: number | null): void => {
                    form.setFieldValue(
                      "cumulativeSold",
                      (value !== null && value !== undefined ? value : 0) /
                        0.7 +
                        form.getFieldValue("smallBottlesCount")
                    );
                  }}
                />
              </Form.Item>
              <Form.Item
                name="cumulativeSold"
                label={translations[language].cumulative07Sold}
                initialValue={
                  (selectedEntity?.largeBottlesCount
                    ? selectedEntity?.largeBottlesCount / 0.7
                    : 0) + (selectedEntity?.smallBottlesCount || 0)
                }
              >
                <InputNumber {...inputNumberGenericFormatter()} disabled />
              </Form.Item>
              <Form.Item
                name="signatureDrinks"
                label={
                  <span>
                    {translations[language].signatureDrinks}
                    <span className="red-text-in-label">
                      ({translations[language].onlyOneSignatureDrink})
                    </span>
                  </span>
                }
                initialValue={
                  selectedEntity?.signatureDrinks?.map(
                    (signatureDrink: BaseViewModel): number => signatureDrink.id
                  ) || []
                }
              >
                <Select
                  showSearch
                  mode="multiple"
                  placeholder={translations[language].signatureDrinkChoose}
                  options={signatureDrinks}
                  filterOption={(input, option) =>
                    filterSelectOption(input, option)
                  }
                />
              </Form.Item>
              <Form.Item
                name="promotionRatingOwner"
                label={translations[language].promotionRatingOwner}
                initialValue={selectedEntity?.promotionRatingOwner}
              >
                <Radio.Group>
                  <Radio value={1}>1</Radio>
                  <Radio value={2}>2</Radio>
                  <Radio value={3}>3</Radio>
                  <Radio value={4}>4</Radio>
                  <Radio value={5}>5</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="promotionRatingPromoter"
                label={translations[language].promotionRatingPromoter}
                initialValue={selectedEntity?.promotionRatingPromoter}
              >
                <Radio.Group>
                  <Radio value={1}>1</Radio>
                  <Radio value={2}>2</Radio>
                  <Radio value={3}>3</Radio>
                  <Radio value={4}>4</Radio>
                  <Radio value={5}>5</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="supervisor"
                label={
                  <span>
                    {translations[language].supervisor}
                    <span className="red-text-in-label">
                      ({translations[language].supervisorNotification})
                    </span>
                  </span>
                }
                initialValue={selectedEntity?.supervisor}
              >
                <Input placeholder={translations[language].supervisorEnter} />
              </Form.Item>
              <Form.Item
                name="observations"
                label={translations[language].observations}
                initialValue={selectedEntity?.observations}
              >
                <Input.TextArea
                  placeholder={translations[language].observationsEnter}
                />
              </Form.Item>
            </>
          )}
          {isAdminOrPromoter(userRole) && (
            <Form.List
              name="promoters"
              key="promoters-costs-list"
              initialValue={
                selectedEntity?.promoter
                  ? [
                      {
                        personId: selectedEntity.promoter.person.id,
                        fee: selectedEntity.promoter.fee || 0,
                        participantId: selectedEntity.promoter.participantId,
                        mileage: selectedEntity.promoter.cost?.mileage || 0,
                        mileagePrice:
                          selectedEntity.promoter.cost?.mileagePrice || 0,
                        costId: selectedEntity.promoter.cost?.id,
                        tolls: selectedEntity.promoter.cost?.tolls || 0,
                        parking: selectedEntity.promoter.cost?.parking || 0,
                        accomodation:
                          selectedEntity.promoter.cost?.accomodation || 0,
                        otherExpenses:
                          selectedEntity.promoter.cost?.otherExpenses || 0,
                        totalCost: selectedEntity.promoter.cost?.totalCost || 0,
                        pricePerKm:
                          selectedEntity.promoter.cost?.pricePerKm || 0,
                      },
                    ]
                  : []
              }
            >
              {(fields) => (
                <>
                  {fields.map(({ key, name, ...restField }, index: number) => (
                    <>
                      <Divider plain>{`${translations[language].costs} - ${
                        translations[language].promoter
                      } ${
                        selectedEntity?.promoter
                          ? `(${selectedEntity?.promoter.person.firstName} ${selectedEntity?.promoter.person.lastName})`
                          : ""
                      }`}</Divider>
                      <Form.Item
                        {...restField}
                        {...formInputNumberRules(language)}
                        name={[name, "mileage"]}
                        label={translations[language].mileage}
                      >
                        <InputNumber
                          min={0}
                          placeholder={translations[language].mileageEnter}
                          onChange={(value: number | null): void => {
                            const formValues = form.getFieldsValue();
                            const { promoters } = formValues;
                            const mileagePrice =
                              (value !== null && value !== undefined
                                ? value
                                : 0) *
                              (promoters[index]["pricePerKm"] || costPerKm);
                            const totalCost =
                              (promoters[index]["fee"] || 0) +
                              mileagePrice +
                              (promoters[index]["tolls"] || 0) +
                              (promoters[index]["parking"] || 0) +
                              (promoters[index]["accomodation"] || 0) +
                              (promoters[index]["otherExpenses"] || 0);

                            Object.assign(promoters[index], {
                              mileagePrice: parseFloat(mileagePrice.toFixed(2)),
                              totalCost: parseFloat(totalCost.toFixed(2)),
                            });
                            form.setFieldsValue({ promoters });
                          }}
                          addonAfter={<span>km</span>}
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "mileagePrice"]}
                        label={`${translations[language].fuelCost} (km * ${(
                          selectedEntity?.promoter?.cost?.pricePerKm ??
                          costPerKm
                        ).toFixed(2)})`}
                      >
                        <InputNumber
                          {...inputNumberGenericFormatter()}
                          disabled
                          addonAfter={<span>€</span>}
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        {...formInputNumberRules(language)}
                        name={[name, "tolls"]}
                        label={translations[language].tolls}
                      >
                        <InputNumber
                          min={0}
                          placeholder={translations[language].tollsEnter}
                          onChange={(value: number | null): void => {
                            const formValues = form.getFieldsValue();
                            const { promoters } = formValues;
                            Object.assign(promoters[index], {
                              tolls:
                                value !== null && value !== undefined
                                  ? value
                                  : 0,
                              totalCost:
                                (promoters[index]["fee"] || 0) +
                                (value !== null && value !== undefined
                                  ? value
                                  : 0) +
                                (promoters[index]["mileagePrice"] || 0) +
                                (promoters[index]["parking"] || 0) +
                                (promoters[index]["accomodation"] || 0) +
                                (promoters[index]["otherExpenses"] || 0),
                            });
                            form.setFieldsValue({ promoters });
                          }}
                          addonAfter={<span>€</span>}
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        {...formInputNumberRules(language)}
                        name={[name, "parking"]}
                        label={translations[language].parking}
                      >
                        <InputNumber
                          min={0}
                          placeholder={translations[language].parkingEnter}
                          onChange={(value: number | null): void => {
                            const formValues = form.getFieldsValue();
                            const { promoters } = formValues;
                            Object.assign(promoters[index], {
                              parking:
                                value !== null && value !== undefined
                                  ? value
                                  : 0,
                              totalCost:
                                (promoters[index]["fee"] || 0) +
                                (value !== null && value !== undefined
                                  ? value
                                  : 0) +
                                (promoters[index]["mileagePrice"] || 0) +
                                (promoters[index]["tolls"] || 0) +
                                (promoters[index]["accomodation"] || 0) +
                                (promoters[index]["otherExpenses"] || 0),
                            });
                            form.setFieldsValue({ promoters });
                          }}
                          addonAfter={<span>€</span>}
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        {...formInputNumberRules(language)}
                        name={[name, "accomodation"]}
                        label={translations[language].overnight}
                      >
                        <InputNumber
                          min={0}
                          placeholder={translations[language].overnightEnter}
                          onChange={(value: number | null): void => {
                            const formValues = form.getFieldsValue();
                            const { promoters } = formValues;
                            Object.assign(promoters[index], {
                              accomodation:
                                value !== null && value !== undefined
                                  ? value
                                  : 0,
                              totalCost:
                                (promoters[index]["fee"] || 0) +
                                (value !== null && value !== undefined
                                  ? value
                                  : 0) +
                                (promoters[index]["mileagePrice"] || 0) +
                                (promoters[index]["tolls"] || 0) +
                                (promoters[index]["parking"] || 0) +
                                (promoters[index]["otherExpenses"] || 0),
                            });
                            form.setFieldsValue({ promoters });
                          }}
                          addonAfter={<span>€</span>}
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        {...formInputNumberRules(language)}
                        name={[name, "otherExpenses"]}
                        label={translations[language].rest}
                      >
                        <InputNumber
                          min={0}
                          placeholder={translations[language].restEnter}
                          onChange={(value: number | null): void => {
                            const formValues = form.getFieldsValue();
                            const { promoters } = formValues;
                            Object.assign(promoters[index], {
                              otherExpenses:
                                value !== null && value !== undefined
                                  ? value
                                  : 0,
                              totalCost:
                                (promoters[index]["fee"] || 0) +
                                (value !== null && value !== undefined
                                  ? value
                                  : 0) +
                                (promoters[index]["mileagePrice"] || 0) +
                                (promoters[index]["tolls"] || 0) +
                                (promoters[index]["parking"] || 0) +
                                (promoters[index]["accomodation"] || 0),
                            });
                            form.setFieldsValue({ promoters });
                          }}
                          addonAfter={<span>€</span>}
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "totalCost"]}
                        label={translations[language].totalCost.toUpperCase()}
                      >
                        <InputNumber
                          {...inputNumberGenericFormatter()}
                          min={0}
                          disabled
                          addonAfter={<span>€</span>}
                        />
                      </Form.Item>
                    </>
                  ))}
                </>
              )}
            </Form.List>
          )}
          {(userRole === UserRole.Technician || isAdmin(userRole)) && (
            <Form.List
              name="technicians"
              key="technicians-costs-list"
              initialValue={
                selectedEntity?.technicians
                  ? selectedEntity.technicians.map((s: PersonFeeVM) => ({
                      personId: s.person.id,
                      fee: s.fee || 0,
                      participantId: s.participantId,
                      mileage: s.cost?.mileage || 0,
                      mileagePrice: s.cost?.mileagePrice || 0,
                      costId: s.cost?.id,
                      tolls: s.cost?.tolls || 0,
                      parking: s.cost?.parking || 0,
                      accomodation: s.cost?.accomodation || 0,
                      otherExpenses: s.cost?.otherExpenses || 0,
                      totalCost: s.cost?.totalCost || 0,
                      pricePerKm: s.cost?.pricePerKm || 0,
                    }))
                  : []
              }
            >
              {(fields) => (
                <>
                  {fields.map(({ key, name, ...restField }, index: number) => (
                    <>
                      {" "}
                      {((selectedEntity?.technicians &&
                        selectedEntity.technicians[index]?.person?.id ===
                          userPersonId) ||
                        isAdmin(userRole)) && (
                        <>
                          <Divider plain>{`${translations[language].costs} - ${
                            translations[language].technician
                          } ${
                            selectedEntity?.technicians
                              ? `(${selectedEntity?.technicians[index].person.firstName} ${selectedEntity?.technicians[index].person.lastName})`
                              : ""
                          }`}</Divider>
                          <Form.Item
                            {...restField}
                            {...formInputNumberRules(language)}
                            name={[name, "mileage"]}
                            label={translations[language].mileage}
                          >
                            <InputNumber
                              min={0}
                              placeholder={translations[language].mileageEnter}
                              onChange={(value: number | null): void => {
                                const formValues = form.getFieldsValue();
                                const { technicians } = formValues;
                                const mileagePrice =
                                  (value !== null && value !== undefined
                                    ? value
                                    : 0) *
                                  (technicians[index]["pricePerKm"] ||
                                    costPerKm);
                                const totalCost =
                                  (technicians[index]["fee"] || 0) +
                                  mileagePrice +
                                  (technicians[index]["tolls"] || 0) +
                                  (technicians[index]["parking"] || 0) +
                                  (technicians[index]["accomodation"] || 0) +
                                  (technicians[index]["otherExpenses"] || 0);

                                Object.assign(technicians[index], {
                                  mileagePrice: parseFloat(
                                    mileagePrice.toFixed(2)
                                  ),
                                  totalCost: parseFloat(totalCost.toFixed(2)),
                                });
                                form.setFieldsValue({ technicians });
                              }}
                              addonAfter={<span>km</span>}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "mileagePrice"]}
                            label={`${translations[language].fuelCost} (km * ${(
                              getTravelCostTechnicians(index) || costPerKm
                            ).toFixed(2)})`}
                          >
                            <InputNumber
                              {...inputNumberGenericFormatter()}
                              disabled
                              addonAfter={<span>€</span>}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            {...formInputNumberRules(language)}
                            name={[name, "tolls"]}
                            label={translations[language].tolls}
                          >
                            <InputNumber
                              min={0}
                              placeholder={translations[language].tollsEnter}
                              onChange={(value: number | null): void => {
                                const formValues = form.getFieldsValue();
                                const { technicians } = formValues;
                                Object.assign(technicians[index], {
                                  tolls:
                                    value !== null && value !== undefined
                                      ? value
                                      : 0,
                                  totalCost:
                                    (technicians[index]["fee"] || 0) +
                                    (value !== null && value !== undefined
                                      ? value
                                      : 0) +
                                    (technicians[index]["mileagePrice"] || 0) +
                                    (technicians[index]["parking"] || 0) +
                                    (technicians[index]["accomodation"] || 0) +
                                    (technicians[index]["otherExpenses"] || 0),
                                });
                                form.setFieldsValue({ technicians });
                              }}
                              addonAfter={<span>€</span>}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            {...formInputNumberRules(language)}
                            name={[name, "parking"]}
                            label={translations[language].parking}
                          >
                            <InputNumber
                              min={0}
                              placeholder={translations[language].parkingEnter}
                              onChange={(value: number | null): void => {
                                const formValues = form.getFieldsValue();
                                const { technicians } = formValues;
                                Object.assign(technicians[index], {
                                  parking:
                                    value !== null && value !== undefined
                                      ? value
                                      : 0,
                                  totalCost:
                                    (technicians[index]["fee"] || 0) +
                                    (value !== null && value !== undefined
                                      ? value
                                      : 0) +
                                    (technicians[index]["mileagePrice"] || 0) +
                                    (technicians[index]["tolls"] || 0) +
                                    (technicians[index]["accomodation"] || 0) +
                                    (technicians[index]["otherExpenses"] || 0),
                                });
                                form.setFieldsValue({ technicians });
                              }}
                              addonAfter={<span>€</span>}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            {...formInputNumberRules(language)}
                            name={[name, "accomodation"]}
                            label={translations[language].overnight}
                          >
                            <InputNumber
                              min={0}
                              placeholder={
                                translations[language].overnightEnter
                              }
                              onChange={(value: number | null): void => {
                                const formValues = form.getFieldsValue();
                                const { technicians } = formValues;
                                Object.assign(technicians[index], {
                                  accomodation:
                                    value !== null && value !== undefined
                                      ? value
                                      : 0,
                                  totalCost:
                                    (technicians[index]["fee"] || 0) +
                                    (value !== null && value !== undefined
                                      ? value
                                      : 0) +
                                    (technicians[index]["mileagePrice"] || 0) +
                                    (technicians[index]["tolls"] || 0) +
                                    (technicians[index]["parking"] || 0) +
                                    (technicians[index]["otherExpenses"] || 0),
                                });
                                form.setFieldsValue({ technicians });
                              }}
                              addonAfter={<span>€</span>}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            {...formInputNumberRules(language)}
                            name={[name, "otherExpenses"]}
                            label={translations[language].rest}
                          >
                            <InputNumber
                              min={0}
                              placeholder={translations[language].restEnter}
                              onChange={(value: number | null): void => {
                                const formValues = form.getFieldsValue();
                                const { technicians } = formValues;
                                Object.assign(technicians[index], {
                                  otherExpenses:
                                    value !== null && value !== undefined
                                      ? value
                                      : 0,
                                  totalCost:
                                    (technicians[index]["fee"] || 0) +
                                    (value !== null && value !== undefined
                                      ? value
                                      : 0) +
                                    (technicians[index]["mileagePrice"] || 0) +
                                    (technicians[index]["tolls"] || 0) +
                                    (technicians[index]["parking"] || 0) +
                                    (technicians[index]["accomodation"] || 0),
                                });
                                form.setFieldsValue({ technicians });
                              }}
                              addonAfter={<span>€</span>}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "totalCost"]}
                            label={translations[
                              language
                            ].totalCost.toUpperCase()}
                          >
                            <InputNumber
                              {...inputNumberGenericFormatter()}
                              min={0}
                              disabled
                              addonAfter={<span>€</span>}
                            />
                          </Form.Item>
                        </>
                      )}
                    </>
                  ))}
                </>
              )}
            </Form.List>
          )}
          {(userRole === UserRole.Bartender || isAdmin(userRole)) && (
            <Form.List
              name="bartenders"
              key="bartenders-costs-list"
              initialValue={
                selectedEntity?.bartenders
                  ? selectedEntity.bartenders.map((s: PersonFeeVM) => ({
                      personId: s.person.id,
                      fee: s.fee || 0,
                      participantId: s.participantId,
                      mileage: s.cost?.mileage || 0,
                      mileagePrice: s.cost?.mileagePrice || 0,
                      costId: s.cost?.id,
                      tolls: s.cost?.tolls || 0,
                      parking: s.cost?.parking || 0,
                      accomodation: s.cost?.accomodation || 0,
                      otherExpenses: s.cost?.otherExpenses || 0,
                      totalCost: s.cost?.totalCost || 0,
                      pricePerKm: s.cost?.pricePerKm || 0,
                    }))
                  : []
              }
            >
              {(fields) => (
                <>
                  {fields.map(({ key, name, ...restField }, index: number) => (
                    <>
                      {((selectedEntity?.bartenders &&
                        selectedEntity.bartenders[index]?.person?.id ===
                          userPersonId) ||
                        isAdmin(userRole)) && (
                        <>
                          <Divider plain>{`${translations[language].costs} - ${
                            translations[language].bartender
                          } ${
                            selectedEntity?.bartenders
                              ? `(${selectedEntity?.bartenders[index].person.firstName} ${selectedEntity?.bartenders[index].person.lastName})`
                              : ""
                          }`}</Divider>
                          <Form.Item
                            {...restField}
                            {...formInputNumberRules(language)}
                            name={[name, "mileage"]}
                            label={translations[language].mileage}
                          >
                            <InputNumber
                              min={0}
                              placeholder={translations[language].mileageEnter}
                              onChange={(value: number | null): void => {
                                const formValues = form.getFieldsValue();
                                const { bartenders } = formValues;
                                const mileagePrice =
                                  (value !== null && value !== undefined
                                    ? value
                                    : 0) *
                                  (bartenders[index]["pricePerKm"] ||
                                    costPerKm);
                                const totalCost =
                                  (bartenders[index]["fee"] || 0) +
                                  mileagePrice +
                                  (bartenders[index]["tolls"] || 0) +
                                  (bartenders[index]["parking"] || 0) +
                                  (bartenders[index]["accomodation"] || 0) +
                                  (bartenders[index]["otherExpenses"] || 0);

                                Object.assign(bartenders[index], {
                                  mileagePrice: parseFloat(
                                    mileagePrice.toFixed(2)
                                  ),
                                  totalCost: parseFloat(totalCost.toFixed(2)),
                                });
                                form.setFieldsValue({ bartenders });
                              }}
                              addonAfter={<span>km</span>}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "mileagePrice"]}
                            label={`${translations[language].fuelCost} (km * ${(
                              getTravelCostBartenders(index) || costPerKm
                            ).toFixed(2)})`}
                          >
                            <InputNumber
                              {...inputNumberGenericFormatter()}
                              disabled
                              addonAfter={<span>€</span>}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            {...formInputNumberRules(language)}
                            name={[name, "tolls"]}
                            label={translations[language].tolls}
                          >
                            <InputNumber
                              min={0}
                              placeholder={translations[language].tollsEnter}
                              onChange={(value: number | null): void => {
                                const formValues = form.getFieldsValue();
                                const { bartenders } = formValues;
                                Object.assign(bartenders[index], {
                                  tolls:
                                    value !== null && value !== undefined
                                      ? value
                                      : 0,
                                  totalCost:
                                    (bartenders[index]["fee"] || 0) +
                                    (value !== null && value !== undefined
                                      ? value
                                      : 0) +
                                    (bartenders[index]["mileagePrice"] || 0) +
                                    (bartenders[index]["parking"] || 0) +
                                    (bartenders[index]["accomodation"] || 0) +
                                    (bartenders[index]["otherExpenses"] || 0),
                                });
                                form.setFieldsValue({ bartenders });
                              }}
                              addonAfter={<span>€</span>}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            {...formInputNumberRules(language)}
                            name={[name, "parking"]}
                            label={translations[language].parking}
                          >
                            <InputNumber
                              min={0}
                              placeholder={translations[language].parkingEnter}
                              onChange={(value: number | null): void => {
                                const formValues = form.getFieldsValue();
                                const { bartenders } = formValues;
                                Object.assign(bartenders[index], {
                                  parking:
                                    value !== null && value !== undefined
                                      ? value
                                      : 0,
                                  totalCost:
                                    (bartenders[index]["fee"] || 0) +
                                    (value !== null && value !== undefined
                                      ? value
                                      : 0) +
                                    (bartenders[index]["mileagePrice"] || 0) +
                                    (bartenders[index]["tolls"] || 0) +
                                    (bartenders[index]["accomodation"] || 0) +
                                    (bartenders[index]["otherExpenses"] || 0),
                                });
                                form.setFieldsValue({ bartenders });
                              }}
                              addonAfter={<span>€</span>}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            {...formInputNumberRules(language)}
                            name={[name, "accomodation"]}
                            label={translations[language].overnight}
                          >
                            <InputNumber
                              min={0}
                              placeholder={
                                translations[language].overnightEnter
                              }
                              onChange={(value: number | null): void => {
                                const formValues = form.getFieldsValue();
                                const { bartenders } = formValues;
                                Object.assign(bartenders[index], {
                                  accomodation:
                                    value !== null && value !== undefined
                                      ? value
                                      : 0,
                                  totalCost:
                                    (bartenders[index]["fee"] || 0) +
                                    (value !== null && value !== undefined
                                      ? value
                                      : 0) +
                                    (bartenders[index]["mileagePrice"] || 0) +
                                    (bartenders[index]["tolls"] || 0) +
                                    (bartenders[index]["parking"] || 0) +
                                    (bartenders[index]["otherExpenses"] || 0),
                                });
                                form.setFieldsValue({ bartenders });
                              }}
                              addonAfter={<span>€</span>}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            {...formInputNumberRules(language)}
                            name={[name, "otherExpenses"]}
                            label={translations[language].rest}
                          >
                            <InputNumber
                              min={0}
                              placeholder={translations[language].restEnter}
                              onChange={(value: number | null): void => {
                                const formValues = form.getFieldsValue();
                                const { bartenders } = formValues;
                                Object.assign(bartenders[index], {
                                  otherExpenses:
                                    value !== null && value !== undefined
                                      ? value
                                      : 0,
                                  totalCost:
                                    (bartenders[index]["fee"] || 0) +
                                    (value !== null && value !== undefined
                                      ? value
                                      : 0) +
                                    (bartenders[index]["mileagePrice"] || 0) +
                                    (bartenders[index]["tolls"] || 0) +
                                    (bartenders[index]["parking"] || 0) +
                                    (bartenders[index]["accomodation"] || 0),
                                });
                                form.setFieldsValue({ bartenders });
                              }}
                              addonAfter={<span>€</span>}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "totalCost"]}
                            label={translations[
                              language
                            ].totalCost.toUpperCase()}
                          >
                            <InputNumber
                              {...inputNumberGenericFormatter()}
                              min={0}
                              disabled
                              addonAfter={<span>€</span>}
                            />
                          </Form.Item>
                        </>
                      )}
                    </>
                  ))}
                </>
              )}
            </Form.List>
          )}
          {/* {isAdminOrPromoter(userRole) && (
            <>
              <Divider plain>{translations[language].sendingReport}</Divider>
              <Form.Item name="mails" label={translations[language].mails}>
                <Select
                  showSearch
                  mode="multiple"
                  placeholder={translations[language].mailsChoose}
                  options={mails}
                  filterOption={(input, option) =>
                    filterSelectOption(input, option)
                  }
                />
              </Form.Item>
            </>
          )} */}
        </Form>
      )}
    </>
  );
};

export default ReportForm;
