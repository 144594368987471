/* eslint-disable react-hooks/exhaustive-deps */
import Table, { ColumnsType } from "antd/lib/table";
import React, { ReactElement, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { defaultFormatDate } from "../../config/constants";
import { translations } from "../../config/translations";
import { BaseViewModel, IdViewModel } from "../../core/models/Base";
import {
  DrawerState,
  PersonType,
  SimpleDrawerState
} from "../../core/models/Enum";
import { HostessVM } from "../../core/models/Hostess";
import { PersonVM } from "../../core/models/Person";
import { TableAction } from "../../core/models/TableAction";
import { getPersonsList } from "../../core/services/PersonService";
import { useAuthContext } from "../../helpers/AuthContext";
import {
  checkStringOnFilter,
  convertUTCToLocalFormattedTime,
  genericTableProps,
  getActionsColumn,
  getColumnSearchProps,
  onRowClick
} from "../../helpers/TableHelper";

interface Props {
  shouldUpdate: boolean;
  personType?: PersonType;
  setShouldUpdateState: (newState: boolean) => void;
  handleDeleteModalChange: (
    newModalVisibility: boolean,
    newShouldUpdate: boolean,
    id?: number
  ) => void;
  handleDrawerChange: (
    newDrawerState: DrawerState,
    newShouldUpdate: boolean,
    id?: number
  ) => void;
  handleImagesDrawerChange: (
    newDrawerState: SimpleDrawerState,
    id?: number
  ) => void;
  handleChangePasswordDrawerChange: (
    newDrawerState: SimpleDrawerState,
    id?: number
  ) => void;
}

const PersonsTable: React.FC<Props> = (props: Props) => {
  const {
    shouldUpdate,
    personType,
    setShouldUpdateState,
    handleDeleteModalChange,
    handleDrawerChange,
    handleImagesDrawerChange,
    handleChangePasswordDrawerChange,
  } = props;
  const { language, setLoading } = useAuthContext();
  const authContext = useAuthContext();
  const [tableData, setTableData] = useState<(PersonVM | HostessVM)[]>([]);

  useEffect(() => {
    getTableData();
  }, []);

  useEffect(() => {
    if (shouldUpdate) {
      setShouldUpdateState(false);
      getTableData();
    }
  }, [shouldUpdate]);

  const getTableData = async () => {
    setLoading(true);

    const data = await getPersonsList(authContext, personType);

    if (data) {
      setTableData(data);
    }

    setLoading(false);
  };

  const actionsContent = (value: IdViewModel): TableAction[] => {
    const returnActions: TableAction[] = [
      {
        id: 1,
        entity: value,
        className: "details-action-button",
        label: translations[language].edit,
        onClick: (): void =>
          handleDrawerChange(DrawerState.Edit, false, value.id),
      },
      {
        id: 2,
        entity: value,
        className: "details-action-button",
        label: translations[language].delete,
        onClick: (): void => handleDeleteModalChange(true, false, value.id),
      },
      {
        id: 3,
        entity: value,
        className: "details-action-button",
        label: translations[language].editImages,
        onClick: (): void =>
          handleImagesDrawerChange(SimpleDrawerState.Opened, value.id),
      },
    ];

    if (personType !== PersonType.Hostess) {
      returnActions.push({
        id: 4,
        entity: value,
        className: "details-action-button",
        label: translations[language].changePassword,
        onClick: (): void =>
          handleChangePasswordDrawerChange(SimpleDrawerState.Opened, value.id),
      });
    }

    return returnActions;
  };

  const getColumns = (): ColumnsType<PersonVM | HostessVM> => {
    if (personType !== PersonType.BrandManager) {
      const columns: ColumnsType<PersonVM | HostessVM> = isMobile
        ? [
            {
              title: translations[language].firstName,
              dataIndex: "firstName",
              ...getColumnSearchProps(language),
              onFilter: (
                value: string | number | boolean | undefined,
                record: PersonVM | HostessVM
              ): boolean => checkStringOnFilter(value, record.firstName),
            },
            {
              title: translations[language].lastName,
              dataIndex: "lastName",
              ...getColumnSearchProps(language),
              onFilter: (
                value: string | number | boolean | undefined,
                record: PersonVM | HostessVM
              ): boolean => checkStringOnFilter(value, record.lastName),
            },
            {
              title: translations[language].city,
              dataIndex: "city",
              ...getColumnSearchProps(language),
              onFilter: (
                value: string | number | boolean | undefined,
                record: PersonVM | HostessVM
              ): boolean => checkStringOnFilter(value, record.city?.name),
              render: (value?: BaseViewModel) => value?.name || "",
            },
            {
              title: translations[language].actions,
              dataIndex: "",
              key: "",
              width: 55,
              render: (value: PersonVM | HostessVM): ReactElement =>
                getActionsColumn(value, actionsContent),
            },
          ]
        : [
            {
              title: translations[language].firstName,
              dataIndex: "firstName",
              ...getColumnSearchProps(language),
              onFilter: (
                value: string | number | boolean | undefined,
                record: PersonVM | HostessVM
              ): boolean => checkStringOnFilter(value, record.firstName),
            },
            {
              title: translations[language].lastName,
              dataIndex: "lastName",
              ...getColumnSearchProps(language),
              onFilter: (
                value: string | number | boolean | undefined,
                record: PersonVM | HostessVM
              ): boolean => checkStringOnFilter(value, record.lastName),
            },
            {
              title: translations[language].residence,
              dataIndex: "residence",
              ...getColumnSearchProps(language),
              onFilter: (
                value: string | number | boolean | undefined,
                record: PersonVM | HostessVM
              ): boolean => checkStringOnFilter(value, record.residence),
            },
            {
              title: translations[language].dateOfBirth,
              dataIndex: "birthDate",
              render: (value?: Date) =>
                convertUTCToLocalFormattedTime(value, defaultFormatDate),
            },
            {
              title: translations[language].representative,
              dataIndex: "representative",
              ...getColumnSearchProps(language),
              onFilter: (
                value: string | number | boolean | undefined,
                record: PersonVM | HostessVM
              ): boolean =>
                checkStringOnFilter(value, record.representative?.name),
              render: (value?: BaseViewModel) => value?.name || "",
            },
            {
              title: translations[language].region,
              dataIndex: "region",
              ...getColumnSearchProps(language),
              onFilter: (
                value: string | number | boolean | undefined,
                record: PersonVM | HostessVM
              ): boolean => checkStringOnFilter(value, record.region?.name),
              render: (value?: BaseViewModel) => value?.name || "",
            },
            {
              title: translations[language].city,
              dataIndex: "city",
              ...getColumnSearchProps(language),
              onFilter: (
                value: string | number | boolean | undefined,
                record: PersonVM | HostessVM
              ): boolean => checkStringOnFilter(value, record.city?.name),
              render: (value?: BaseViewModel) => value?.name || "",
            },
            {
              title: translations[language].area,
              dataIndex: "area",
              ...getColumnSearchProps(language),
              onFilter: (
                value: string | number | boolean | undefined,
                record: PersonVM | HostessVM
              ): boolean => checkStringOnFilter(value, record.area?.name),
              render: (value?: BaseViewModel) => value?.name || "",
            },
            {
              title: translations[language].street,
              dataIndex: "street",
              ...getColumnSearchProps(language),
              onFilter: (
                value: string | number | boolean | undefined,
                record: PersonVM | HostessVM
              ): boolean => checkStringOnFilter(value, record.street),
            },
            {
              title: translations[language].mobilePhone,
              dataIndex: "phone",
              ...getColumnSearchProps(language),
              onFilter: (
                value: string | number | boolean | undefined,
                record: PersonVM | HostessVM
              ): boolean => checkStringOnFilter(value, record.phone),
            },
            {
              title: translations[language].email,
              dataIndex: "email",
              ...getColumnSearchProps(language),
              onFilter: (
                value: string | number | boolean | undefined,
                record: PersonVM | HostessVM
              ): boolean => checkStringOnFilter(value, record.email),
            },
            {
              title: translations[language].note,
              dataIndex: "note",
              ...getColumnSearchProps(language),
              onFilter: (
                value: string | number | boolean | undefined,
                record: PersonVM | HostessVM
              ): boolean => checkStringOnFilter(value, record.note),
            },
            {
              title: translations[language].actions,
              dataIndex: "",
              key: "",
              width: 55,
              render: (value: PersonVM | HostessVM): ReactElement =>
                getActionsColumn(value, actionsContent),
            },
          ];

      if (personType === PersonType.Hostess && !isMobile) {
        columns.splice(
          columns.length - 1,
          0,
          {
            title: translations[language].height,
            dataIndex: "height",
          },
          {
            title: translations[language].garmentNumber,
            dataIndex: "confectionSize",
          }
        );
      }
      return columns;
    } else {
      const otherColumns: ColumnsType<PersonVM | HostessVM> = isMobile
        ? [
            {
              title: translations[language].firstName,
              dataIndex: "firstName",
              ...getColumnSearchProps(language),
              onFilter: (
                value: string | number | boolean | undefined,
                record: PersonVM
              ): boolean => checkStringOnFilter(value, record.firstName),
            },
            {
              title: translations[language].lastName,
              dataIndex: "lastName",
              ...getColumnSearchProps(language),
              onFilter: (
                value: string | number | boolean | undefined,
                record: PersonVM
              ): boolean => checkStringOnFilter(value, record.lastName),
            },
            {
              title: translations[language].brand,
              dataIndex: "brand",
              ...getColumnSearchProps(language),
              onFilter: (
                value: string | number | boolean,
                record: PersonVM
              ): boolean => checkStringOnFilter(value, record.brand?.name),
              render: (value?: BaseViewModel) => value?.name,
            },
            {
              title: translations[language].actions,
              dataIndex: "",
              key: "",
              width: 55,
              render: (value: PersonVM): ReactElement =>
                getActionsColumn(value, actionsContent),
            },
          ]
        : [
            {
              title: translations[language].firstName,
              dataIndex: "firstName",
              ...getColumnSearchProps(language),
              onFilter: (
                value: string | number | boolean | undefined,
                record: PersonVM
              ): boolean => checkStringOnFilter(value, record.firstName),
            },
            {
              title: translations[language].lastName,
              dataIndex: "lastName",
              ...getColumnSearchProps(language),
              onFilter: (
                value: string | number | boolean | undefined,
                record: PersonVM
              ): boolean => checkStringOnFilter(value, record.lastName),
            },
            {
              title: translations[language].brand,
              dataIndex: "brand",
              ...getColumnSearchProps(language),
              onFilter: (
                value: string | number | boolean,
                record: PersonVM
              ): boolean => checkStringOnFilter(value, record.brand?.name),
              render: (value?: BaseViewModel) => value?.name,
            },
            {
              title: translations[language].mobilePhone,
              dataIndex: "phone",
              ...getColumnSearchProps(language),
              onFilter: (
                value: string | number | boolean | undefined,
                record: PersonVM
              ): boolean => checkStringOnFilter(value, record.phone),
            },
            {
              title: translations[language].email,
              dataIndex: "email",
              ...getColumnSearchProps(language),
              onFilter: (
                value: string | number | boolean | undefined,
                record: PersonVM
              ): boolean => checkStringOnFilter(value, record.email),
            },
            {
              title: translations[language].actions,
              dataIndex: "",
              key: "",
              width: 55,
              render: (value: PersonVM): ReactElement =>
                getActionsColumn(value, actionsContent),
            },
          ];

      return otherColumns;
    }
  };

  return (
    <Table
      {...genericTableProps(language)}
      columns={getColumns()}
      className="cursorPointer"
      size="small"
      dataSource={tableData}
      rowKey={(item): number => item.id}
      onRow={(item): { onClick: (column: any) => void } => ({
        onClick: (column: { target: { nodeName: string } }): void =>
          onRowClick(column, (): void =>
            handleDrawerChange(DrawerState.Edit, false, item.id)
          ),
      })}
    />
  );
};

export default PersonsTable;
