/* eslint-disable react-hooks/exhaustive-deps */
import { Form, InputNumber } from "antd";
import React, { useEffect, useState } from "react";
import { translations } from "../../config/translations";
import { SimpleDrawerState } from "../../core/models/Enum";
import { BottlesReportVM, ReportFormModel } from "../../core/models/Report";
import {
  getBottlesReportDetails,
  updateBottlesReport
} from "../../core/services/ReportService";
import { useAuthContext } from "../../helpers/AuthContext";
import {
  formItemLayoutDrawer10_14,
  inputNumberGenericFormatter
} from "../../helpers/FormHelper";

interface Props {
  drawerState: SimpleDrawerState;
  selectedEntityId: number;
  wrappedComponentRef?: React.RefObject<any>;
  onClose: (
    drawerState: SimpleDrawerState,
    shouldUpdate: boolean,
    id?: number
  ) => void;
}

const ReportSoldBottlesForm: React.FC<Props> = (props: Props) => {
  const { selectedEntityId, wrappedComponentRef, onClose } = props;
  const [form] = Form.useForm();
  const { language, setLoading } = useAuthContext();
  const authContext = useAuthContext();
  const [selectedEntity, setSelectedEntity] = useState<
    BottlesReportVM | undefined
  >();

  useEffect(() => {
    getFormData();
  }, []);

  const getFormData = async () => {
    setLoading(true);

    const result = await getBottlesReportDetails(authContext, selectedEntityId);

    if (result) {
      setSelectedEntity(result);
    }

    setLoading(false);
  };

  const handleSave = async (formValues: ReportFormModel) => {
    setLoading(true, translations[language].saving);

    let result;

    result = await updateBottlesReport(authContext, {
      ...formValues,
      orderId: selectedEntityId,
    });

    if (result) {
      onClose(SimpleDrawerState.Closed, true, undefined);
    }

    setLoading(false);
  };

  return (
    <>
      {selectedEntity && (
        <Form
          onFinish={handleSave}
          className="form"
          {...formItemLayoutDrawer10_14}
          ref={wrappedComponentRef}
          form={form}
        >
          <Form.Item
            name="smallBottlesCount"
            label={translations[language].sold07Bottles}
            initialValue={selectedEntity?.smallBottlesCount || 0}
          >
            <InputNumber
              min={0}
              placeholder={translations[language].sold07BottlesEnter}
              onChange={(value: number | null): void => {
                const otherValue = form.getFieldValue("largeBottlesCount");
                form.setFieldValue(
                  "cumulativeSold",
                  (otherValue
                    ? form.getFieldValue("largeBottlesCount") / 0.7
                    : 0) + (value !== null && value !== undefined ? value : 0)
                );
              }}
            />
          </Form.Item>
          <Form.Item
            name="largeBottlesCount"
            label={translations[language].sold1Bottles}
            initialValue={selectedEntity?.largeBottlesCount || 0}
          >
            <InputNumber
              min={0}
              placeholder={translations[language].sold1BottlesEnter}
              onChange={(value: number | null): void => {
                form.setFieldValue(
                  "cumulativeSold",
                  (value !== null && value !== undefined ? value : 0) / 0.7 +
                    form.getFieldValue("smallBottlesCount")
                );
              }}
            />
          </Form.Item>
          <Form.Item
            name="cumulativeSold"
            label={translations[language].cumulative07Sold}
            initialValue={
              (selectedEntity?.largeBottlesCount
                ? selectedEntity?.largeBottlesCount / 0.7
                : 0) + (selectedEntity?.smallBottlesCount || 0)
            }
          >
            <InputNumber {...inputNumberGenericFormatter()} disabled />
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default ReportSoldBottlesForm;
