/* eslint-disable react-hooks/exhaustive-deps */
import { PlusOutlined } from "@ant-design/icons";
import { Button, Drawer, PageHeader } from "antd";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../components/delete-modal/DeleteModal";
import { DrawerButtons } from "../../components/drawer-buttons/DrawerButtons";
import { DRAWER_SMALL_WIDTH } from "../../config/constants";
import routes from "../../config/routes";
import { translations } from "../../config/translations";
import { DrawerState } from "../../core/models/Enum";
import { deleteMail } from "../../core/services/MailService";
import { useAuthContext } from "../../helpers/AuthContext";
import { PageHeaderButtonSettings } from "../../helpers/ButtonHelper";
import { submitForm } from "../../helpers/FormHelper";
import MailForm from "./MailForm";
import MailsTable from "./MailTable";

const Mail: React.FC = () => {
  const { language, setLoading } = useAuthContext();
  const authContext = useAuthContext();
  const [drawerState, setDrawerState] = useState<DrawerState>(
    DrawerState.Closed
  );
  const [shouldTableUpdate, setShouldTableUpdate] = useState<boolean>(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [selectedEntityId, setSelectedEntityId] = useState<
    number | undefined
  >();
  const { mailId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const ref = React.createRef();

  useEffect(() => {
    handleInitialRoute();
  }, []);

  const handleInitialRoute = (): void => {
    const newLocation = mailId
      ? location.pathname.replace(mailId, ":mailId")
      : location.pathname;

    switch (newLocation) {
      case routes.ROUTE_MAILS_CREATE:
        handleDrawerChange(DrawerState.Create, false);
        return;
      case routes.ROUTE_MAILS_EDIT:
        if (mailId) {
          handleDrawerChange(DrawerState.Edit, false, parseInt(mailId));
        }
        return;
      default:
        return;
    }
  };

  const handleDrawerChange = (
    newDrawerState: DrawerState,
    newShouldUpdate: boolean,
    id?: number
  ) => {
    setSelectedEntityId(id);
    setShouldTableUpdate(newShouldUpdate);
    setDrawerState(newDrawerState);
    navigate(getUrl(newDrawerState, id));
  };

  const getUrl = (state: DrawerState, id?: number): string => {
    switch (state) {
      case DrawerState.Create:
        return routes.ROUTE_MAILS_CREATE;
      case DrawerState.Edit:
        return id
          ? routes.ROUTE_MAILS_EDIT.replace(":mailId", id.toString())
          : routes.ROUTE_MAILS;
      default:
        return routes.ROUTE_MAILS;
    }
  };

  const handleDeleteModalChange = (
    newModalVisibility: boolean,
    newShouldUpdate: boolean,
    id?: number
  ) => {
    setSelectedEntityId(id);
    setShouldTableUpdate(newShouldUpdate);
    setDeleteModalVisible(newModalVisibility);
  };

  const deleteEntity = async (): Promise<void> => {
    if (!selectedEntityId) return;

    setLoading(true, translations[language].deleting);

    const result = await deleteMail(authContext, selectedEntityId);

    if (result) {
      handleDeleteModalChange(false, true);
    }

    setLoading(false);
  };

  return (
    <>
      <PageHeader
        title={translations[language].mails}
        extra={[
          <Button
            key="mails-button"
            {...PageHeaderButtonSettings}
            onClick={() => handleDrawerChange(DrawerState.Create, false)}
          >
            <PlusOutlined />
            {translations[language].addMail}
          </Button>,
        ]}
      />
      <MailsTable
        shouldUpdate={shouldTableUpdate}
        setShouldUpdateState={setShouldTableUpdate}
        handleDrawerChange={handleDrawerChange}
        handleDeleteModalChange={handleDeleteModalChange}
      />
      <Drawer
        title={
          selectedEntityId
            ? translations[language].editMail
            : translations[language].addMail
        }
        open={!!drawerState}
        width={isMobile ? window.screen.width : DRAWER_SMALL_WIDTH}
        destroyOnClose
        onClose={() => handleDrawerChange(DrawerState.Closed, false)}
        footer={
          <DrawerButtons
            onCancelAction={() => handleDrawerChange(DrawerState.Closed, false)}
            onSubmitAction={() => submitForm(ref)}
          />
        }
      >
        <MailForm
          drawerState={drawerState}
          selectedEntityId={selectedEntityId}
          wrappedComponentRef={ref}
          onClose={handleDrawerChange}
        />
      </Drawer>
      <DeleteModal
        title={translations[language].deleteMail}
        message={translations[language].confirmDeleteMail}
        isVisible={deleteModalVisible}
        onOkAction={deleteEntity}
        onCancelAction={(): void => handleDeleteModalChange(false, false)}
      />
    </>
  );
};

export default Mail;
