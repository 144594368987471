import { IUseAuthValues } from "../../helpers/AuthContext";
import { BaseCreateRequestModel, BaseUpdateRequestModel, BaseViewModel } from "../models/Base";

export const getLocationTypesList = async (
  context: IUseAuthValues
): Promise<BaseViewModel[]> => {
  const { get } = context;

  const entities = await get<BaseViewModel[] | undefined>(`/LocationType`);

  return entities || [];
};

export const getLocationTypeDetails = async (
  context: IUseAuthValues,
  id: number
): Promise<BaseViewModel | undefined> => {
  const { get } = context;

  const entity = await get<BaseViewModel | undefined>(`/LocationType/${id}`);

  return entity;
};

export const createLocationType = async (
  context: IUseAuthValues,
  newEntity: BaseCreateRequestModel
): Promise<number | undefined> => {
  const { post } = context;

  const entity = await post<number | undefined>(`/LocationType`, newEntity);

  return entity;
};

export const updateLocationType = async (
  context: IUseAuthValues,
  updateEntity: BaseUpdateRequestModel
): Promise<number | undefined> => {
  const { put } = context;

  const entity = await put<number | undefined>(`/LocationType/${updateEntity.id}`, updateEntity);

  return entity;
};

export const deleteLocationType = async (context: IUseAuthValues, id: number): Promise<number | undefined> => {
  const { remove } = context;

  const entity = await remove<number | undefined>(`/LocationType/${id}`);

  return entity;
};
